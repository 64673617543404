import { useEffect, useState } from 'react';

import { useMutation } from 'react-query';

import api from 'app/api/v2/api_calls';
import { usePartnerConnectionContext } from 'app/businessConnection/components/PartnerConnectionContext';
import { partnerToPartnerResourceName } from 'app/businessConnection/services/PartnerToAccountsMethods';
import StatefulButtonComponent, {
    ButtonStatus,
    ERROR,
    LOADING,
    SUCCESSFUL,
} from 'app/common/components/buttons/StatefulButton';
import { BUSINESS_CONNECTION, CONNECTION_STATS } from 'app/common/data/queryKeysConstants';
import { OUTLINED, Type } from 'app/common/designSystem/constants/type';
import queryClient from 'app/states/queryClient';

export const DisconnectButton = () => {
    const {
        owningBusinessId,
        partnerName,
        setShowConnectionModal,
        setOwningBusinessId,
        setSelectedLocationId,
        setConnectedLocationId,
    } = usePartnerConnectionContext();
    const [label, setLabel] = useState<string>('disconnect');
    const [status, setStatus] = useState<ButtonStatus>(ERROR);

    const disconnectBusiness = useMutation(
        () =>
            api.businessModule.publisherConnection.disconnectBusinessFromLocation(
                owningBusinessId,
                partnerToPartnerResourceName[partnerName],
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([BUSINESS_CONNECTION]);
                queryClient.invalidateQueries([CONNECTION_STATS]);
                setLabel('disconnection_successful');
                setStatus(SUCCESSFUL);
                setShowConnectionModal(false);
                setOwningBusinessId('');
                setSelectedLocationId('');
                setConnectedLocationId('');
            },
            onError: () => {
                setLabel('disconnect');
                setStatus(ERROR);
            },
        },
    );

    useEffect(() => {
        if (disconnectBusiness.isLoading) {
            setLabel('disconnection_in_progress');
            setStatus(LOADING);
        }
    }, [disconnectBusiness]);

    return (
        <StatefulButtonComponent
            hideIcon
            type={OUTLINED as Type}
            status={status}
            label={label}
            onClick={() => disconnectBusiness.mutate()}
        />
    );
};
