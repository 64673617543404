import WHITELIST_PROVIDERS from 'app/common/data/providers';
import { BUSINESS_MANAGER, GROUP_MANAGER, ORG_ADMIN } from 'app/common/data/roles';
import { useHasMessagePage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasMessagePage';
import useMe from 'app/common/hooks/queries/useMeUncamel';

const useHasWhatsappAccess = (): boolean => {
    const { data: me } = useMe();
    const hasMessagePage = useHasMessagePage();

    if (me === undefined) {
        return false;
    }

    if (!hasMessagePage) {
        return false;
    }

    const allowed_roles = WHITELIST_PROVIDERS.includes(me.provider)
        ? [ORG_ADMIN]
        : [ORG_ADMIN, GROUP_MANAGER, BUSINESS_MANAGER];
    return allowed_roles.includes(me.role);
};

export default useHasWhatsappAccess;
