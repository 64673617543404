import { uniq } from 'lodash-es';

import { V2FormattedLocationData, V2LocationPartnerStatusData } from 'app/api/types/account';

// REQUEST STATUS
const RUNNING = 'RUNNING';
const FAILED = 'FAILED';

// ACTION TYPES
export const GET_FACEBOOK_LOCATION_STATUS_REQUEST = 'GET_FACEBOOK_LOCATION_STATUS_REQUEST';
const GET_FACEBOOK_LOCATION_STATUS_REQUEST_SUCCESS = 'GET_FACEBOOK_LOCATION_STATUS_REQUEST_SUCCESS';
const GET_FACEBOOK_LOCATION_STATUS_REQUEST_FAILURE = 'GET_FACEBOOK_LOCATION_STATUS_REQUEST_FAILURE';
export const FUZZY_SEARCH_FACEBOOK_LOCATIONS = 'FUZZY_SEARCH_FACEBOOK_LOCATIONS';
const FUZZY_SEARCH_FACEBOOK_LOCATIONS_SUCCESS = 'FUZZY_SEARCH_FACEBOOK_LOCATIONS_SUCCESS';
const FUZZY_SEARCH_FACEBOOK_LOCATIONS_FAILURE = 'FUZZY_SEARCH_FACEBOOK_LOCATIONS_FAILURE';

// ACTION FLOW TYPES
export type GetFacebookLocationStatusAction = {
    type: 'GET_FACEBOOK_LOCATION_STATUS_REQUEST';
    locationId: string;
};

type GetFacebookLocationStatusSuccessAction = {
    type: 'GET_FACEBOOK_LOCATION_STATUS_REQUEST_SUCCESS';
    locationId: string;
    locationStatus: V2LocationPartnerStatusData;
};

type GetFacebookLocationStatusFailureAction = {
    type: 'GET_FACEBOOK_LOCATION_STATUS_REQUEST_FAILURE';
    locationId: string;
    error: Record<string, any>;
};

export type FuzzySearchFacebookLocationsAction = {
    type: 'FUZZY_SEARCH_FACEBOOK_LOCATIONS';
    searchInput: string;
};

type FuzzySearchFacebookLocationsSuccessAction = {
    type: 'FUZZY_SEARCH_FACEBOOK_LOCATIONS_SUCCESS';
    searchResultsById: Record<string, V2FormattedLocationData>;
    searchResultsIds: Array<string>;
};

type FuzzySearchFacebookLocationsFailureAction = {
    type: 'FUZZY_SEARCH_FACEBOOK_LOCATIONS_FAILURE';
    error: Record<string, any>;
};

export type FacebookLocationsState = {
    isSearchingOnServer: boolean;
    searchInput: string;
    searchErrors: Record<string, any>;
    searchResultsById: {};
    searchResultsIds: Array<number | string>;
    statusById: Record<string, any>;
};

export type FacebookResourceAction =
    | FuzzySearchFacebookLocationsAction
    | FuzzySearchFacebookLocationsSuccessAction
    | FuzzySearchFacebookLocationsFailureAction
    | GetFacebookLocationStatusAction
    | GetFacebookLocationStatusSuccessAction
    | GetFacebookLocationStatusFailureAction;

// ACTION CREATORS
export const fuzzySearchFacebookLocations = (
    searchInput: string,
): FuzzySearchFacebookLocationsAction => ({
    type: FUZZY_SEARCH_FACEBOOK_LOCATIONS,
    searchInput,
});

export const fuzzySearchFacebookLocationsFailure = (
    error: Record<string, any>,
): FuzzySearchFacebookLocationsFailureAction => ({
    type: FUZZY_SEARCH_FACEBOOK_LOCATIONS_FAILURE,
    error,
});

export const fuzzySearchFacebookLocationsSuccess = (
    searchResultsById: Record<string, V2FormattedLocationData>,
    searchResultsIds: Array<string>,
): FuzzySearchFacebookLocationsSuccessAction => ({
    type: FUZZY_SEARCH_FACEBOOK_LOCATIONS_SUCCESS,
    searchResultsById,
    searchResultsIds,
});

// INITIAL STATES
const initialState = {
    isSearchingOnServer: false,
    searchInput: '',
    searchErrors: {},
    searchResultsById: {},
    searchResultsIds: [],
    statusById: {},
};

// REDUCER
const facebookResourcesReducer = (
    state: FacebookLocationsState = initialState,
    action: FacebookResourceAction,
): FacebookLocationsState => {
    switch (action.type) {
        case GET_FACEBOOK_LOCATION_STATUS_REQUEST:
            return {
                ...state,
                statusById: {
                    ...state.statusById,
                    [action.locationId]: {
                        status: RUNNING,
                    },
                },
            };

        case GET_FACEBOOK_LOCATION_STATUS_REQUEST_SUCCESS:
            return {
                ...state,
                statusById: {
                    ...state.statusById,
                    [action.locationId]: action.locationStatus,
                },
            };

        case GET_FACEBOOK_LOCATION_STATUS_REQUEST_FAILURE:
            return {
                ...state,
                statusById: {
                    ...state.statusById,
                    [action.locationId]: {
                        status: FAILED,
                        error: action.error,
                    },
                },
            };

        case FUZZY_SEARCH_FACEBOOK_LOCATIONS:
            return {
                ...state,
                isSearchingOnServer: true,
                searchInput: action.searchInput,
                searchErrors: {},
                searchResultsIds: [],
                searchResultsById: {},
            };

        case FUZZY_SEARCH_FACEBOOK_LOCATIONS_SUCCESS:
            return {
                ...state,
                isSearchingOnServer: false,
                searchResultsIds: uniq(action.searchResultsIds),
                searchResultsById: action.searchResultsById,
            };

        case FUZZY_SEARCH_FACEBOOK_LOCATIONS_FAILURE:
            return {
                ...state,
                isSearchingOnServer: false,
                searchErrors: action.error,
            };

        default:
            return state;
    }
};

// SELECTORS
export const isSearchingOnServerSelector = (state: FacebookLocationsState): boolean =>
    state.isSearchingOnServer;

export const allFacebookLocationSearchResultsSelector = (
    state: FacebookLocationsState,
): Array<V2FormattedLocationData> => state.searchResultsIds.map(id => state.searchResultsById[id]);

export const facebookLocationSearchResultsSelector = (
    state: FacebookLocationsState,
    locationId: string,
): V2FormattedLocationData | void => state.searchResultsById[locationId];

export default facebookResourcesReducer;
