import { useEffect, useMemo } from 'react';

import { useForm } from 'react-hook-form';

import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { APP_PRODUCTS } from 'app/common/data/productIds';
import { ORG_BUSINESS_EDIT_FIELDS } from 'app/common/data/queryKeysConstants';

import { useHasReviewInvitePage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewInvitePage';
import { useHasReviewManagementPage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewManagementPage';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrganization from 'app/common/hooks/queries/useOrganization';
import {
    CEOrgFeatures,
    useHasCEOrgFeatures,
} from 'app/pages/customerExperience/settings/useHasCEOrgFeatures';
import useOrgPermissionsUpdate from 'app/pages/settingsV2/subPages/Team/components/Permissions/hooks/useOrgPermissionsUpdate';
import { PermissionsFormType } from 'app/pages/settingsV2/subPages/Team/components/Permissions/PermissionsSettingsV1';
import { PermissionsSectionType } from 'app/pages/settingsV2/subPages/Team/components/Permissions/utils/typing';
import queryClient from 'app/states/queryClient';

import useOrgPermissions from './useOrgPermissions';

/**
 * Handles all the logic for the permissions page
 * So it is reusable for Settings V2
 */
export default function usePermissionsPageLogicContent() {
    const { data: me, isLoading: isMeLoading } = useMe();
    const { data: businessFields, isLoading: isLoadingPermissions } = useOrgPermissions();
    const { data: org, isLoading: isLoadingOrg } = useOrganization(me?.org_id ?? 0, !!me?.org_id);

    const isLoading = isMeLoading || isLoadingPermissions || isLoadingOrg;

    // Reset queries cache
    useEffect(() => {
        document.getElementById('react-content')?.scrollTo(0, 0);
        // important to reset and not invalidate
        queryClient.invalidateQueries(invalidatesAny(ORG_BUSINESS_EDIT_FIELDS));
    }, []);

    const hasReviewInvitePage = useHasReviewInvitePage();
    const hasReviewManagementPage = useHasReviewManagementPage();
    const {
        orgFeatures: {
            [CEOrgFeatures.FeedbackManagement]: hasFbm,
            [CEOrgFeatures.FBMReplySuggestion]: hasFbmReplySuggestion,
        },
    } = useHasCEOrgFeatures();

    const setFormValue = (): {
        businessFields: Array<PermissionsSectionType>;
    } => {
        // All those filters will need to be done on the backend once the permissions/plans/products are ready
        const value = businessFields
            ?.map(section => {
                const fields = section.fields
                    .filter(field => {
                        if (field.name === 'review_assign_tag') return !!org?.has_review_tag;
                        if (field.name === 'feedback_result_reply_suggestion')
                            return hasFbmReplySuggestion;
                        return true;
                    })
                    .map(field => ({
                        enabled: field.enabled,
                        name: field.name,
                        enabled_for_gm: field.enabled_for_gm,
                        enabled_for_bm: field.enabled_for_bm,
                    }));

                return {
                    name: section.name,
                    fields,
                };
            })
            .filter(section => {
                if (!hasFbm && section.name === 'feedback_management') {
                    return false;
                }
                if (!hasReviewInvitePage && section.name === APP_PRODUCTS.REVIEW_BOOSTER) {
                    return false;
                }
                if (!hasReviewManagementPage && section.name === APP_PRODUCTS.REVIEW_MANAGEMENT) {
                    return false;
                }

                return section.fields.length > 0;
            });

        return {
            businessFields: value ?? [],
        };
    };

    const form: PermissionsFormType = useForm({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    // Reset form when businessFields change
    useEffect(() => {
        if (!isLoading) {
            form.reset(setFormValue());
        }
    }, [isLoading, businessFields]);

    const updatePermissions = useOrgPermissionsUpdate();

    return {
        isLoading,
        form,
        updatePermissions,
    };
}
