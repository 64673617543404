import { FEATURE_ENUM } from 'app/api/types/plans_features';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { COMPETITIVE_BENCHMARK, USE_USER_SIDEBAR_FEATURES } from 'app/common/data/featureFlags';
import { ADMIN } from 'app/common/data/roles';
import { useHasPage } from 'app/common/hooks/accessesAndPermissions/useHasPage';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useHasCompetitiveBenchmarkPage = (): boolean | undefined => {
    const { data: me } = useMe();
    const isAdmin = me?.role === ADMIN;

    const released = useFeatureFlag(COMPETITIVE_BENCHMARK);

    if (isAdmin) {
        return false;
    }

    if (!released) {
        return false;
    }

    if (USE_USER_SIDEBAR_FEATURES) {
        return useHasPage(FEATURE_ENUM.COMPETITIVE_BENCHMARK);
    } else {
        return !me?.sidebar_excluded_pages.includes('competitive_benchmark');
    }
};
