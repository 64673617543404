import { upperFirst } from 'lodash-es';

import {
    SET_LOCATION_ID,
    SetLocationIdAction,
    UNSET_BUSINESS_LOCATION_ID,
    UnsetLocationIdAction,
} from 'app/common/reducers/newBusinesses';

export type ConnectionsStatsState = {
    isFetching: boolean;
    count: number;
    linkedToGoogleCount: number;
    linkedToFacebookCount: number;
    error: Object;
};

type ConnectionsStatsAction = SetLocationIdAction | UnsetLocationIdAction;

// INITIAL STATES
const initialState = {
    isFetching: false,
    count: 0,
    linkedToGoogleCount: 0,
    linkedToFacebookCount: 0,
    error: {},
};

// REDUCER
const connectionsStatsReducer = (
    state: ConnectionsStatsState = initialState,
    action: ConnectionsStatsAction,
): ConnectionsStatsState => {
    switch (action.type) {
        case SET_LOCATION_ID:
            return {
                ...state,
                [`linkedTo${upperFirst(action.partnerName)}Count`]:
                    state[`linkedTo${upperFirst(action.partnerName)}Count`] + 1,
            };

        case UNSET_BUSINESS_LOCATION_ID:
            return {
                ...state,
                [`linkedTo${upperFirst(action.partnerName)}Count`]:
                    state[`linkedTo${upperFirst(action.partnerName)}Count`] - 1,
            };

        default:
            return state;
    }
};

// SELECTORS

export const countSelector = (state: ConnectionsStatsState): number => state.count;

export default connectionsStatsReducer;
