import { uniq } from 'lodash-es';

import { V2FormattedLocationData } from 'app/api/types/account';

// ACTION TYPES
export const FUZZY_SEARCH_GMB_LOCATIONS = 'FUZZY_SEARCH_GMB_LOCATIONS';
const FUZZY_SEARCH_GMB_LOCATIONS_SUCCESS = 'FUZZY_SEARCH_GMB_LOCATIONS_SUCCESS';
const FUZZY_SEARCH_GMB_LOCATIONS_FAILURE = 'FUZZY_SEARCH_GMB_LOCATIONS_FAILURE';

// ACTION FLOW TYPES
export type FuzzySearchGmbLocationsAction = {
    type: 'FUZZY_SEARCH_GMB_LOCATIONS';
    searchInput: string;
};

type FuzzySearchGmbLocationsSuccessAction = {
    type: 'FUZZY_SEARCH_GMB_LOCATIONS_SUCCESS';
    searchResultsById: Record<string, V2FormattedLocationData>;
    searchResultsIds: Array<string>;
};

type FuzzySearchGmbLocationsFailureAction = {
    type: 'FUZZY_SEARCH_GMB_LOCATIONS_FAILURE';
    error: Record<string, any>;
};

export type GMBLocationsState = {
    isSearchingOnServer: boolean;
    searchInput: string;
    searchErrors: Record<string, any>;
    searchResultsIds: Array<string>;
    searchResultsById: Record<string, any>;
};

export type GmbResourceAction =
    | FuzzySearchGmbLocationsAction
    | FuzzySearchGmbLocationsSuccessAction
    | FuzzySearchGmbLocationsFailureAction;

// ACTION CREATORS
export const fuzzySearchGmbLocations = (searchInput: string): FuzzySearchGmbLocationsAction => ({
    type: FUZZY_SEARCH_GMB_LOCATIONS,
    searchInput,
});

export const fuzzySearchGmbLocationsFailure = (
    error: Record<string, any>,
): FuzzySearchGmbLocationsFailureAction => ({
    type: FUZZY_SEARCH_GMB_LOCATIONS_FAILURE,
    error,
});

export const fuzzySearchGmbLocationsSuccess = (
    searchResultsById: Record<string, V2FormattedLocationData>,
    searchResultsIds: Array<string>,
): FuzzySearchGmbLocationsSuccessAction => ({
    type: FUZZY_SEARCH_GMB_LOCATIONS_SUCCESS,
    searchResultsById,
    searchResultsIds,
});

// INITIAL STATES
const initialState = {
    isSearchingOnServer: false,
    searchInput: '',
    searchErrors: {},
    searchResultsById: {},
    searchResultsIds: [],
};

// REDUCER
const gmbResourcesReducer = (
    state: GMBLocationsState = initialState,
    action: GmbResourceAction,
): GMBLocationsState => {
    switch (action.type) {
        case FUZZY_SEARCH_GMB_LOCATIONS:
            return {
                ...state,
                isSearchingOnServer: true,
                searchInput: action.searchInput,
                searchErrors: {},
                searchResultsIds: [],
                searchResultsById: {},
            };

        case FUZZY_SEARCH_GMB_LOCATIONS_SUCCESS:
            return {
                ...state,
                isSearchingOnServer: false,
                searchResultsIds: uniq(action.searchResultsIds),
                searchResultsById: action.searchResultsById,
            };

        case FUZZY_SEARCH_GMB_LOCATIONS_FAILURE:
            return {
                ...state,
                isSearchingOnServer: false,
                searchErrors: action.error,
            };

        default:
            return state;
    }
};

// SELECTORS
export const isSearchingOnServerSelector = (state: GMBLocationsState): boolean =>
    state.isSearchingOnServer;

export const allGmbLocationsSearchResultsSelector = (
    state: GMBLocationsState,
): Array<V2FormattedLocationData> => state.searchResultsIds.map(id => state.searchResultsById[id]);

export const gmbLocationSearchResultsSelector = (
    state: GMBLocationsState,
    locationId: string,
): V2FormattedLocationData | void => state.searchResultsById[locationId];

export default gmbResourcesReducer;
