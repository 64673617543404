import { ReviewBusinessFilter, ReviewFilters, ReviewObject } from 'app/api/types/review';

import {
    DELETED_REVIEWS,
    LIVE_REVIEWS,
    NOT_TREATED_REVIEWS,
    PENDING_AUTO_REPLY_REVIEWS,
    TREATED_AUTO_REPLY_REVIEWS,
    TREATED_REVIEWS,
    WITH_MESSAGE,
} from '../filters';

export const isInRating = (rating: number, gmbRatingRange: Array<number>) =>
    (rating >= gmbRatingRange[0] && rating <= gmbRatingRange[1]) || rating === null;

export const isInDateRange = (date: number, dateFrom: Date | null, dateTo: Date | null) =>
    // @ts-ignore
    (dateFrom ? date >= +dateFrom : true) && (dateTo ? date <= dateTo : true);

export const isInPlatforms = (
    partner: string,
    platform: Array<'google_my_business' | 'facebook' | 'tripadvisor'>,
) =>
    // @ts-ignore
    platform.includes(partner);

export const isInMessage = (content: string, message: Array<'withMessage' | 'withoutMessage'>) =>
    !!message.reduce((seq, a) => seq || (a === WITH_MESSAGE) === !!content, false);

export const isInBusinessIds = (businessId: string, ids: Array<string>) => ids.includes(businessId);

export const isRightState = (state: string, reviewsState: string) => {
    switch (reviewsState) {
        case LIVE_REVIEWS:
            return state !== DELETED_REVIEWS;
        case TREATED_REVIEWS:
            return [
                TREATED_REVIEWS,
                TREATED_AUTO_REPLY_REVIEWS,
                PENDING_AUTO_REPLY_REVIEWS,
            ].includes(state);
        case NOT_TREATED_REVIEWS:
            return [NOT_TREATED_REVIEWS].includes(state);
        default:
            return state === reviewsState;
    }
};

export const filterReviews = (
    reviews: Array<ReviewObject>,
    reviewFilters: ReviewFilters,
    reviewBusinessFilter: ReviewBusinessFilter = {
        includedIds: null,
        excludedIds: null,
    },
) => {
    const { dateFrom, dateTo, platform, message, reviewState } = reviewFilters;
    const { includedIds, excludedIds } = reviewBusinessFilter;
    let filtered = Array.from(reviews);

    if (includedIds) {
        filtered = filtered.filter(({ businessId }) => isInBusinessIds(businessId, includedIds));
    }

    if (excludedIds) {
        filtered = filtered.filter(({ businessId }) => !isInBusinessIds(businessId, excludedIds));
    }

    if (reviewState) filtered = filtered.filter(({ state }) => isRightState(state, reviewState));

    if (dateFrom || dateTo) {
        filtered = filtered.filter(({ updateDate }) => isInDateRange(updateDate, dateFrom, dateTo));
    }

    if (platform) filtered = filtered.filter(({ partner }) => isInPlatforms(partner, platform));
    if (message) filtered = filtered.filter(({ content }) => isInMessage(content, message));

    return filtered.sort(({ updateDate: a }, { updateDate: b }) => b - a);
};
