import { FC, useMemo } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { AccountPartner } from 'app/api/types/account';
import api from 'app/api/v2/api_calls';

import StatusButton from 'app/businessConnection/components/connectionsTable/BusinessRow/StatusButton';
import { usePartnerConnectionContext } from 'app/businessConnection/components/PartnerConnectionContext';
import { partnerToPartnerName } from 'app/businessConnection/services/PartnerToAccountsMethods';
import { BUSINESS_LOCATIONS } from 'app/common/data/queryKeysConstants';
import { usePublishersStatesBusinessesInfo } from 'app/presence/management/hooks/usePublishersStatesBusinessesInfo';

import { CardLine } from '../commonOld/CardLine';
import LocationDetails from '../commonOld/LocationDetails';

interface Props {
    partner: AccountPartner;
    businessId: string;
    locationId: string;
}

interface ColorParams {
    isLoading: boolean;
    isPartnerConnectionOk: boolean;
    isLocationAccessError: boolean;
}

const getColor = ({ isLoading, isPartnerConnectionOk, isLocationAccessError }: ColorParams) => {
    switch (true) {
        case isLoading:
            return 'primary';
        case isPartnerConnectionOk:
            return 'success';
        case isLocationAccessError:
            return 'secondary';
        default:
            return 'danger';
    }
};

const getIconType = ({
    isLoading,
    isPartnerConnectionOk,
}: {
    isLoading: boolean;
    isPartnerConnectionOk: boolean;
}) => {
    switch (true) {
        case isLoading:
            return FontAwesomeIconsPartooUsed.faSpin;
        case isPartnerConnectionOk:
            return FontAwesomeIconsPartooUsed.faCircleCheck;
        default:
            return FontAwesomeIconsPartooUsed.faExclamationCircle;
    }
};

const ConnectedButton: FC<Props> = ({ partner, businessId, locationId }) => {
    const { t } = useTranslation();
    const formattedPartner = partner.toLowerCase();
    const { setShowConnectionModal, setConnectedLocationId, setOwningBusinessId, setPartnerName } =
        usePartnerConnectionContext();

    const { data: publisherData, isLoading: isPublisherLoading } =
        usePublishersStatesBusinessesInfo({
            publisherName: formattedPartner,
            state: 'success',
            enabled: !!businessId && !!partner,
        });

    const publisherStatus = useMemo(() => {
        return publisherData?.pages?.some(page => {
            const publisherState = page[formattedPartner]?.success;
            return publisherState?.publishers_business?.some(
                business => business.business_id === businessId,
            );
        })
            ? 'success'
            : 'error';
    }, [publisherData, formattedPartner, businessId]);

    const {
        isLoading: isLocationLoading,
        data: location,
        isError: isLocationAccessError,
    } = useQuery(
        [BUSINESS_LOCATIONS, { partner, locationId }],
        () => api.account.getLocationDetail(locationId, partner),
        { enabled: !!locationId },
    );

    const isLoading = isPublisherLoading || isLocationLoading;
    const isPartnerConnectionOk = !!(location && location.partner_connection?.status !== 'broken');

    const color = getColor({ isLoading, isPartnerConnectionOk, isLocationAccessError });
    const iconType = getIconType({ isLoading, isPartnerConnectionOk });
    const isDisabled = !location || (publisherStatus === 'success' && color === 'danger');

    const text = useMemo(() => {
        if (location && !(publisherStatus === 'success' && color === 'danger')) {
            return <LocationDetails location={location} />;
        }
        return (
            <CardLine
                text={t(
                    isLocationAccessError
                        ? 'partner_connection_permission_denied_subject'
                        : 'loading',
                )}
            />
        );
    }, [location, isLocationAccessError, color, publisherStatus, t]);

    const handleClick = () => {
        setShowConnectionModal(true);
        if (location) setConnectedLocationId(location.id);
        setOwningBusinessId(businessId);
        setPartnerName(partnerToPartnerName[partner]);
    };

    return (
        <StatusButton
            icon={[iconType, IconPrefix.SOLID]}
            text={text}
            textTooltip={isDisabled ? t('partner_connection_button_tooltip') : undefined}
            color={color}
            onClick={handleClick}
            disabled={isDisabled}
        />
    );
};

export default ConnectedButton;
