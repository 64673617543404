import { useTranslation } from 'react-i18next';

import { useHasReviewAnalyticsPage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewAnalyticsPage';
import { PageHeaderWithAvatar } from 'app/routing/components/PageHeaderWithAvatar';

export const ReviewsPageHeader = () => {
    const { t } = useTranslation();

    const hasReviewAnalyticsPage = useHasReviewAnalyticsPage();

    if (!hasReviewAnalyticsPage) {
        return null;
    }

    return <PageHeaderWithAvatar title={t('analytics')} />;
};
