import { useTranslation } from 'react-i18next';

import { useAppDataContext } from 'app/common/contexts/appData';
import { ADMIN } from 'app/common/data/roles';
import { PageHeaderWithAvatar } from 'app/routing/components/PageHeaderWithAvatar';

import { ExportButton } from './ExportButton';

export const CompetitiveBenchmarkPageHeader = () => {
    const { t } = useTranslation();
    const { me } = useAppDataContext();

    const isAdmin = me.role === ADMIN;

    if (isAdmin) {
        return null;
    }

    return (
        <PageHeaderWithAvatar
            title={t('analytics')}
            actions={[<ExportButton key="--export-analytics-button-key--" />]}
        />
    );
};
