import { Navigate, useRoutes } from 'react-router-dom';

import { useHasReviewManagementPage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewManagementPage';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks/useDeviceType';
import {
    CEPages,
    useCanAccessCustomerExperiencePage,
} from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';
import { findFirstDisplayPathname } from 'app/pages/settingsV2/helpers';
import {
    ReviewSettingsMenu,
    useCanAccessReviewsSetting,
} from 'app/pages/settingsV2/hooks/Reviews/useCanAccessReviewsSetting';
import { useSettingReviewsMenu } from 'app/pages/settingsV2/hooks/Reviews/useSettingReviewsMenu';
import { WithComment } from 'app/pages/settingsV2/subPages/Reviews/components/AutoReply/WithComment/WithComment';
import { WithoutComment } from 'app/pages/settingsV2/subPages/Reviews/components/AutoReply/WithoutComment/WithoutComment';
import { FeedbackFormListPage } from 'app/pages/settingsV2/subPages/Reviews/components/FeedbackFormListPage';
import { ReplyTemplates as ReplyTemplatesReviews } from 'app/pages/settingsV2/subPages/Reviews/components/ReplyTemplates/ReplyTemplates';
import { SettingReviews } from 'app/pages/settingsV2/subPages/Reviews/SettingReviews';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import {
    AUTO_REPLY_PATH_TOKEN,
    FEEDBACK_FORMS_PATH_TOKEN,
    REPLY_TEMPLATES_PATH_TOKEN,
    SETTINGS_REVIEWS_AUTO_REPLY_WITHOUT_COMMENT_PATHNAME,
    SETTINGS_REVIEWS_PATHNAME,
    WITHOUT_COMMENT_PATH_TOKEN,
    WITH_COMMENT_PATH_TOKEN,
} from 'app/routing/routeIds';

export const ReviewsRoutes = () => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    const { menus: settingsReviewsMenu } = useSettingReviewsMenu();
    const defaultSettingReviewsPathname = findFirstDisplayPathname(settingsReviewsMenu);

    const { data: me } = useMe();
    const meRole = me ? me.role : null;
    const { canAccessCustomerExperiencePage } = useCanAccessCustomerExperiencePage();
    const hasReviewManagement = !!useHasReviewManagementPage();
    const {
        menuAccess: {
            [ReviewSettingsMenu.AUTO_REPLY_WITHOUT_COMMENT]: withoutCommentsSettings,
            [ReviewSettingsMenu.AUTO_REPLY_WITH_COMMENT]: withCommentsSettings,
        },
    } = useCanAccessReviewsSetting();

    const hasAutoReplySettingsAccess = withoutCommentsSettings || withCommentsSettings;

    const indexRoute = isBelowProvidedDevices
        ? SETTINGS_REVIEWS_PATHNAME
        : defaultSettingReviewsPathname;

    const routes = [
        {
            element: <SettingReviews />,
            children: [
                {
                    index: true,
                    element: <Navigate replace to={indexRoute} />,
                },
                ...(hasReviewManagement
                    ? [
                          {
                              path: REPLY_TEMPLATES_PATH_TOKEN,
                              element: (
                                  <ProtectedRoute userRole={meRole}>
                                      <ReplyTemplatesReviews />
                                  </ProtectedRoute>
                              ),
                          },
                      ]
                    : []),
                ...(hasAutoReplySettingsAccess !== null
                    ? [
                          {
                              path: AUTO_REPLY_PATH_TOKEN,
                              children: [
                                  {
                                      index: true,
                                      element: (
                                          <Navigate
                                              replace
                                              to={
                                                  SETTINGS_REVIEWS_AUTO_REPLY_WITHOUT_COMMENT_PATHNAME
                                              }
                                          />
                                      ),
                                  },
                                  {
                                      path: WITHOUT_COMMENT_PATH_TOKEN,
                                      element: (
                                          <ProtectedRoute userRole={meRole}>
                                              <WithoutComment />
                                          </ProtectedRoute>
                                      ),
                                  },
                                  ...(withCommentsSettings
                                      ? [
                                            {
                                                path: WITH_COMMENT_PATH_TOKEN,
                                                element: (
                                                    <ProtectedRoute userRole={meRole}>
                                                        <WithComment />
                                                    </ProtectedRoute>
                                                ),
                                            },
                                        ]
                                      : []),
                              ],
                          },
                      ]
                    : []),
                ...(canAccessCustomerExperiencePage[CEPages.FBM]
                    ? [
                          {
                              path: FEEDBACK_FORMS_PATH_TOKEN,
                              element: (
                                  <ProtectedRoute userRole={meRole}>
                                      <FeedbackFormListPage />
                                  </ProtectedRoute>
                              ),
                          },
                      ]
                    : []),
            ],
        },
    ];

    return useRoutes(routes);
};
