/**
 * Definition of all pushs events
 */
export enum PushNotifsEvent {
    // When a user replies to message clicking on the send button or enter on its keyboard
    SEND_MESSAGE = 'send_message',
    // When a user clicks on Invite on the Review Booster
    SEND_SMS = 'send_sms',
    // When a user clicks on Create Business Button
    CREATE_BUSINESS = 'create_business',
    // When a user loads the Partoo website
    LAUNCH_WEB_APP = 'launch_web_app',
    // When a user creates a comment on a review
    REPLY_TO_REVIEW = 'reply_to_review',
    // When a user tags a review
    TAG_REVIEW = 'tag_review',
    // When a user creates or updates a message template
    SAVE_MESSAGE_TEMPLATE = 'save_messages_reply_template',
}
