export enum FEATURE_ENUM {
    // PM
    BUSINESS_EDITION = 'business_edition',
    DIFFUSION = 'diffusion',
    PRESENCE_ANALYTICS = 'presence_analytics',
    BULK_MODIFICATION = 'bulk_modification',
    POSTS = 'posts',
    COMPETITIVE_BENCHMARK = 'competitive_benchmark',

    // RM
    REVIEW_MANAGEMENT = 'review_management',
    REVIEW_ANALYTICS = 'review_analytics',
    REVIEW_INVITE = 'review_invite', // old review booster
    REVIEW_TAG = 'review_tag',
    REVIEW_AI_SUGGESTION = 'review_ai_suggestion',
    REVIEW_AUTO_REPLY_WITH_COMMENTS = 'review_auto_reply_with_comments',
    REVIEW_AUTO_REPLY_WITHOUT_COMMENTS = 'review_auto_reply_without_comments',
    DOWNLOAD_RM_STATS = 'download_rm_stats',

    // Messages
    MESSAGES = 'messages',
    JIM = 'jim',

    // FB
    FEEDBACK_MANAGEMENT = 'feedback_management',
    FEEDBACK_MANAGEMENT_DOWNLOAD = 'feedback_management_download',
    FEEDBACK_MANAGEMENT_REPLY_SUGGESTION = 'feedback_management_reply_suggestion',

    // General
    API_KEY_MANAGER = 'api_key_manager',
    PARTOO_PLUS = 'partoo_plus',
    CARE_EXPERT = 'care_expert',
    DEMO_CREATOR = 'demo_creator',
}

export enum PRODUCT_ENUM {
    PRESENCE_MANAGEMENT = 'presence_management',
    REVIEW_MANAGEMENT = 'review_management',
    MESSAGING = 'messages',
    FEEDBACK_MANAGEMENT = 'feedback_management',
    GENERAL = 'general',
}
