import { FEATURE_ENUM } from 'app/api/types/plans_features';
import { USE_USER_SIDEBAR_FEATURES } from 'app/common/data/featureFlags';
import { APP_PRODUCTS, BUSINESS_MESSAGE } from 'app/common/data/productIds';
import { BUSINESS_MANAGER, GROUP_MANAGER, ORG_ADMIN } from 'app/common/data/roles';
import { useHasPage } from 'app/common/hooks/accessesAndPermissions/useHasPage';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useHasMessagePage = (): boolean | undefined => {
    const { data: me } = useMe();

    if (![ORG_ADMIN, GROUP_MANAGER, BUSINESS_MANAGER].includes(me?.role ?? '')) {
        return false;
    }

    if (USE_USER_SIDEBAR_FEATURES) {
        return useHasPage(FEATURE_ENUM.MESSAGES);
    } else {
        return me?.sidebar_products.includes(APP_PRODUCTS[BUSINESS_MESSAGE]);
    }
};
