import { useHasPresenceAnalyticsPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasPresenceAnalyticsPage';
import { SmallStatProps } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/DefaultStatComponent';
import { StatPresence } from 'app/pages/Homepage/sections/HomepageStats/stats/StatPresence';
import { Item } from 'app/pages/Homepage/utils/typing';

export const useStatPresenceItem = (): Item<SmallStatProps> => {
    const isAvailable = !!useHasPresenceAnalyticsPage();

    return {
        order: 1,
        isAvailable: isAvailable,
        component: StatPresence,
        key: 'stat-presence',
    };
};
