export enum ConnectionQueryKeys {
    PUBLISHER = 'publisher',
    QUERY = 'query',
    BUSINESS_ID = 'business_id',
}

export enum ConnectionEnum {
    FACEBOOK = 'facebook',
    GOOGLE = 'google',
}
