import { useContext, useState } from 'react';

import { toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';

import { CommentData } from 'app/api/types/comments';
import { showConfirm } from 'app/common/components/ConfirmModal/reducers/confirm';
import { ToastWithAction } from 'app/common/components/ToastWithAction/ToastWithAction';
import { COMMENTS_NOT_FETCHED_COUNT } from 'app/common/data/queryKeysConstants';
import env from 'app/common/services/getEnvironment';
import queryClient from 'app/states/queryClient';
import {
    realtimeUpdatesExistingReviewsSelector,
    realtimeUpdatesSelector,
} from 'app/states/realtimeUpdates/selectors';

import CommentAuthorMessage from './CommentAuthorMessage';
import { StyledCommentAuthorMessage } from './CommentAuthorMessage.styled';
import CommentForm, { CommentOptions } from './CommentForm';
import { CommentMenu } from './CommentMenu';
import { useCreateComment } from './hooks/useCreateComment';
import { useUpdateComment } from './hooks/useUpdateComment';
import { ReviewCardContext, ReviewCardContextType } from '../../ReviewCard';

type Props = {
    comment?: CommentData;
    parentId: number | undefined;
    canDelete?: boolean;
    isEditing: boolean;
    setIsEditing?: (isEditing: boolean) => void;
};

export default function CommentContent({
    comment,
    isEditing,
    setIsEditing,
    parentId,
    canDelete,
}: Props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { review } = useContext<ReviewCardContextType>(ReviewCardContext);
    const canEdit = !!setIsEditing;

    const initialContent = comment?.content ?? '';
    const [content, setContent] = useState(initialContent);

    const isTripAdviserReview = review.partner === 'tripadvisor';
    const realtimeReviews = realtimeUpdatesExistingReviewsSelector(
        useSelector(realtimeUpdatesSelector),
    );

    const { mutate: createCommentMutate, isLoading: isCreatingComment } = useCreateComment(
        review,
        comment,
        parentId,
    );

    const { mutate: updateCommentMutate, isLoading: isUpdatingComment } = useUpdateComment(
        review,
        comment,
    );

    const handleUndo = () => {
        setIsEditing?.(false);
        setContent(initialContent);
    };

    function handleReply(content: string, commentOptions: CommentOptions) {
        if (!!comment?.id) {
            updateCommentMutate(
                { content, commentOptions },
                {
                    onSuccess: () => {
                        setIsEditing?.(false);
                        void queryClient.invalidateQueries(COMMENTS_NOT_FETCHED_COUNT);
                    },
                },
            );
        } else {
            if (!env.isSandbox()) {
                const realtimeReview = realtimeReviews.find(({ id }) => +id === review.id);
                if (
                    !!realtimeReview &&
                    realtimeReview.state === 'treated' &&
                    realtimeReview.comments.length
                ) {
                    const handleRedirect = () => {
                        dispatch(
                            navigate({
                                search: '?status=treated',
                            }),
                        );
                    };
                    toast.warning(
                        <ToastWithAction
                            action={handleRedirect}
                            description={t('already_replied_snackbar_content')}
                        >
                            {t('already_replied_snackbar_cta')}
                        </ToastWithAction>,
                        t('already_replied_snackbar_title'),
                    );
                    return;
                }
            }
            createCommentMutate(
                { content, commentOptions },
                {
                    onSuccess: () => {
                        setIsEditing?.(false);
                        setContent('');
                    },
                },
            );
        }
    }

    const handleEdit = () => {
        if (isTripAdviserReview) {
            window.open(String(review.link));
        } else {
            setIsEditing?.(true);
        }
    };

    const handleDelete = (): void => {
        if (isTripAdviserReview) {
            window.open(String(review.link));
        } else {
            dispatch(
                showConfirm(
                    'delete_review_modal',
                    { commentId: comment?.id, review, parentId },
                    [],
                ),
            );
        }
    };

    if (isEditing) {
        return (
            <StyledCommentAuthorMessage>
                <CommentForm
                    value={content}
                    onChange={setContent}
                    canCancel={!!parentId || !!initialContent}
                    parentId={parentId}
                    onUndo={handleUndo}
                    onReply={handleReply}
                    isReplying={isCreatingComment || isUpdatingComment}
                    comment={comment}
                />
            </StyledCommentAuthorMessage>
        );
    }

    const author =
        review.state === 'deleted' && comment?.author_name
            ? t(comment?.author_name)
            : comment?.author_name;

    return (
        <CommentAuthorMessage
            reviewState={review.state}
            author={author}
            canEdit={canEdit || isTripAdviserReview}
            notFetched={comment?.notFetched}
            commentDate={comment?.date}
            message={initialContent}
            commentMenu={
                canEdit || isTripAdviserReview ? (
                    <CommentMenu
                        onClickEdit={handleEdit}
                        onClickDelete={handleDelete}
                        canDelete={!!canDelete}
                    />
                ) : undefined
            }
            isReplySuggestion={comment?.is_reply_suggestion}
        />
    );
}
