import { ReactNode } from 'react';

import { FEATURE_ENUM } from 'app/api/types/plans_features';

import { V2BusinessData } from './business';
import { ProductReport } from './index';

export type UserStatus = 'all' | 'invited' | 'active' | 'disabled';
export enum UserStatusEnum {
    ALL = 'all',
    INVITED = 'invited',
    ACTIVE = 'active',
}

export type RequestPartnerUpsaleResponse = {
    status: string;
};

export type RoleValue = 'BUSINESS_MANAGER' | 'GROUP_MANAGER' | 'ORG_ADMIN' | 'ADMIN' | 'PROVIDER';

export type V2UserData = {
    id: string;
    shadow_user_id: string;
    shadow_user_role: string;
    shadow_user_intercom_hash: string;
    intercom_user_hash: string;
    created_at: number;
    first_name: string;
    last_name: string;
    lang: string;
    org_id: number;
    phone: string;
    role: RoleValue;
    email: string;
    status: UserStatus;
    business_ids: Array<string>;
    provider: string;
    provider_name: string;
    sidebar_products: Array<string>;
    sidebar_excluded_pages: Array<string>;
    reports: Array<ProductReport>;
    provider_logo: string;
    group_id: number | null;
    rm_last_seen_date: string | null;
    sso_only: boolean;
    can_use_bulk_modification: boolean;
    theme: string;
    has_active_api_keys: boolean;
    created_by: string;
    privacy_policy_accepted_at: number | null;
    terms_and_conditions_accepted_at: number | null;
    accesses?: Array<Array<number>>;
    // Sidebar pages selected in the user edit page
    sidebar_pages?: Array<FEATURE_ENUM>;
    // Sidebar pages available for the user, also take into account is_page features
    available_pages?: Array<FEATURE_ENUM>;
};

export type V2UsersStats = {
    count: number;
    invited_count: number;
    activated_count: number;
    count_less_me: number;
};

export type V2UsersAvailableRoles = {
    roles: Array<string>;
};

export type SearchUserParams = {
    email?: string;
    last_name?: string;
    org_id?: number;
    role?: RoleValue;
    query?: string;
    status?: 'invited' | 'active' | null;
    page?: number;
    display_business_ids?: 'True' | 'False';
    provider?: string;
};

export type FormattedUserData = {
    email: string;
    first_name: string;
    last_name: string;
    provider?: string;
    lang?: string;
    business_ids: Array<string>;
    password?: string | null;
    org_id?: number | null;
    role: string;
    sidebar_products: Array<string> | null;
    send_invitation?: 'True' | 'False';
    rm_last_seen_date?: string | null;
    sso_only?: boolean | null;
    can_use_bulk_modification?: boolean | null;
    group_id?: number | null;
    accesses?: Array<Array<number>> | null;

    sidebar_pages?: Array<FEATURE_ENUM>;
};

export type FuzzySearchUsersReceivedData = {
    users: Array<V2UserData>;
    count: number;
    max_page: number;
    page: number;
};

export type UserBusinessesReceivedData = {
    businesses: Array<V2BusinessData>;
    count: number;
    max_page: number;
    page: number;
};

export type UserReceivedData = {
    status: string;
    id: string;
};

export type RoleChoice = {
    label: string;
    value: RoleValue;
};

export type IntChoice = {
    label: string;
    value: number;
};

export type MenuChoice = {
    label: React.ReactNode;
    value: string;
    icon?: string;
    image?: string;
    color?: string;
    dataTrack?: string;
    disabledTooltip?: string;
    subtitle?: string;
    subtitleColor?: string;
};

export type Choice<TValue = string> = {
    label: string;
    value: TValue;
    icon?: string | JSX.Element;
};

export type SelectOption = {
    label: ReactNode;
    value: string;
    name: string;
};

export type UserChoice = {
    label: string;
    value: string;
    fullName: string;
};

export type ChoiceWithSubtitle<TValue = string> = Choice<TValue> & {
    subtitle?: string;
};

export type CategoryChoice = Choice;

export type V2FormattedUserData = {
    id: string;
    shadowUserId: string;
    shadowUserRole: string;
    shadowUserIntercomHash: string;
    intercomUserHash: string;
    createdAt: number | null;
    firstName: string;
    lastName: string;
    lang: string;
    orgId: number;
    phone: string;
    role: RoleValue;
    email: string;
    status: UserStatus;
    theme: string;
    businessIds: Array<string>;
    provider: string;
    providerName: string;
    sidebarProducts: Array<string>;
    sidebarExcludedPages: Array<string>;
    providerLogo: string;
    createdBy: string;
    groupId: number | null;
    hasActiveApiKeys: boolean;
    rmLastSeenDate: string | null;
    receiveReviewsEmailNotifications: boolean;
    ssoOnly: boolean;
    canUseBulkModification: boolean;
};

export type ReportUserPreferencesResponse = {
    reports: {
        review_management_daily: boolean;
        review_management_weekly: boolean;
        review_management_monthly: boolean;
        daily_review_preferences?: {
            partners: Array<string>;
            review_type: number;
        };
    };
    receive_negative_reviews_email_notifications: boolean;
};

export type PaginatedBusinessUsers = {
    page: number;
    max_page: number;
    count: number;
    groups: Array<V2UserData>;
};

type SearchOrder = 'id' | 'role' | '-role';
export enum SearchOrderEnum {
    ID = 'id',
    ROLE = 'role',
    MINUSROLE = '-role',
}

export type BusinessUsersParams = {
    query?: string;
    status?: UserStatus;
    excluded_roles?: string;
    order_by?: SearchOrder;
};

export type BusinessUsersAPIResponse = {
    count: number;
    max_page: number;
    page: number;
    users: Array<V2UserData>;
};

export type BusinessUser = {
    id: string;
    role: string;
    firstName: string;
    lastName: string;
};
