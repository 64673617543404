import { useCallback, useState } from 'react';

import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import {
    CreateWidgetSetupData,
    CreateWidgetSetupResponse,
    WidgetSetupIcon,
} from 'app/api/v2/api_calls/messagingWidgetSetupApiCalls';
import { MESSAGING_WIDGET_SETUP_GET } from 'app/common/data/queryKeysConstants';

import dataLayer from 'app/common/utils/dataLayer';
import { DefaultMenuListHeader } from 'app/pages/settingsV2/subComponents/DefaultMenuListHeader/DefaultMenuListHeader';
import { MenuListContentTemplate } from 'app/pages/settingsV2/subComponents/MenuListContentTemplate/MenuListContentTemplate';

import {
    NOT_FOUND_PATH,
    SETTINGS_MESSAGES_WIDGET_SETUP_WIDGET_PATHNAME,
} from 'app/routing/routeIds';

import { useGetFirstBusinessPage } from './hooks/useGetFirstBusinessPage';
import { useWidgetSetupAvatarUpload } from './hooks/useWidgetSetupAvatarUpload';
import { useWidgetSetupCreate } from './hooks/useWidgetSetupCreate';
import { useWidgetSetupGet } from './hooks/useWidgetSetupGet';
import { useWidgetSetupUpdate } from './hooks/useWidgetSetupUpdate';

import { WidgetSetupForm } from './WidgetSetupForm';

export interface ActiveChannelsFormValues {
    sms: boolean;
    whatsapp: boolean;
    messenger: boolean;
}

export interface WidgetSetupFormValues {
    business_id: string;
    domain_whitelist: string[];
    active_channels: ActiveChannelsFormValues;
    color?: string;
    icon?: WidgetSetupIcon;
    avatar_url?: string;
}

const DEFAULT_FORM_DATA: Partial<WidgetSetupFormValues> = {
    business_id: undefined,
    domain_whitelist: [],
    active_channels: {
        sms: false,
        whatsapp: false,
        messenger: false,
    },
    color: '#0085F2',
    icon: WidgetSetupIcon.LOGO,
    avatar_url: undefined,
};

const getFormDataFromWidgetSetup = (
    widgetSetup: CreateWidgetSetupResponse,
): WidgetSetupFormValues => {
    return {
        business_id: widgetSetup?.business?.id,
        domain_whitelist: widgetSetup?.domain_whitelist,
        active_channels: {
            sms: widgetSetup?.has_sms_active,
            whatsapp: widgetSetup?.has_whatsapp_active,
            messenger: widgetSetup?.has_messenger_active,
        },
        color: widgetSetup?.color,
        icon: widgetSetup?.icon,
        avatar_url: widgetSetup?.avatar_url,
    };
};

const getWidgetSetupFromFormData = (formData: WidgetSetupFormValues): CreateWidgetSetupData => {
    return {
        business_id: formData.business_id,
        domain_whitelist: formData.domain_whitelist,
        has_sms_active: formData.active_channels.sms,
        has_whatsapp_active: formData.active_channels.whatsapp,
        has_messenger_active: formData.active_channels.messenger,
        color: formData.color,
        icon: formData.icon,
        avatar_url: formData.avatar_url,
    };
};

export const WidgetSetup = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { widgetSetupId } = useParams();
    const isCreation = !widgetSetupId;
    const menuListHeader = isCreation
        ? t('widget_setup_title_create')
        : t('widget_setup_title_edit');
    const {
        data: databaseWidgetSetup,
        isLoading: isDatabaseWidgetSetupLoading,
        isError: isDatabaseWidgetSetupError,
    } = useWidgetSetupGet(widgetSetupId!, {
        enabled: !isCreation,
    });

    const createWidgetSetupMutation = useWidgetSetupCreate();
    const updateWidgetSetupMutation = useWidgetSetupUpdate();
    const { data: firstBusinessPage, isLoading: isBusinessFirstPageLoading } =
        useGetFirstBusinessPage();

    const uploadWidgetSetupAvatarMutation = useWidgetSetupAvatarUpload();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleCreateSuccess = useCallback(
        (widgetSetup: CreateWidgetSetupResponse, avatarUrl?: string) => {
            queryClient.setQueryData([MESSAGING_WIDGET_SETUP_GET, widgetSetup.id], widgetSetup);
            if (!avatarUrl) setIsSubmitting(false);
            if (avatarUrl) {
                updateAvatar(widgetSetup.id, avatarUrl);
            }

            toast.success(
                t('widget_setup_create_success_description'),
                t('widget_setup_create_success_title'),
            );
            dataLayer.pushDict('widget_setup_create', {
                widget_id: widgetSetup.id,
                date: new Date().toISOString(),
            });
            navigate(`${SETTINGS_MESSAGES_WIDGET_SETUP_WIDGET_PATHNAME}${widgetSetup.id}`, {
                replace: true,
            });
        },
        [queryClient, setIsSubmitting, toast, navigate],
    );

    const handleCreateError = useCallback(() => {
        setIsSubmitting(false);
        toast.error(
            t('widget_setup_create_error_description'),
            t('widget_setup_create_error_title'),
        );
    }, [setIsSubmitting, toast]);

    const handleUpdateSuccess = useCallback(
        (widgetSetup: CreateWidgetSetupResponse, avatarUrl?: string) => {
            if (!avatarUrl || avatarUrl === databaseWidgetSetup?.avatar_url) {
                setIsSubmitting(false);
            }

            queryClient.setQueryData([MESSAGING_WIDGET_SETUP_GET, widgetSetupId], widgetSetup);
            dataLayer.pushDict('widget_setup_update', {
                widget_id: widgetSetup.id,
                date: new Date().toISOString(),
            });

            if (avatarUrl) {
                updateAvatar(widgetSetup.id, avatarUrl);
            }

            toast.success(
                t('widget_setup_update_success_description'),
                t('widget_setup_update_success_title'),
            );
        },
        [queryClient, widgetSetupId, setIsSubmitting, toast],
    );

    const handleUpdateError = useCallback(() => {
        setIsSubmitting(false);
        toast.error(
            t('widget_setup_update_error_description'),
            t('widget_setup_update_error_title'),
        );
    }, [setIsSubmitting, toast]);

    const handleAvatarUpdateSuccess = useCallback(
        (widgetSetup: CreateWidgetSetupResponse) => {
            queryClient.setQueryData([MESSAGING_WIDGET_SETUP_GET, widgetSetupId], widgetSetup);
            setIsSubmitting(false);
        },
        [queryClient, setIsSubmitting],
    );

    const handleAvatarUpdateError = useCallback(() => {
        toast.error(t('~Failed to update the Widget Setup avatar'), t('~Error'));
        setIsSubmitting(false);
    }, [toast, setIsSubmitting]);

    const updateAvatar = useCallback(
        async (widgetId: string, avatarUrl: string) => {
            const blob = await fetch(avatarUrl).then(r => r.blob());
            const extension = blob.type.split('/')[1];
            const avatar = new File([blob], `widget-avatar.${extension}`, { type: blob.type });

            uploadWidgetSetupAvatarMutation.mutate(
                { id: widgetId, avatar },
                {
                    onSuccess: handleAvatarUpdateSuccess,
                    onError: handleAvatarUpdateError,
                },
            );
        },
        [uploadWidgetSetupAvatarMutation, handleAvatarUpdateSuccess, handleAvatarUpdateError],
    );

    const defaultFormData =
        firstBusinessPage?.count === 1
            ? { ...DEFAULT_FORM_DATA, business_id: firstBusinessPage?.businesses[0]?.id }
            : DEFAULT_FORM_DATA;

    const initialData = isCreation
        ? defaultFormData
        : getFormDataFromWidgetSetup(databaseWidgetSetup!);

    const onSubmit = (formValues: WidgetSetupFormValues) => {
        const createWidgetData = getWidgetSetupFromFormData(formValues);
        if (isCreation) {
            createWidgetSetupMutation.mutate(createWidgetData, {
                onSuccess: response => handleCreateSuccess(response, formValues?.avatar_url),
                onError: handleCreateError,
            });
            setIsSubmitting(true);
            return;
        }

        const { business_id, ...updateFormValues } = createWidgetData;
        updateWidgetSetupMutation.mutate(
            { id: widgetSetupId, data: updateFormValues },
            {
                onSuccess: response => handleUpdateSuccess(response, formValues.avatar_url),
                onError: handleUpdateError,
            },
        );
        setIsSubmitting(true);
    };

    if (isDatabaseWidgetSetupError) {
        // URL widgetId does not exists or user does not have access to it
        navigate(NOT_FOUND_PATH);
        return <div>ERROR WIDGET NOT FOUND</div>;
    }

    if (isDatabaseWidgetSetupLoading || isBusinessFirstPageLoading) {
        return <div>LOADING DB WIDGET</div>;
    }

    return (
        <MenuListContentTemplate headerContent={<DefaultMenuListHeader title={menuListHeader} />}>
            {isCreation ? 'CREATION' : 'EDITION'}
            <WidgetSetupForm
                initialData={initialData}
                onSubmit={onSubmit}
                isCreation={isCreation}
                isSubmitting={isSubmitting}
            />
        </MenuListContentTemplate>
    );
};
