import { useHasApiKeyManagerPage } from 'app/common/hooks/accessesAndPermissions/pages/general/useHasApiKeyManagerPage';

import { useIsCustomFieldsPageEnabled } from './pagesEnabled/useIsCustomFieldsPageEnabled';

export const useIntegrationsSettingsEnabled = (): boolean => {
    const isCustomFieldsPageEnabled = useIsCustomFieldsPageEnabled();
    const hasApiKeyManagerPage = !!useHasApiKeyManagerPage();

    return isCustomFieldsPageEnabled || hasApiKeyManagerPage;
};
