import { PropsWithChildren } from 'react';

import { Card } from './SelectableCard.styled';

interface Props {
    isSelected: boolean;
    onChange: (value: boolean) => void;
    disabled?: boolean;
    className?: string;
}

export const SelectableCard = ({
    disabled = false,
    isSelected,
    onChange,
    className,
    children,
}: PropsWithChildren<Props>) => {
    return (
        <Card
            className={className}
            onClick={() => onChange(!isSelected)}
            isSelected={isSelected}
            disabled={disabled}
        >
            {children}
        </Card>
    );
};
