export enum AnalyticsQueryKeys {
    COMPARISON = 'comparison',
    DATE_FROM = 'date-from',
    DATE_TO = 'date-to',
    BUSINESS = 'included',
    DIMENSIONS_ACTIONS = 'dimensions-actions',
    DIMENSIONS_VIEWS = 'dimensions-views',
    DIMENSIONS_QUERIES = 'dimensions-queries',
    ORDER_BY_ACTIONS = 'order-actions',
    ORDER_BY_VIEWS = 'order-views',
    ORDER_BY_QUERIES = 'order-queries',
}
