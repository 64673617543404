import { FEATURE_ENUM } from 'app/api/types/plans_features';
import { USE_USER_SIDEBAR_FEATURES } from 'app/common/data/featureFlags';
import { useHasPage } from 'app/common/hooks/accessesAndPermissions/useHasPage';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import useOrganization from 'app/common/hooks/queries/useOrganization';

export const useHasBulkModificationPage = (): boolean | undefined => {
    const { data: me } = useMe();
    const { data: organization } = useOrganization(me?.org_id ?? 0, !!me);

    if (USE_USER_SIDEBAR_FEATURES) {
        return useHasPage(FEATURE_ENUM.BULK_MODIFICATION);
    } else {
        return me?.can_use_bulk_modification && organization?.has_bulk_modification;
    }
};
