import { ReviewsStatsState } from '../reducers/types';

export const reviewStatsSelector = (state: any): ReviewsStatsState =>
    state.reviews.stats;

export const reviewCountTreatedSelector = (state: ReviewsStatsState): number =>
    state.treated;

export const reviewCountUntreatedSelector = (
    state: ReviewsStatsState,
): number => state.notTreated;

export const reviewCountLiveSelector = (state: ReviewsStatsState): number =>
    state.count - state.deleted;

export const reviewCountDeletedSelector = (state: ReviewsStatsState): number =>
    state.deleted;

export const reviewCountSelector = (state: ReviewsStatsState): number =>
    state.count;
