import { useMemo, useState } from 'react';

import {
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    Stack,
    Table,
    Text,
} from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { ReviewAnalyticsDimension } from 'app/api/types/review_analytics';
import { sumValues } from 'app/common/utils/sumValues';

import { createColumnConfig } from './common/createColumnConfig';
import { DimensionSwitch } from './common/DimensionSwitch';
import { PageNavigator } from './common/PageNavigator';
import { useHandleSort } from './hooks/useHandleSort';
import { useInternalRankingParameters } from './hooks/useInternalRankingParameters';
import { StyledBackgroundTableCell, StyledCell, StyledHeader } from './Rankings.styled';
import { formatValue, generateTableColumns } from '../../../helper';
import {
    getApiPage,
    getDataForTablePage,
    useReplyTimeInternalRanking,
} from '../../../hooks/useMetrics';
import { ExportModal } from '../../ExportModal/ExportModal';

export const ReplyTimeRanking = () => {
    const [page, setPage] = useState(1);

    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    const { currentOrder, handleSort } = useHandleSort('ratio__reply_time__fast', 'desc');
    const orderBy =
        currentOrder.order === 'asc' ? currentOrder.columnId : `-${currentOrder.columnId}`;
    const metrics = useReplyTimeInternalRanking(getApiPage(page), orderBy);
    const {
        dimension: [selectedDimension],
    } = useInternalRankingParameters();
    const metrics_data = metrics || { count: 0, data: [] };
    const data = useMemo(() => {
        return getDataForTablePage(metrics_data?.data ?? [], page);
    }, [metrics_data?.data, page]);

    const columnsConfig = useMemo(
        () => [
            createColumnConfig('#', '#', '300px', false),
            createColumnConfig(
                'dimension_name',
                t(`review_analytics_rankings_${selectedDimension}`),
                '3000px',
            ),
            createColumnConfig('reviews', t('review_analytics_rankings_reviews'), '1000px', false),
            createColumnConfig(
                'ratio__reply_time__fast',
                t('review_analytics_rankings_reply_time_fast'),
                '1000px',
            ),
            createColumnConfig(
                'ratio__reply_time__slow',
                t('review_analytics_rankings_reply_time_slow'),
                '1000px',
            ),
            createColumnConfig(
                'ratio__reply_time__not_replied',
                t('review_analytics_rankings_reply_time_not_replied'),
                '1000px',
                selectedDimension === 'business',
            ),
        ],
        [selectedDimension, t],
    );

    const tableColumns = generateTableColumns(currentOrder, handleSort, setPage, columnsConfig);

    return (
        <Stack>
            <StyledHeader>
                <Text variant="heading6">{t('review_analytics_internal_ranking')}</Text>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <DimensionSwitch />
                    <IconButton
                        dataTrackId="review_analytics__ranking_reply_time_download"
                        appearance="outlined"
                        icon={[FontAwesomeIconsPartooUsed.faArrowDownToLine, IconPrefix.REGULAR]}
                        tooltipPlacement="bottom"
                        tooltip={t('download_data')}
                        onClick={() => setOpenModal(true)}
                    />
                </Stack>
            </StyledHeader>
            <Table columns={tableColumns}>
                <Table.Body>
                    {data.map(({ dimension, dimension_name, metrics }) => {
                        const reviewCount = sumValues(metrics.reply_time) ?? 0;
                        const fastReply = metrics.reply_time?.fast;
                        const slowReply = metrics.reply_time?.slow;
                        const noReply = metrics.reply_time?.not_replied;

                        return (
                            <Table.Row
                                dataTrackId="review_analytics__ranking_reply_time"
                                id={dimension}
                                key={dimension}
                            >
                                <StyledCell>
                                    {reviewCount === 0 || metrics.rank == null
                                        ? 'NC'
                                        : metrics.rank}
                                </StyledCell>
                                <StyledCell align="left">{dimension_name}</StyledCell>
                                <StyledCell>
                                    {selectedDimension === 'business' ? reviewCount : '-'}
                                </StyledCell>
                                <StyledBackgroundTableCell color="success">
                                    {formatValue(fastReply, reviewCount)}
                                </StyledBackgroundTableCell>
                                <StyledCell>{formatValue(slowReply, reviewCount)}</StyledCell>
                                <StyledCell>
                                    {selectedDimension === 'business'
                                        ? formatValue(noReply, reviewCount)
                                        : '-'}
                                </StyledCell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
            <PageNavigator
                dataTrackIdKey="reply_time"
                dataCount={metrics_data.count}
                dataLength={metrics_data.data.length}
                page={page}
                setPage={setPage}
            />
            <ExportModal
                isOpenExportModal={openModal}
                setIsOpenExportModal={setOpenModal}
                dimensionType={selectedDimension as ReviewAnalyticsDimension}
            />
        </Stack>
    );
};
