import { Loader } from '@partoohub/ui';

import BusinessModalButton from 'app/common/components/businessModal';
import { FilterBusinessModalButton } from 'app/common/components/businessModal/components/FilterBusinessModalButton/FilterBusinessModalButton';
import { ComparisonPeriodSelect } from 'app/pages/analytics/CompetitiveBenchmark/components/ComparisonPeriodSelect/ComparisonPeriodSelect';
import { FiltersSectionContainer } from 'app/pages/analytics/CompetitiveBenchmark/components/FiltersSection/FiltersSection.styled';
import { KeywordDropDownSelection } from 'app/pages/analytics/CompetitiveBenchmark/components/KeywordsDropDownSelection/KeywordsDropDownSelection';

import { MonthPicker } from 'app/pages/analytics/CompetitiveBenchmark/components/MonthPicker/MonthPicker';

import { useFiltersAreLoading } from '../../hooks/useFiltersAreLoading';

export const FiltersSection = () => {
    const isLoading = useFiltersAreLoading();

    if (isLoading) {
        return <Loader />;
    }

    return (
        <FiltersSectionContainer>
            <BusinessModalButton
                key="business-modal-key"
                dataTrack="analytics_visiblity__business_filter"
                button={
                    <div>
                        <FilterBusinessModalButton />
                    </div>
                }
            />
            <KeywordDropDownSelection />
            <MonthPicker />
            <ComparisonPeriodSelect />
        </FiltersSectionContainer>
    );
};
