import { useMemo } from 'react';

import { List } from '@partoohub/ui';
import { uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';
import { useInfiniteQuery } from 'react-query';

import { SearchBusinessesReceivedData } from 'app/api/types/business';
import api from 'app/api/v2/api_calls';
import { usePartnerConnectionContext } from 'app/businessConnection/components/PartnerConnectionContext';
import {
    TO_CONNECT_TO_FACEBOOK,
    TO_CONNECT_TO_GMB,
    useNavTabSelector,
} from 'app/businessConnection/hooks/useNavTab';
import NoBusinessToConnect from 'app/common/components/placeholder/KnowledgeManagement/NoBusinessToConnect.container';
import NoBusiness from 'app/common/components/placeholder/NoBusiness.container';
import { BUSINESS_CONNECTION } from 'app/common/data/queryKeysConstants';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { ConnectionQueryKeys } from 'app/pages/settingsV2/subPages/Platforms/components/Connection/utils/enums';
import { ALL_TAB } from 'app/states/knowledge/reducers/businessConnection/nav';

import BusinessRow from './BusinessRow';
import { ConnectionsTableLoadingState } from './ConnectionsTableLoadingState/ConnectionsTableLoadingState';

const ConnectionsTable = () => {
    const { t } = useTranslation();
    const { businessFilter } = usePartnerConnectionContext();
    const navTab = useNavTabSelector();
    const [searchQuery] = useStateQueryParams(ConnectionQueryKeys.QUERY);

    const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } = useInfiniteQuery(
        [BUSINESS_CONNECTION, { navTab, searchQuery, businessFilter }],
        ({ pageParam = 1 }) => {
            const params = {};
            if (businessFilter.org_id) {
                params['org_id'] = businessFilter.org_id;
            }
            if (businessFilter.org_id__in) {
                params['org_id__in'] = businessFilter.org_id__in;
            }
            if (navTab === TO_CONNECT_TO_GMB) {
                params['connected_to_gmb_location'] = false;
            }
            if (navTab === TO_CONNECT_TO_FACEBOOK) {
                params['connected_to_facebook_location'] = false;
            }

            return api.business.searchBusinesses({
                ...params,
                query: searchQuery,
                page: pageParam,
            });
        },
        {
            getNextPageParam: (lastPage: SearchBusinessesReceivedData) => {
                return lastPage.max_page > lastPage.page ? lastPage.page + 1 : undefined;
            },
        },
    );

    const businesses = useMemo(
        () => uniqBy(data?.pages?.flatMap(page => page.businesses) ?? [], business => business.id),
        [data],
    );

    const columns = [
        { label: t('locations') },
        { label: 'Google', width: '170px' },
        { label: 'Facebook', width: '170px' },
    ];

    if (!isLoading && !businesses.length) {
        return navTab === ALL_TAB ? <NoBusiness /> : <NoBusinessToConnect />;
    }

    return (
        <InfiniteScroll
            hasMore={hasNextPage && !isFetchingNextPage}
            loadMore={() => fetchNextPage()}
            useWindow={false}
            getScrollParent={() => document.getElementById('react-content')}
        >
            <List columns={columns} loading={isLoading}>
                {businesses.length === 0 && <ConnectionsTableLoadingState />}

                {businesses.length > 0 &&
                    businesses.map(business => (
                        <BusinessRow key={business.id} business={business} />
                    ))}
            </List>
        </InfiniteScroll>
    );
};

export default ConnectionsTable;
