import { camelCase, mapKeys, mapValues } from 'lodash-es';

import {
    GoogleVerifications,
    V2FormattedLocationData,
    V2FormattedLocationPartnerStatusData,
    V2LocationData,
} from 'app/api/types/account';
import { LOCATION_TYPE } from 'app/common/data/locationTypes';

const defaultLocationStatus: V2FormattedLocationPartnerStatusData = {
    status: null,
    owningBusinessId: '',
};

const defaultLocationVerification: GoogleVerifications = {
    status: null,
    methodUsed: '',
};

const defaultLocationField: V2FormattedLocationData = {
    id: '',
    name: '',
    locationName: '',
    // @ts-ignore
    partnerAccountId: '',
    storeCode: '',
    regionCode: '',
    postalCode: '',
    addressDetails: '',
    locality: '',
    type: LOCATION_TYPE,
    canModifyServiceList: false,
    partnerConnection: defaultLocationStatus,
    googleVerifications: defaultLocationVerification,
};

function formatLocation(location: V2LocationData): V2FormattedLocationData {
    const formattedLocation = mapKeys(location, (_, key) => camelCase(key));
    // @ts-ignore
    return mapValues(defaultLocationField, (v, key) => formattedLocation[key] || v);
}

const formatLocations = (locations: Array<V2LocationData>): Array<V2FormattedLocationData> =>
    locations.map(formatLocation);

export default formatLocations;
