import React, { ChangeEvent, MouseEvent, PureComponent } from 'react';

import { debounce } from 'lodash-es';

import { Choice } from 'app/api/types/user';
import Clickable from 'app/common/components/buttons/Clickable';
import Menu from 'app/common/designSystem/components/atoms/CommonSelectMenu/CommonSelectMenu';
import explanationHOC from 'app/common/designSystem/components/atoms/ExplanationHOC';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

export type Props = {
    placeholder: string;
    onChange: (arg0: Array<Choice>) => void;
    options: Array<Choice>;
    selectedOptions: Array<Choice>;
    // Optional props
    searchPlaceholder?: string;
    explanation?: string;
    hideExplanation?: boolean;
    suggestedOptions?: Array<Choice>;
    suggestedOptionsHeader?: string;
    onClickSuggestion?: () => void;
    onOpenMenu?: () => void;
    // hide the explanation, in case it's empty
    disabled?: boolean;
    textTooltip?: string;
    // text of the tooltip
    loadMore?: (arg0: string) => void;
    // called when scrolled with hasMore, or when searchText changed
    hasMore?: boolean;
    // display the loading and call loadMore if scrolled to bottom
    hasError?: boolean;
    hasWarning?: boolean;
    isLoading?: boolean;
    // display the loading, do not use with hasMore
    isMandatory?: boolean;
    mustFilterOptions?: boolean;
    // only filter if word startsWith
    searchBeginningWords?: boolean;
    // default true. disable automatic filter on options array.
    selectedOptionsLimit?: number; // limit length of selected options
    dataTestId?: string;
    dataTrack?: string; // Used as static HTML identifier
};

type State = {
    wrapperRef: HTMLElement | null;
    inputRef: HTMLInputElement | null;
    hiddenSpanRef: HTMLElement | null;
    isFocused: boolean;
    currentSearch: string;
    hideMenu: boolean;
};

class AsyncMultipleSelect extends PureComponent<Props, State> {
    static defaultProps = {
        explanation: undefined,
        hideExplanation: false,
        disabled: false,
        textTooltip: undefined,
        hasMore: false,
        loadMore: () => ({}),
        hasError: false,
        hasWarning: false,
        isLoading: false,
        isMandatory: false,
        mustFilterOptions: true,
        selectedOptionsLimit: undefined,
        onOpenMenu: () => undefined,
        onClickSuggestion: () => undefined,
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            wrapperRef: null,
            inputRef: null,
            hiddenSpanRef: null,
            isFocused: false,
            currentSearch: '',
            hideMenu: false,
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    selectedOptionsLimitReached = () => {
        const { selectedOptionsLimit, selectedOptions } = this.props;
        return !!selectedOptionsLimit && selectedOptions.length === selectedOptionsLimit;
    };

    handleClickOutside = (event: Event) => {
        const { isFocused, wrapperRef } = this.state;

        if (
            isFocused &&
            wrapperRef &&
            event.target instanceof Node &&
            !wrapperRef.contains(event.target)
        ) {
            this.closeMenu();
        }
    };

    openMenu = () => {
        const { isFocused, inputRef, hideMenu } = this.state;
        const { onOpenMenu } = this.props;

        if (!isFocused) {
            this.setState({
                isFocused: !this.selectedOptionsLimitReached(),
                currentSearch: '',
            });
        } else if (hideMenu) {
            this.setState({
                hideMenu: false,
            });
        }

        if (inputRef && !this.selectedOptionsLimitReached()) inputRef.focus();
        if (onOpenMenu) onOpenMenu();
    };

    closeMenu = () => {
        const { loadMore } = this.props;

        this.setState({
            isFocused: false,
            hideMenu: false,
        });

        if (loadMore) loadMore('');
    };

    /*
      Change dynamically the width of the input
    */
    onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { inputRef, hiddenSpanRef } = this.state;

        if (hiddenSpanRef && inputRef) {
            hiddenSpanRef.textContent = e.target.value;
            inputRef.style.width = `${hiddenSpanRef.offsetWidth + 5}px`;
        }

        this.setState(
            {
                currentSearch: e.target.value,
                hideMenu: false,
            },
            () => {
                this.debouncedLoadMore();
            },
        );
    };

    setWrapperRef = (ref: HTMLElement | null) => {
        this.setState({
            wrapperRef: ref,
        });
    };

    setInputRef = (ref: HTMLInputElement | null) => {
        this.setState({
            inputRef: ref,
        });
    };

    setHiddenSpanRef = (ref: HTMLElement | null) => {
        this.setState({
            hiddenSpanRef: ref,
        });
    };

    reset = () => {
        const { onChange } = this.props;
        onChange([]);
    };

    onItemClick = (item: Choice, e: MouseEvent<HTMLButtonElement>, suggestion = false) => {
        e.preventDefault();
        e.stopPropagation();
        const { onChange, selectedOptions, selectedOptionsLimit, loadMore, onClickSuggestion } =
            this.props;
        const { inputRef } = this.state;
        const filtered = item
            ? selectedOptions.filter(option => option.value !== item.value)
            : selectedOptions;
        const limitExceeded = selectedOptionsLimit
            ? selectedOptions.length >= selectedOptionsLimit
            : false;

        let newValue = filtered;

        if (filtered.length === selectedOptions.length && !limitExceeded) {
            newValue = [...selectedOptions, item];
        }
        onChange(newValue);

        if (suggestion && onClickSuggestion) {
            onClickSuggestion();
        }

        const lastSuggestion = !!selectedOptionsLimit && newValue.length >= selectedOptionsLimit;

        this.setState({
            hideMenu: lastSuggestion,
            isFocused: !lastSuggestion,
            currentSearch: '',
        });

        if (inputRef) {
            inputRef.value = '';
            inputRef.focus();
        }

        if (loadMore) loadMore('');
    };

    debouncedLoadMore = debounce(() => {
        const { loadMore } = this.props;
        const { currentSearch } = this.state;
        if (loadMore) loadMore(currentSearch);
    }, 250);

    render() {
        const {
            placeholder,
            searchPlaceholder,
            options,
            selectedOptions,
            disabled,
            textTooltip,
            hasError,
            hasWarning,
            hasMore,
            loadMore,
            isLoading,
            isMandatory,
            mustFilterOptions,
            searchBeginningWords,
            dataTestId,
            suggestedOptions,
            suggestedOptionsHeader,
            dataTrack,
        } = this.props;
        const { isFocused, currentSearch, hideMenu } = this.state;
        let modifier = '';

        if (isFocused && hasError) {
            modifier = '--focused_error';
        } else if (isFocused && hasWarning) {
            modifier = '--focused_warning';
        } else if (hasError) {
            modifier = '--error';
        } else if (hasWarning) {
            modifier = '--warning';
        } else if (isFocused) {
            modifier = '--focused';
        } else {
            modifier = '--unfocused';
        }

        let menuOptions;
        if (mustFilterOptions) {
            if (searchBeginningWords) {
                menuOptions = options.filter(
                    ({ label }) =>
                        (label || '')
                            .toLowerCase()
                            .split(/[\s-]+/)
                            .filter(word => word.startsWith(currentSearch.toLocaleLowerCase()))
                            .length,
                );
            } else {
                menuOptions = options.filter(({ label }) =>
                    (label || '').toLowerCase().includes(currentSearch.toLowerCase()),
                );
            }
        } else {
            menuOptions = options;
        }

        const component = (
            <div
                className={`
                    multiple_select
                    ${disabled ? 'multiple_select--disabled' : ''}
                `}
                ref={this.setWrapperRef}
                data-track={dataTrack}
                data-intercom-target={dataTrack}
                {...(dataTestId ? { 'data-testid': dataTestId } : {})}
            >
                <Clickable
                    className={`
                        multiple_select__field
                        multiple_select__field--async
                        multiple_select__field${modifier}
                        ${disabled ? 'multiple_select__field--disabled' : ''}
                    `}
                    onClick={this.openMenu}
                    disabled={disabled}
                >
                    <div
                        className={`
                            multiple_select__placeholder
                            ${isFocused ? 'multiple_select__placeholder--focused' : ''}
                            ${hasError ? 'multiple_select__placeholder--error' : ''}
                            ${
                                hasWarning && !hasError
                                    ? 'multiple_select__placeholder--warning'
                                    : ''
                            }
                            ${
                                selectedOptions.length > 0 || isFocused
                                    ? 'multiple_select__placeholder--top'
                                    : ''
                            }
                        `}
                    >
                        <div>
                            {placeholder}
                            {isMandatory && <span className="mandatory-field"> *</span>}
                        </div>
                    </div>
                    {searchPlaceholder &&
                        !currentSearch &&
                        isFocused &&
                        !selectedOptions.length && (
                            <div className="multiple_select__search_placeholder">
                                {searchPlaceholder}
                            </div>
                        )}
                    <div className="multiple_select__field_items">
                        {selectedOptions.map((option, idx) => (
                            <div
                                className="multiple_select__field_items_item font-small-bold"
                                key={option.value + idx}
                            >
                                {option.icon && (
                                    <i className={`${option.icon}  margin_right--simple`} />
                                )}
                                {option.label}
                                <button
                                    type="button"
                                    className="multiple_select__field_items_item_delete"
                                    onClick={e => this.onItemClick(option, e)}
                                >
                                    <i className="fa-solid fa-times-circle" />
                                </button>
                            </div>
                        ))}
                        {isFocused && (
                            <div className="async-input">
                                <span ref={this.setHiddenSpanRef} />
                                <input
                                    ref={this.setInputRef}
                                    type="text"
                                    onChange={this.onChange}
                                    autoFocus
                                />
                            </div>
                        )}
                    </div>
                    {selectedOptions.length > 0 ? (
                        <button
                            type="button"
                            className="multiple_select__field__icon_button"
                            onClick={this.reset}
                        >
                            <i
                                className={`fas fa-times ${
                                    hasError ? 'multiple_select__field__icon_button--error' : ''
                                }`}
                            />
                        </button>
                    ) : (
                        <button type="button" className="multiple_select__field__icon_button">
                            <i
                                className={`fas fa-caret-down ${
                                    hasError ? 'multiple_select__field__icon_button--error' : ''
                                }`}
                            />
                        </button>
                    )}
                </Clickable>
                {isFocused && !hideMenu && !this.selectedOptionsLimitReached() && (
                    <Menu
                        options={menuOptions}
                        selectedOptions={selectedOptions}
                        onClick={this.onItemClick}
                        closeMenu={this.closeMenu}
                        hasMore={hasMore}
                        loadMore={() => {
                            if (loadMore) loadMore(currentSearch);
                        }}
                        isLoading={isLoading}
                        suggestedOptions={suggestedOptions}
                        suggestedOptionsHeader={suggestedOptionsHeader}
                        currentSearch={currentSearch}
                    />
                )}
            </div>
        );

        return (
            <div className="flex--fill flex--col">
                {textTooltip ? (
                    <TooltipWrapper text={textTooltip} position="bottom-start">
                        {component}
                    </TooltipWrapper>
                ) : (
                    component
                )}
            </div>
        );
    }
}

export default explanationHOC<Props>(AsyncMultipleSelect);
