import { Chart, ChartType, ScatterOptions } from '@partoohub/ui';
import { Chart as ChartJS } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useTranslation } from 'react-i18next';

import { sumValues } from 'app/common/utils/sumValues';

import {
    calculateXProportion,
    calculateYProportion,
    createBackground,
    createLabel,
    getQuadrantTextPosition,
    getReviewQuantity,
} from '../../../helper';
import { useReviewTagMetrics } from '../../../hooks/useMetrics';

ChartJS.register(annotationPlugin);

interface ScatterOptionsWithTooltip extends ScatterOptions {
    plugins: {
        legend?: { [key: string]: any };
        tooltip?: { [key: string]: any };
        annotation?: {
            annotations: Record<string, any>;
        };
    };
}
export const TagsGraph = () => {
    const { t } = useTranslation();
    const metrics = useReviewTagMetrics();

    if (!metrics) return undefined;

    const { data: rawData } = metrics;

    const xValues = rawData.map(d => sumValues(d.metrics.rating_distribution));
    const maxReview = Math.max(...xValues);
    const avgReview = xValues.reduce((sum, curr) => sum + curr, 0) / rawData.length;

    const tagsData = rawData.map(d => {
        const reviewCount = sumValues(d.metrics.rating_distribution);

        const x = calculateXProportion(avgReview, maxReview, reviewCount);
        const y = calculateYProportion(d.metrics.average_rating);
        const averageRating = d.metrics.average_rating;
        const tagName = d.dimension_name;
        return { x, y, averageRating, reviewCount, tagName };
    });

    const { minReviewQuantity, maxReviewQuantity, middleReviewQuantity } =
        getReviewQuantity(xValues);

    const minY = 0,
        maxY = 6,
        middleY = 3;

    const quadrantsData = [
        {
            data: tagsData.filter(d => d.x >= middleReviewQuantity && d.y >= middleY), //top-right
            color: 'rgb(44,184,132)',
        },
        {
            data: tagsData.filter(
                d =>
                    (d.x < middleReviewQuantity && d.y >= middleY) ||
                    (d.x === middleReviewQuantity && d.y === middleY),
            ), //top-left
            color: 'rgb(0,133,242)',
        },
        {
            data: tagsData.filter(d => d.x <= middleReviewQuantity && d.y < middleY), //bottom-left
            color: 'rgba(255, 144, 20, 1)',
        },
        {
            data: tagsData.filter(d => d.x > middleReviewQuantity && d.y < middleY), //bottom-right
            color: 'rgba(255, 66, 110, 1)',
        },
    ];

    const quadrants = {
        datasets: quadrantsData.map(q => ({
            data: q.data,
            backgroundColor: q.color,
        })),
    };

    const options: ScatterOptionsWithTooltip = {
        scales: {
            x: {
                type: 'linear',
                position: 'center',
                display: false,
                min: minReviewQuantity - (maxReviewQuantity - minReviewQuantity) * 0.05,
                max: maxReviewQuantity + (maxReviewQuantity - minReviewQuantity) * 0.05,
            },
            y: {
                type: 'linear',
                position: 'center',
                display: false,
                min: minY - 0.5,
                max: maxY + 0.5,
            },
        },
        plugins: {
            legend: { display: false },
            tooltip: {
                displayColors: false,
                callbacks: {
                    label: (context: any) => {
                        const dataPoint = context.raw;
                        const reviewCount = dataPoint.reviewCount;
                        const averageRating = dataPoint.averageRating.toFixed(2);
                        const tagName = dataPoint.tagName;
                        return [
                            tagName,
                            `${reviewCount} ${t('header_counter_reviews', { count: reviewCount })} - ${averageRating}`,
                        ];
                    },
                },
            },
            annotation: {
                annotations: {
                    // Lines
                    line1: {
                        type: 'line',
                        xMin: middleReviewQuantity,
                        yMin: middleY + 0.1,
                        xMax: middleReviewQuantity,
                        yMax: maxY,
                        borderColor: 'rgba(20, 37, 66, 0.12)',
                        borderWidth: 1,
                    },
                    line2: {
                        type: 'line',
                        xMin: middleReviewQuantity,
                        yMin: minY,
                        xMax: middleReviewQuantity,
                        yMax: middleY - 0.1,
                        borderColor: 'rgba(20, 37, 66, 0.12)',
                        borderWidth: 1,
                    },
                    line3: {
                        type: 'line',
                        xMin: minReviewQuantity + (maxReviewQuantity - minReviewQuantity) * 0.02,
                        yMin: middleY,
                        xMax: maxReviewQuantity - (maxReviewQuantity - minReviewQuantity) * 0.03,
                        yMax: middleY,
                        borderColor: 'rgba(20, 37, 66, 0.12)',
                        borderWidth: 1,
                    },
                    // Labels
                    label1: createLabel({
                        position: getQuadrantTextPosition(minReviewQuantity, middleY),
                        label: t('review_analytics_tag_rare'),
                    }),
                    label2: createLabel({
                        position: getQuadrantTextPosition(maxReviewQuantity, middleY),
                        label: t('review_analytics_tag_frequent'),
                    }),
                    label3: createLabel({
                        position: getQuadrantTextPosition(middleReviewQuantity, maxY + 0.3),
                        label: t('review_analytics_tag_5_stars'),
                    }),
                    label4: createLabel({
                        position: getQuadrantTextPosition(middleReviewQuantity, minY - 0.3),
                        label: t('review_analytics_tag_1_star'),
                    }),
                    label5: createLabel({
                        position: getQuadrantTextPosition(
                            (minReviewQuantity + middleReviewQuantity) * 0.5,
                            maxY + 0.3,
                        ),
                        label: t('review_analytics_tag_reinforced'),
                        fontSize: 18,
                        bold: true,
                    }),
                    label6: createLabel({
                        position: getQuadrantTextPosition(
                            (maxReviewQuantity + middleReviewQuantity) * 0.5,
                            maxY + 0.3,
                        ),
                        label: t('review_analytics_tag_promoted'),
                        fontSize: 18,
                        bold: true,
                    }),
                    label7: createLabel({
                        position: getQuadrantTextPosition(
                            (minReviewQuantity + middleReviewQuantity) * 0.5,
                            minY - 0.3,
                        ),
                        label: t('review_analytics_tag_developed'),
                        fontSize: 18,
                        bold: true,
                    }),
                    label8: createLabel({
                        position: getQuadrantTextPosition(
                            (maxReviewQuantity + middleReviewQuantity) * 0.5,
                            minY - 0.3,
                        ),
                        label: t('review_analytics_tag_corrected'),
                        fontSize: 18,
                        bold: true,
                    }),
                    // Backgrounds
                    background1: createBackground({
                        xMin: minReviewQuantity,
                        xMax: middleReviewQuantity,
                        yMin: middleY,
                        yMax: maxY + 0.06,
                        color: 'rgba(0, 133, 242, 0.05)', //left-top
                    }),
                    background2: createBackground({
                        xMin: middleReviewQuantity,
                        xMax: maxReviewQuantity,
                        yMin: middleY,
                        yMax: maxY + 0.06,
                        color: 'rgba(64, 219, 162, 0.05)', //right-top
                    }),
                    background3: createBackground({
                        xMin: minReviewQuantity,
                        xMax: middleReviewQuantity,
                        yMin: minY - 0.06,
                        yMax: middleY,
                        color: 'rgba(255, 144, 20, 0.05)', //left-bottom
                    }),
                    background4: createBackground({
                        xMin: middleReviewQuantity,
                        xMax: maxReviewQuantity,
                        yMin: minY - 0.06,
                        yMax: middleY,
                        color: 'rgba(255, 66, 110, 0.05)', //right-bottom
                    }),
                },
            },
        },
    };

    return (
        <Chart
            dataTrackId="review_analytics__chart_tags"
            data={quadrants}
            options={options}
            type={ChartType.Quadrant}
        />
    );
};
