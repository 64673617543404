import { Navigate, useRoutes } from 'react-router-dom';

import IS_IFRAME from 'app/common/data/iframe';
import { SettingsV2Page } from 'app/pages/settingsV2';

import {
    INTEGRATIONS_PATH_TOKEN,
    INVOICE_PATH_TOKEN,
    MAIN_PATH_TOKEN,
    MESSAGES_PATH_TOKEN,
    NOT_FOUND_PATH,
    PLATFORMS_PATH_TOKEN,
    REVIEWS_PATH_TOKEN,
    SETTINGS_MAIN_INVOICES_PATHNAME,
    TEAM_PATH_TOKEN,
} from 'app/routing/routeIds';

import { IntegrationsRoutes } from 'app/routing/subRoutes/settings/Integrations';
import { MainRoutes } from 'app/routing/subRoutes/settings/Main';
import { MessagesRoutes } from 'app/routing/subRoutes/settings/Messages';
import { PlatformsRoutes } from 'app/routing/subRoutes/settings/Platforms';
import { ReviewsRoutes } from 'app/routing/subRoutes/settings/Reviews';
import { TeamRoutes } from 'app/routing/subRoutes/settings/Team';

const SettingsRoutes = () => {
    const routes = useRoutes([
        {
            index: true,
            element: !IS_IFRAME ? <SettingsV2Page /> : <Navigate replace to={NOT_FOUND_PATH} />,
        },
        {
            path: `${MAIN_PATH_TOKEN}/*`,
            element: <MainRoutes />,
        },
        {
            path: `${TEAM_PATH_TOKEN}/*`,
            element: <TeamRoutes />,
        },
        {
            path: `${MESSAGES_PATH_TOKEN}/*`,
            element: <MessagesRoutes />,
        },
        {
            path: `${REVIEWS_PATH_TOKEN}/*`,
            element: <ReviewsRoutes />,
        },
        {
            path: `${PLATFORMS_PATH_TOKEN}/*`,
            element: <PlatformsRoutes />,
        },
        {
            path: `${INTEGRATIONS_PATH_TOKEN}/*`,
            element: <IntegrationsRoutes />,
        },
        {
            path: INVOICE_PATH_TOKEN,
            element: <Navigate replace to={SETTINGS_MAIN_INVOICES_PATHNAME} />,
        },
        {
            path: '*',
            element: <Navigate replace to={NOT_FOUND_PATH} />,
        },
    ]);
    return routes;
};

export default SettingsRoutes;
