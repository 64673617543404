import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import { useHasPresenceAnalyticsPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasPresenceAnalyticsPage';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { LoaderView } from 'app/pages/analytics/VisibilityAnalyticsV2/components/LoaderView/LoaderView';
import { Visibility } from 'app/pages/analytics/VisibilityAnalyticsV2/Visibility';
import { VisibilityWithNewSidebar } from 'app/pages/analytics/VisibilityAnalyticsV2/VisibilityWithNewSidebar';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

export const VisibilityAnalyticsV2Page = () => {
    const { t } = useTranslation();
    const { isLoading } = useMe();
    const hasPresenceAnalyticsPage = useHasPresenceAnalyticsPage();
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const VisibilityComponent = isNewSidebarEnabled ? VisibilityWithNewSidebar : Visibility;

    return (
        <>
            <DynamicPageHeader title={t('analytics')} />
            {!isLoading ? (
                <>
                    {hasPresenceAnalyticsPage ? (
                        <VisibilityComponent />
                    ) : (
                        <Navigate replace to={NOT_FOUND_PATH} />
                    )}
                </>
            ) : (
                <LoaderView height={100} />
            )}
        </>
    );
};
