export enum CustomFieldsQueryKeys {
    CREATE = 'create',
    DELETE = 'delete',
    EDIT = 'edit',
}

export enum CustomFieldsSectionQueryKeys {
    DELETE = 'section_delete',
    RENAME = 'section_rename',
}

export enum CustomFieldsCreationKeys {
    SECTION = 'section',
    CATALOG = 'catalog',
}
