import { ChartOptions, Tooltip } from 'chart.js';

// @ts-expect-error: Allow tooltip position to follow the cursor
// See: https://www.chartjs.org/docs/latest/configuration/tooltip.html#custom-position-modes
Tooltip.positioners.cursorPositioner = (_, eventPosition) => {
    return { x: eventPosition.x, y: eventPosition.y };
};

export const BAR_CHART_OPTIONS: ChartOptions = {
    plugins: {
        tooltip: {
            boxPadding: 4,
            // @ts-expect-error
            position: 'cursorPositioner',
            filter: ({ raw }) => {
                return !!raw;
            },
            callbacks: {
                label: ({ dataset, raw }) => {
                    return `${dataset.label}: ${(raw as number).toFixed(2)}%`;
                },
            },
        },
    },
    scales: {
        x: {
            grid: { display: false },
        },
        y: {
            grid: { display: false },
            max: 100,
        },
    },
} as const;

export const LINE_CHART_OPTIONS: ChartOptions = {
    clip: false, // Prevent line and point to be clipped when at the edges of the chart
    plugins: {
        tooltip: {
            boxPadding: 4,
            intersect: false, // Display tooltip even if the cursor is not right on the point
            mode: 'index', // Group points from the same abscissa into one tooltip
            position: 'nearest', // Tooltip is placed at the point closest to the cursor
            callbacks: {
                title: () => '',
                label: ({ datasetIndex, label, raw }) => {
                    const labels = label.split(',');
                    return [labels[datasetIndex], raw];
                },
            },
        },
    },
    scales: {
        x: {
            grid: { display: false },
            ticks: {
                callback: function (value) {
                    // @ts-expect-error: https://www.chartjs.org/docs/latest/axes/labelling.html#creating-custom-tick-formats
                    return this.getLabelForValue(value)[0];
                },
            },
        },
        y: {
            grid: { display: false },
        },
    },
} as const;
