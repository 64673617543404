import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { WIDGET_SETUP } from 'app/common/data/featureFlags';
import { ORG_ADMIN } from 'app/common/data/roles';
import { useHasMessagePage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasMessagePage';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useCanAccessWidgetSetupMenu = () => {
    const { data: me } = useMe();
    const hasMessages = useHasMessagePage();
    const isOrgAdmin = me?.role === ORG_ADMIN;
    const hasFlag = useFeatureFlag(WIDGET_SETUP);

    return hasMessages && isOrgAdmin && hasFlag;
};
