import { css } from '@emotion/react';
import { Filters, Option } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import { InteractionTagType } from 'app/api/types/interactionTag';
import api from 'app/api/v2/api_calls';
import { REVIEW_TAGS } from 'app/common/data/queryKeysConstants';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';

import { REVIEW_ANALYTICS_FILTER_TAGS } from '../../../hooks/useReviewAnalyticsFilters';

export const TagFilter = () => {
    const { t } = useTranslation();
    const [tagFilter, setTagFilter] = useStateQueryParams(REVIEW_ANALYTICS_FILTER_TAGS);
    const selectedTags =
        tagFilter !== ''
            ? tagFilter.split(',').reduce(
                  (allTags, tag) => {
                      return { ...allTags, [tag]: true };
                  },
                  {} as Record<string, boolean>,
              )
            : {};

    const { data: { tags } = { tags: [] }, isFetched } = useQuery(
        REVIEW_TAGS,
        // Getting all tags at once
        // (there are max 100 tags per orgs, therefore we set the perPage param to 100)
        () => api.interactionTag.getInteractionTags('', 1, 100),
    );

    const choices: Array<Option> = tags
        ? tags.map((tag: InteractionTagType) => ({
              label: `${tag.label}`,
              name: tag.id.toString(),
              value: tag.id.toString(),
          }))
        : [];

    const filteredTags = tags.filter(tag => selectedTags[tag.id]);

    const selectedTagLabels =
        filteredTags.length >= 3 || filteredTags.length === 0
            ? t('review_analytics_graph_section_tags')
            : filteredTags.map(tag => tag.label).join(', ') + '\u00A0\u00A0';

    const onFilterChange = (selected: Record<string, boolean>) => {
        setTagFilter(
            Object.entries(selected)
                .filter(([, value]) => !!value)
                .map(([tag]) => tag)
                .join(','),
        );
    };

    return isFetched && tags.length > 0 ? (
        <div
            css={css`
                min-width: 100px;
            `}
        >
            <Filters
                multiple
                dataTrackId=""
                placeholder={selectedTagLabels}
                position="bottom"
                menuListArray={[{ options: choices }]}
                selectedItems={selectedTags}
                maxHeight={300}
                onChange={onFilterChange}
            />
        </div>
    ) : (
        <></>
    );
};
