import { useTranslation } from 'react-i18next';

import { V2LocationData } from 'app/api/types/account';
import { ServiceAreaButton } from 'app/businessConnection/components/commonOld/ConnectionButtonLogo.styled';
import { LocationDetailsContainer } from 'app/businessConnection/components/commonOld/LocationDetails.styled';

import { CardLine } from './CardLine';

type Props = {
    location: V2LocationData;
};

const LocationDetails = ({ location }: Props) => {
    const { t } = useTranslation();

    return (
        <LocationDetailsContainer>
            {!location.address_details && (
                <ServiceAreaButton>{t('google_location_service_area')}</ServiceAreaButton>
            )}

            {location.address_details && (
                <>
                    <CardLine text={location.address_details} />
                    <CardLine text={`${location.postal_code} ${location.locality}`} />
                </>
            )}
        </LocationDetailsContainer>
    );
};

export default LocationDetails;
