import { FEATURE_ENUM } from 'app/api/types/plans_features';
import useMe from 'app/common/hooks/queries/useMeUncamel';

/**
 * Return if the user has access to the page based on the feature
 * Return undefined if query in progress
 */
export const useHasPage = (feature: FEATURE_ENUM): boolean | undefined => {
    const { data: me } = useMe();
    return me?.available_pages?.includes(feature);
};
