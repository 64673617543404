import { useQuery } from 'react-query';

import { ReviewAlertsQueryParams } from 'app/api/types/review';
import API from 'app/api/v2/api_calls';
import { COMMENTS_NOT_FETCHED_COUNT } from 'app/common/data/queryKeysConstants';

export const useGetCommentsNotFetchedCount = () => {
    const queryParams: ReviewAlertsQueryParams = {
        has_comment_not_fetched: true,
        partner: 'google_my_business',
        fields: 'total',
    };
    const query = useQuery(COMMENTS_NOT_FETCHED_COUNT, () =>
        API.review.getReviewStats(queryParams),
    );
    return query.data?.total;
};
