import React, { useContext, useEffect, useState } from 'react';

import {
    Illustration,
    IllustrationCategoryEnum,
    IllustrationOldElement,
    IllustrationOldEnum,
} from '@partoohub/iconography';
import {
    Button,
    Dialog,
    Expandable,
    FontAwesomeIconsPartooUsed,
    Icon,
    Modal,
    Stack,
    Switch,
    Text,
    TextInput,
} from '@partoohub/ui';
import { Controller, useForm, useWatch } from 'react-hook-form';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CustomChatHours } from 'app/api/types/business_message_settings';
import { DAYS } from 'app/businessEditV2/oldFromEditV1/data/constants';
import BusinessModal from 'app/common/components/businessModal/components/BusinessModal';
import { setSelectedBusinessIdsModalActions } from 'app/common/components/businessModal/context/businessModalCustomActions';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import useSelectedBusinessesCount from 'app/common/components/businessModal/hooks/useSelectedBusinessesCount';
import { BusinessSearchParams } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';
import { ALL_STATUSES_EXCEPT_CLOSED } from 'app/common/data/businessStatuses';
import { ControlledToggleBox } from 'app/common/designSystem/components/molecules/ToggleBox/ControlledToggleBox';
import useDebounce from 'app/common/hooks/useDebounce';
import {
    AutomaticMessagesConfigurationContentContainer,
    AutomaticMessagesConfigurationContentExpandableContent,
    AutomaticMessagesConfigurationContentHeader,
    AutomaticMessagesConfigurationContentWrapper,
    AutomaticMessagesConfigurationSectionContainer,
    AutomaticMessagesConfigurationSectionHeader,
    AutomaticMessagesConfigurationSectionHeaderChevronIcon,
    BusinessInputContainer,
    SaveActivationButtonContainer,
} from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/AutomaticMessagesConfigurationContent.styled';
import { AutomaticMessagesConfigurationMentionTextArea } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/AutomaticMessagesConfigurationMentionTextArea';
import { ConversationStarterItemsSectionList } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/components/ConversationStarterItemsSectionList';
import { ConversationStartersContext } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/contexts/ConversationStartersContext';
import useActivateConversationStarter from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useActivateConversationStarter';
import { useConversationStarter } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStarter';
import useConversationStartersItems from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useConversationStartersItems';
import useUpdateConversationStarter from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useUpdateConversationStarter';
import useUpdateConversationStarterBusinesses from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useUpdateConversationStarterBusinesses';
import { useValidateBusinesses } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/hooks/useValidateBusinesses';
import {
    ICE_BREAKER_SECTION,
    OFFLINE_SECTION,
} from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/types/conversationStarters';
import { CustomChatHoursToggleBox } from 'app/reviewManagement/messaging/configurationModal/CustomChatHoursToggleBox';

const SEARCH_PARAMS: BusinessSearchParams = {
    subscribed_to_bm: true,
    status: ALL_STATUSES_EXCEPT_CLOSED,
};

const defaultChatOpenHours: CustomChatHours = {
    monday: [{ startHour: '', endHour: '' }],
    tuesday: [{ startHour: '', endHour: '' }],
    wednesday: [{ startHour: '', endHour: '' }],
    thursday: [{ startHour: '', endHour: '' }],
    friday: [{ startHour: '', endHour: '' }],
    saturday: [{ startHour: '', endHour: '' }],
    sunday: [{ startHour: '', endHour: '' }],
};

type FormValues = {
    welcome_message: string;
    offline_message: string;
    conv_starters_enabled: boolean;
    use_custom_chat_hours: boolean;
    chat_open_hours: CustomChatHours;
};

// prop to change
const formatChatHours = (chatOpenHours?: CustomChatHours) => {
    return DAYS.reduce(
        (acc, day) => ({
            ...acc,
            [day]: chatOpenHours[day].map((window: string) => ({
                startHour: window.slice(0, 5),
                endHour: window.slice(-5),
            })),
        }),
        {} as CustomChatHours,
    );
};

const hasCustomChatHoursChanged = (a: CustomChatHours, b: CustomChatHours): boolean => {
    return DAYS.some(day => {
        const dayA = a[day as keyof CustomChatHours];
        const dayB = b[day as keyof CustomChatHours];

        if (dayA.length !== dayB.length) {
            return true;
        }

        return dayA.some(
            (window, index: number) =>
                window.startHour !== dayB[index].startHour ||
                window.endHour !== dayB[index].endHour,
        );
    });
};

export const AutomaticMessagesConfigurationContent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [businessesConflicting, setBusinessesConflicting] = useState(false);
    const [saveButtonIsEnabled, setSaveButtonIsEnabled] = useState(false);
    const [businessModalIsOpen, setBusinessModalIsOpen] = useState<boolean>(false);
    const [showDeactivateModal, setShowDeactivateModal] = useState(false);
    const [sectionOpened, setSectionOpened] = useState('');
    const { data: conversationStarter } = useConversationStarter();
    const [isOpened, setIsOpened] = useState(conversationStarter?.conv_starters_enabled);
    const businessesFilters = useBusinessModalFilters(true);
    const businessCount = useSelectedBusinessesCount();
    const updateBusinesses = useUpdateConversationStarterBusinesses(conversationStarter?.id);
    const validateBusinessSelection = useValidateBusinesses(conversationStarter?.id);
    const updateConvStarter = useUpdateConversationStarter(conversationStarter?.id);
    const activationHook = useActivateConversationStarter(conversationStarter?.id);

    const setFormValue = () => ({
        welcome_message: conversationStarter?.welcome_message ?? '',
        offline_message: conversationStarter?.offline_message ?? '',
        conv_starters_enabled: conversationStarter?.conv_starters_enabled ?? false,
        use_custom_chat_hours: !conversationStarter?.uses_business_hours,
        chat_open_hours: conversationStarter?.chat_open_hours
            ? formatChatHours(conversationStarter.chat_open_hours)
            : defaultChatOpenHours,
    });

    const { control, formState, clearErrors, watch, setValue } = useForm<FormValues>({
        defaultValues: setFormValue(),
    });

    useEffect(() => {
        if (conversationStarter) {
            DAYS.map(day =>
                setValue(
                    `chat_open_hours.${day}`,
                    formatChatHours(conversationStarter.chat_open_hours)[
                        day as keyof CustomChatHours
                    ],
                ),
            );
        }
    }, [conversationStarter]);

    const debouncedWelcomeMessage = useDebounce(watch('welcome_message'));
    const debouncedOfflineMessage = useDebounce(watch('offline_message'));
    const debouncedConvStartersEnabled = useDebounce(watch('conv_starters_enabled'));
    const debouncedUseCustomChatHours = useDebounce(watch('use_custom_chat_hours'));
    const debouncedChatOpenHours = useWatch({ name: 'chat_open_hours', control });

    const { data: conversationStarterItems, isLoading } = useConversationStartersItems(
        conversationStarter?.id,
    );

    const { setIsEnabled: setIsConvStarterEnabled, setCurrentWelcomeMessage } = useContext(
        ConversationStartersContext,
    );

    useEffect(() => {
        if (conversationStarter?.business_ids)
            dispatch(setSelectedBusinessIdsModalActions(conversationStarter?.business_ids));
        setValue('welcome_message', conversationStarter?.welcome_message ?? '');
        setValue('offline_message', conversationStarter?.offline_message ?? '');
        setValue('conv_starters_enabled', conversationStarter?.conv_starters_enabled ?? false);
        setValue('use_custom_chat_hours', !conversationStarter?.uses_business_hours);
        setValue(
            'chat_open_hours',
            conversationStarter?.chat_open_hours
                ? formatChatHours(conversationStarter?.chat_open_hours)
                : defaultChatOpenHours,
        );
    }, [conversationStarter, dispatch]);

    useEffect(() => {
        setIsConvStarterEnabled(debouncedConvStartersEnabled);
        setIsOpened(debouncedConvStartersEnabled);
    }, [debouncedConvStartersEnabled]);

    useEffect(() => {
        if (
            debouncedWelcomeMessage !== conversationStarter?.welcome_message ||
            debouncedOfflineMessage !== conversationStarter?.offline_message ||
            debouncedConvStartersEnabled !== conversationStarter?.conv_starters_enabled ||
            debouncedUseCustomChatHours !== !conversationStarter?.uses_business_hours ||
            (conversationStarter?.chat_open_hours &&
                hasCustomChatHoursChanged(
                    debouncedChatOpenHours,
                    formatChatHours(conversationStarter?.chat_open_hours),
                ))
        ) {
            setSaveButtonIsEnabled(true);
        }
    }, [
        debouncedConvStartersEnabled,
        debouncedWelcomeMessage,
        debouncedOfflineMessage,
        debouncedUseCustomChatHours,
        debouncedChatOpenHours,
    ]);

    useEffect(() => {
        if (conversationStarter?.id) {
            validateBusinessSelection.mutate(businessesFilters, {
                onSuccess: result => {
                    if (result.businesses.length > 0) {
                        setBusinessesConflicting(true);
                    }
                },
            });
        }
    }, [businessesFilters, conversationStarter]);

    useEffect(() => {
        setCurrentWelcomeMessage(debouncedWelcomeMessage);
    }, [debouncedWelcomeMessage]);

    const onSave = async () => {
        await updateConvStarter.mutateAsync({
            welcome_message: debouncedWelcomeMessage,
            offline_message: debouncedOfflineMessage,
            conv_starters_enabled: debouncedConvStartersEnabled,
            uses_business_hours: !debouncedUseCustomChatHours,
            chat_open_hours: debouncedChatOpenHours,
        });
        await updateBusinesses.mutateAsync(businessesFilters);

        setSaveButtonIsEnabled(false);
    };

    const onSaveAndActivate = async () => {
        if (!conversationStarter?.automatic_message_enabled) {
            await onSave();
            await activationHook.mutateAsync(true);
        } else {
            setShowDeactivateModal(true);
        }
    };

    const illustrationElement: IllustrationOldElement = {
        type: IllustrationCategoryEnum.Old,
        name: IllustrationOldEnum.Warning,
    };

    const handleResetBusinessHours = () => {
        clearErrors('uses_business_hours');
    };

    const handleSectionOpened = (sectionId: string) => {
        if (sectionId === sectionOpened) {
            setSectionOpened('');
        } else {
            setSectionOpened(sectionId);
        }
    };

    return (
        <AutomaticMessagesConfigurationContentWrapper gap="24px">
            <Stack direction="column" gap="16px">
                <BusinessModal
                    searchFilters={SEARCH_PARAMS}
                    opened={businessModalIsOpen}
                    closeModal={() => {
                        setBusinessModalIsOpen(false);
                        setSaveButtonIsEnabled(true);
                    }}
                    noBusinessLimit
                    withUrlSync={false}
                />
                <BusinessInputContainer>
                    <TextInput
                        dataTrackId=""
                        value={
                            businessCount
                                ? t('selected_businesses_with_count', {
                                      count: businessCount,
                                  })
                                : t('conversation_starters_settings_select_business_label')
                        }
                        onFocus={() => setBusinessModalIsOpen(true)}
                        className={businessCount ? 'selected-businesses' : 'no-selected-businesses'}
                    />
                    <Icon
                        dataTrackId=""
                        icon={['fa-chevron-right']}
                        color="secondary"
                        className="chevron-button"
                        iconSize="16px"
                        onClick={() => setBusinessModalIsOpen(true)}
                    />
                </BusinessInputContainer>
            </Stack>
            <Stack direction="column" gap="16px">
                <AutomaticMessagesConfigurationSectionContainer>
                    <AutomaticMessagesConfigurationSectionHeader
                        onClick={() => handleSectionOpened(ICE_BREAKER_SECTION)}
                    >
                        <Stack direction="row" justifyContent={'space-between'}>
                            <Text as="div" variant={'heading6'}>
                                {t('automatic_message_ice_breaker_section_title')}
                            </Text>
                            <AutomaticMessagesConfigurationSectionHeaderChevronIcon
                                icon={[FontAwesomeIconsPartooUsed.faChevronRight]}
                                isOpened={sectionOpened == ICE_BREAKER_SECTION}
                                color="secondary"
                            />
                        </Stack>
                    </AutomaticMessagesConfigurationSectionHeader>
                    <Expandable opened={sectionOpened == ICE_BREAKER_SECTION}>
                        <AutomaticMessagesConfigurationContentExpandableContent>
                            <Stack direction="column" gap="0">
                                <Stack direction="column" gap="16px">
                                    <Text as="div" variant="bodyLBold">
                                        {t('messages_welcome_message_title')}
                                    </Text>
                                    <AutomaticMessagesConfigurationMentionTextArea
                                        control={control}
                                        formState={formState}
                                        watch={watch}
                                        fieldId={'welcome_message'}
                                        fieldName={t('messages_welcome_message_title')}
                                    />
                                </Stack>

                                {/* Conversation starter items */}
                                <AutomaticMessagesConfigurationContentContainer isOpened={isOpened}>
                                    <AutomaticMessagesConfigurationContentHeader>
                                        <Stack direction={'column'} gap={'4px'}>
                                            <Text as="div" variant={'bodyLBold'}>
                                                {t('conversation_starters_settings_items_header')}
                                            </Text>
                                            <Text
                                                as="div"
                                                variant={'bodyMRegular'}
                                                color={'secondary'}
                                            >
                                                {t(
                                                    'conversation_starters_settings_items_description',
                                                )}
                                            </Text>
                                        </Stack>
                                        <Controller
                                            control={control}
                                            name="conv_starters_enabled"
                                            render={({ field: { ref: _ref, onChange, value } }) => (
                                                <Switch
                                                    dataTrackId="toggle_write_modification__switch"
                                                    name="toggle_write_modification"
                                                    onChange={onChange}
                                                    checked={value}
                                                />
                                            )}
                                        />
                                    </AutomaticMessagesConfigurationContentHeader>
                                    <Expandable opened={isOpened}>
                                        <AutomaticMessagesConfigurationContentExpandableContent>
                                            {!isLoading && conversationStarterItems && (
                                                <ConversationStarterItemsSectionList
                                                    conversationStarterId={conversationStarter.id}
                                                    conversationStarterItems={
                                                        conversationStarterItems.items
                                                    }
                                                />
                                            )}
                                        </AutomaticMessagesConfigurationContentExpandableContent>
                                    </Expandable>
                                </AutomaticMessagesConfigurationContentContainer>
                            </Stack>
                        </AutomaticMessagesConfigurationContentExpandableContent>
                    </Expandable>
                </AutomaticMessagesConfigurationSectionContainer>

                <AutomaticMessagesConfigurationSectionContainer>
                    <AutomaticMessagesConfigurationSectionHeader
                        onClick={() => handleSectionOpened(OFFLINE_SECTION)}
                    >
                        <Stack direction="row" justifyContent={'space-between'}>
                            <Text as="div" variant={'heading6'}>
                                {t('automatic_message_offline_section_title')}
                            </Text>
                            <AutomaticMessagesConfigurationSectionHeaderChevronIcon
                                icon={[FontAwesomeIconsPartooUsed.faChevronRight]}
                                isOpened={sectionOpened == OFFLINE_SECTION}
                                color="secondary"
                            />
                        </Stack>
                    </AutomaticMessagesConfigurationSectionHeader>
                    <Expandable opened={sectionOpened == OFFLINE_SECTION}>
                        <AutomaticMessagesConfigurationContentExpandableContent>
                            <Stack direction="column" gap="16px">
                                <AutomaticMessagesConfigurationMentionTextArea
                                    control={control}
                                    formState={formState}
                                    watch={watch}
                                    fieldId={'offline_message'}
                                    fieldName={t('messages_offline_message_title')}
                                    explanation={t('messages_offline_message_description')}
                                />

                                <ControlledToggleBox
                                    text={t('automatic_message_open_hour_title')}
                                    description={t('automatic_message_open_hour_description')}
                                    name={'use_custom_chat_hours'}
                                    control={control}
                                    onClick={handleResetBusinessHours}
                                />
                                <Expandable opened={debouncedUseCustomChatHours}>
                                    <CustomChatHoursToggleBox
                                        control={control}
                                        clearErrors={clearErrors}
                                        errors={formState.errors}
                                        withinConvStarters
                                    />
                                </Expandable>
                            </Stack>
                        </AutomaticMessagesConfigurationContentExpandableContent>
                    </Expandable>
                </AutomaticMessagesConfigurationSectionContainer>
            </Stack>

            <SaveActivationButtonContainer gap="16px" direction="row">
                <Button
                    onClick={onSave}
                    dataTrackId=""
                    appearance="contained"
                    shape="cube"
                    variant={'primary'}
                    size="large"
                    full
                    disabled={!saveButtonIsEnabled}
                    icon={['fa-floppy-disk']}
                >
                    {t('save')}
                </Button>

                <Button
                    onClick={onSaveAndActivate}
                    dataTrackId="automatic_messages_activate_button"
                    appearance={
                        conversationStarter?.automatic_message_enabled ? 'outlined' : 'contained'
                    }
                    shape="cube"
                    variant={
                        conversationStarter?.automatic_message_enabled ? 'secondary' : 'success'
                    }
                    size="large"
                    full
                    disabled={!businessCount}
                >
                    {conversationStarter?.automatic_message_enabled
                        ? t('automatic_messages_deactivate_button')
                        : t('automatic_messages_activate_button')}
                </Button>
            </SaveActivationButtonContainer>

            <Modal
                isOpen={businessesConflicting}
                closeModal={() => setBusinessesConflicting(false)}
            >
                <Dialog
                    dataTrackId=""
                    header={
                        <div style={{ paddingTop: '24px' }}>
                            <Illustration
                                dataTrackId="automatic_messages__illustration"
                                illustration={illustrationElement}
                                width="320"
                                height="140"
                            />
                        </div>
                    }
                    title={t('conv_starters_conflicting_business_selection_title')}
                    subTitle={t('conv_starters_conflicting_business_selection_subtitle')}
                >
                    <Stack direction="row">
                        <Button
                            dataTrackId=""
                            variant="secondary"
                            size="large"
                            onClick={() => {
                                setBusinessModalIsOpen(true);
                                setBusinessesConflicting(false);
                            }}
                            appearance="outlined"
                            full
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            dataTrackId=""
                            variant="primary"
                            size="large"
                            onClick={() => {
                                setBusinessesConflicting(false);
                                setSaveButtonIsEnabled(true);
                            }}
                            full
                        >
                            {t('confirm')}
                        </Button>
                    </Stack>
                </Dialog>
            </Modal>

            <ConfirmModal
                show={showDeactivateModal}
                title={t('automatic_messages_deactivate_modal_title')}
                content={t('automatic_messages_deactivate_modal_content')}
                confirmLabel={t('automatic_messages_deactivate_button')}
                modalFor="warning"
                onConfirm={() => {
                    activationHook.mutate(false);
                    setShowDeactivateModal(false);
                }}
                onHide={() => setShowDeactivateModal(false)}
                trackId="automatic_messages__deactivate_modal"
            />
        </AutomaticMessagesConfigurationContentWrapper>
    );
};
