// General
export const CITIES = 'cities';
export const GROUPS = 'groups';
export const GROUPS_V2 = 'groups_V2';
export const GROUP_DELETABLE = 'GROUP_DELETABLE';
export const SUGGESTIONS = 'suggestions';
export const BUSINESS = 'business';
export const ATTRIBUTES = 'attributes';
export const MORE_HOURS = 'more_hours';
export const PUBLISHERS = 'publishers';
export const PUBLISHER_INFOS = 'publisher_infos';
export const PUBLISHER_ERRORS = 'publisher_errors';
export const BUSINESS_CATEGORY = 'business_category';
export const BUSINESS_SUBSCRIPTIONS = 'business_subscriptions';
export const CATEGORIES = 'categories';
export const HOLIDAYS = 'holidays';
export const COUNTRY = 'country';
export const BUSINESSES = 'businesses';
export const BUSINESS_SEARCH_STATS = 'business_search_stats';
export const BUSINESSES_LANGUAGES = 'businesses_languages';
export const BUSINESS_CONNECTION = 'business_connection';
export const ME = 'me';
export const USERS = 'users';
export const USERS_LIST = 'usersList';
export const USER_STATS = 'userStats';
export const AVAILABLE_ROLES = 'availableRoles';
export const AVAILABLE_LANGUAGES = 'availableLanguages';
export const AUTH_TOKEN = 'authToken';
export const CONNECTION_STATS = 'connectionStats';
export const METRICS_ANALYTICS = 'metricsAnalytics';
export const KEYWORDS_ANALYTICS = 'keywordsAnalytics';
export const BUSINESS_DISABLED_DIFFUSERS = 'business_disabled_diffusers';
export const PLACE_ACTION_LINKS = 'place_action_links';
export const PRODUCT_UPSALE = 'productUpsale';
export const REQUEST_PARTNER_UPSALE = 'requestPartnerUpsale';
export const OAUTH_ACCOUNTS = 'accounts';
export const NUMBER_IMPORTED_OAUTH_ACCOUNTS = 'numberImportedOauthAccounts';
export const META_RELOGIN = 'metaRelogin';
export const CUSTOM_FIELDS = 'customFields';
export const CUSTOM_FIELDS_SECTION = 'customFieldsSections';
export const PARTNER_LINKS = 'partnerLinks';

// Business fields
export const ORG_BUSINESS_EDIT_FIELDS = 'orgBusinessEditFields';
export const BUSINESS_LOCATIONS = 'businessLocation';
export const BUSINESS_EDIT_FIELDS = 'businessEditFields';
export const BUSINESS_SERVICES = 'businessServices';
export const SERVICES_SUGGESTIONS = 'servicesSuggestions';
export const BUSINESS_FOODMENUS = 'businessFoodmenus';
export const IS_SERVICES_AVAILABLE = 'is_services_available_query';

export const PUBLISHERS_STATES = 'publishersStates';
export const PUBLISHERS_STATES_BUSINESSES_INFO = 'publishersStatesBusinessesInfo';
export const PUBLISHERS_STATES_BUSINESS_INFO = 'publishersStatesBusinessInfo';
export const POST_PUBLISHER_STATES_EXPORT = 'postPublisherStatesExport';

// Google posts
export const POSTS_BUSINESS_SCOPES = 'postsBusinessScopes';
export const POST_STATE = 'postState';

// Reviews
export const REVIEWS_STATS = 'reviewsStats';
export const REVIEW_LIST = 'reviewList';
export const UNSEEN_REVIEWS_COUNT = 'unseenReviewsCount';
export const REVIEW_TAGS = 'reviewTags';
export const REVIEW_TAG_STATUS = 'reviewTagStatus';
export const COMMENTS_NOT_FETCHED_COUNT = 'commentsNotFetchedCount';

// Review Analytics
export const REVIEW_ANALYTICS_METRICS = 'reviewAnalyticsMetrics';

// Feedback Management
export const FEEDBACK_FORM = 'feedbackForm';
export const REVIEW_USER_REPORT_PREFERENCES = 'reviewUserReportPreferences';

// Reply templates
export const REPLY_TEMPLATE_LANGUAGE = 'replyTemplateLanguage';
export const REPLY_TEMPLATE_LIST = 'replyTemplateList';
export const REPLY_TEMPLATE_PLACEHOLDERS = 'replyTemplatePlaceholders';

// Review auto reply settings
export const REVIEW_AUTO_REPLY_SETTINGS = 'review_auto_reply_settings';
export const REVIEW_AUTO_REPLY_SELECTED_BUSINESSES = 'review_auto_reply_selected_businesses';
export const REVIEW_AUTO_REPLY_SUGGESTED_TEMPLATES = 'review_auto_reply_suggested_templates';

// Review booster
export const REVIEW_BOOSTER_SMS_TEMPLATE = 'reviewBoosterSmsTemplate';
export const REVIEW_BOOSTER_GET_EXPEDITOR_NAME = 'reviewBoosterGetExpeditorName';
export const REVIEW_BOOSTER_GET_REVIEW_URL = 'reviewBoosterGetReviewUrl';

// SSO
export const SSO_INFOS = 'ssoInfos';

// Orgs
export const ORGANIZATIONS = 'organizations';
export const ORG_LANGUAGES = 'orgLanguages';

// Messaging
export const HOMEPAGE_MESSAGING_STATS = 'HOMEPAGE_MESSAGING_STATS';
export const MESSAGING_BUSINESS_USERS = 'MESSAGING_BUSINESS_USERS';
export const MESSAGING_CONVERSATION_STATS = 'MESSAGING_CONVERSATION_STATS';
export const MESSAGING_INFOS = 'MESSAGING_INFOS';
export const MESSAGING_SETTINGS = 'MESSAGING_SETTINGS';
export const MESSAGING_SETTINGS_STATS = 'MESSAGING_SETTINGS_STATS';
export const MESSAGING_MESSAGES = 'MESSAGING_MESSAGES';
export const MESSAGING_CONVERSATION = 'MESSAGING_CONVERSATION';
export const MESSAGING_CONVERSATIONS = 'MESSAGING_CONVERSATIONS';
export const MESSAGING_WIDGET_SETUP = 'MESSAGING_WIDGET_SETUP';

// Message Reply Template
export const MESSAGING_REPLY_TEMPLATES = 'MESSAGING_REPLY_TEMPLATES';
export const MESSAGE_REPLY_TEMPLATE_CREATE = 'MESSAGE_REPLY_TEMPLATE_CREATE';
export const MESSAGE_REPLY_TEMPLATE_DELETE = 'MESSAGE_REPLY_TEMPLATE_DELETE';
export const MESSAGE_REPLY_TEMPLATE_UPDATE = 'MESSAGE_REPLY_TEMPLATE_UPDATE';

// Conversation Starters
export const CONVERSATION_STARTERS = 'CONVERSATION_STARTERS';
export const CONVERSATION_STARTERS_ITEMS = 'CONVERSATION_STARTERS_ITEMS';

// Chatbots
export const CHATBOTS = 'CHATBOTS';
export const CHATBOT = 'CHATBOT';
export const CHATBOT_TONE_OF_VOICES = 'CHATBOTS_TONE_OF_VOICES';
export const CONVERSATION_ROUTER = 'CONVERSATION_ROUTER';

// Tab business edition
export const IS_FOOD_MENUS_AVAILABLE = 'is_food_menus_available_query';
export const GET_SERVICES = 'getServicesQuery';

export const POSTS = 'posts';

export const GOOGLE_VERIFICATION_OPTIONS = 'google_verification_options';
export const GOOGLE_CONNEXIONS_ERRORS_COUNT = 'google_connexions_errors_count';

export const INVOICE = 'invoice';

export const WHATSAPP = 'whatsapp';
export const WHATSAPP_ACCOUNT_COUNT = 'whatsapp_accounts_count';
export const WHATSAPP_NUMBER_ALLOWED = 'whatsapp_number_allowed';
export const WHATSAPP_PHONE_NUMBER = 'whatsapp_phone_number';

export const API_KEYS_LIST = 'api-keys-list';

export const API_KEY_VALUE = 'api-key-value';

// Analytics
export const COMPETITIVE_OVERVIEW = 'COMPETITIVE_BENCHMARK_OVERVIEW';
export const COMPETITIVE_BENCHMARK_KEYWORDS = 'COMPETITIVE_BENCHMARK_KEYWORDS';
export const COMPETITIVE_BENCHMARK_LOCAL_RANKINGS = 'COMPETITIVE_BENCHMARK_LOCAL_RANKINGS';
export const COMPETITIVE_BENCHMARK_GLOBAL_RANKINGS = 'COMPETITIVE_BENCHMARK_GLOBAL_RANKINGS';
