export enum MetricsAttributesResponse {
    BUSINESS_IMPRESSIONS_DESKTOP_MAPS = 'business_impressions_desktop_maps',
    BUSINESS_IMPRESSIONS_DESKTOP_SEARCH = 'business_impressions_desktop_search',
    BUSINESS_IMPRESSIONS_MOBILE_MAPS = 'business_impressions_mobile_maps',
    BUSINESS_IMPRESSIONS_MOBILE_SEARCH = 'business_impressions_mobile_search',
    BUSINESS_CONVERSATIONS = 'business_conversations',
    BUSINESS_DIRECTION_REQUESTS = 'business_direction_requests',
    CALL_CLICKS = 'call_clicks',
    WEBSITE_CLICKS = 'website_clicks',
    BUSINESS_BOOKINGS = 'business_bookings',
    BUSINESS_FOOD_ORDERS = 'business_food_orders',
    COUNT = 'count',
    THRESHOLD = 'threshold',
    DEPRECATED_WEBSITE_CLICKS = 'actions_website',
    DEPRECATED_CALL_CLICKS = 'actions_phone',
    DEPRECATED_DIRECTION_REQUEST = 'actions_driving_directions',
}
