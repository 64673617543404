import styled from '@emotion/styled';
import { Icon, MenuList, Stack, Text } from '@partoohub/ui';

export const Container = styled.div`
    position: relative;
    margin: auto;
`;

export const SearchMenuContainer = styled.div<{
    maxHeight: number;
}>`
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: ${({ theme }) => theme.colors.theme.initial};
    gap: 4px;
    width: 332px;
    ${({ maxHeight }) => `max-height: ${maxHeight}px;`}
    top: 40px;
    right: 0px;
    border-radius: 8px;
    box-shadow: 0px 5px 10px rgba(20, 37, 66, 0.16);
    z-index: 1;
`;

export const TextFieldContainer = styled.div`
    padding: 8px 8px 4px 8px;
`;

export const OverridenMenuList = styled(MenuList)`
    position: unset;
    box-shadow: none;
    padding: 0px 8px 8px 8px;

    label {
        margin-bottom: 0px;
    }
    > div {
        max-width: 100%;
    }
    span {
        width: 100%;
    }
`;

export const OverridenMenuListText = styled(Text)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const MeLabelContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 248px;
    gap: 4px;
`;

export const AvatarContainer = styled.div`
    width: 24px;
`;

export const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    max-width: 300px;
    line-height: normal;
`;

export const OverridenText = styled(Text)`
    text-overflow: ellipsis;
    overflow: hidden;
`;

export const IconWrapper = styled(Icon)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BackButton = styled.div`
    display: flex;
    padding: 8px 8px 0px 8px;
    align-items: flex-start;
    cursor: pointer;
    gap: 8px;
    align-self: stretch;
`;

export const BusinessMenuChoiceContainer = styled(Stack)`
    width: 100%;
`;

export const IconNameContainer = styled(Stack)`
    min-width: 0;
`;

export const TextContainer = styled(Text)`
    text-overflow: ellipsis;
    overflow: auto;
`;

export const BusinessAvatar = styled.div`
    flex-shrink: 0;
`;

export const TextWrapper = styled.div`
    min-width: 0;
`;

export const CenteredText = styled(Text)`
    text-align: center;
`;
