// Not the most precise typing... this could be improved
// Represents a review that came through websockets
export type UnformattedObject = {
    id: string;
    [key: string]: any;
};
export type WebsocketObject = {
    type: string;
    data: UnformattedObject;
};
export type RealtimeUpdatesState = {
    reviews: Array<WebsocketObject>;
};
