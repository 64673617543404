import { useHasReviewAnalyticsPage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewAnalyticsPage';
import { SmallStatProps } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/DefaultStatComponent';
import { StatReviews } from 'app/pages/Homepage/sections/HomepageStats/stats/StatReviews';
import { Item } from 'app/pages/Homepage/utils/typing';

export const useStatReviewsItem = (): Item<SmallStatProps> => {
    const isAvailable = !!useHasReviewAnalyticsPage();

    return {
        order: 2,
        isAvailable,
        component: StatReviews,
        key: 'stat-reviews',
    };
};
