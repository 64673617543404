import * as constants from './constants';
import * as types from './types';

export const updateReviewStatsAction = (
    treated: number,
    notTreated: number,
): types.UpdateReviewStatsType => ({
    type: constants.UPDATE_REVIEW_STATS,
    treated,
    notTreated,
});

export const addReviewStatsAction = (
    count: number,
    treated: number,
    notTreated: number,
): types.AddReviewStatsType => ({
    type: constants.ADD_REVIEW_STATS,
    count,
    treated,
    notTreated,
});
