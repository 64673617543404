import { ShortcutSendInvite } from 'app/pages/Homepage/sections/HomepageShortcuts/shortcuts/ShortcutSendInvite';
import { Item } from 'app/pages/Homepage/utils/typing';

import { useHomepageCanSendInvite } from './useHomepageCanSendInvite';

export const useShortcutSendInviteItem = (): Item => {
    const isAvailable = useHomepageCanSendInvite();

    return {
        order: 2,
        isAvailable,
        component: ShortcutSendInvite,
        key: 'shortcut-send-invite',
    };
};
