import { useMutation, useQueryClient } from 'react-query';

import { MessagingSetting } from 'app/api/types/business_message_settings';
import api from 'app/api/v2/api_calls/camel';
import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { MESSAGING_SETTINGS } from 'app/common/data/queryKeysConstants';

export default function useMessageSettingsConversationStarterIdUpdate(
    messageSetting: MessagingSetting | null,
) {
    const queryClient = useQueryClient();
    return useMutation(
        (conversationStarterId: string) => {
            if (messageSetting) {
                messageSetting.conversationStarterId = conversationStarterId;
                // there is a lot going on this field, I put this to have something that work but not good at all
                messageSetting.usesBusinessHours = !messageSetting.usesBusinessHours;
                return api.messagingSettings.update(messageSetting.id, messageSetting, true);
            }
        },
        {
            onSuccess: () => queryClient.invalidateQueries(invalidatesAny(MESSAGING_SETTINGS)),
        },
    );
}
