import { SegmentedProgressBar, Stack, Text } from '@partoohub/ui';

import { Trans } from 'react-i18next';

import { BUSINESS_MANAGER } from 'app/common/data/roles';
import useMe from 'app/common/hooks/queries/useMeUncamel';

import { BarContainer } from './BusinessDistributionBar.styled';
import { useBusinessDistributionSegments } from './useBusinessDistributionSegments';
import { useGraphSelection } from '../../../../../hooks/useGraphSelection';

export const BusinessDistributionBar = () => {
    const [graph] = useGraphSelection();
    const { data: me } = useMe();
    const segments = useBusinessDistributionSegments();
    const businessCount = Object.values(segments).reduce((acc, segment) => acc + segment.value, 0);
    const isBM = me?.role === BUSINESS_MANAGER;
    const showBusinessDistribution = graph === 'average_rating' && !isBM && businessCount > 1;

    if (!showBusinessDistribution) return undefined;

    return (
        <Stack gap="0">
            <Text color="secondary">
                <Trans
                    i18nKey="review_analytics_businesses__count"
                    values={{ count: businessCount }}
                    components={{ b: <Text as="span" variant="heading2" /> }}
                />
            </Text>
            <BarContainer>
                <SegmentedProgressBar segments={segments} />
            </BarContainer>
        </Stack>
    );
};
