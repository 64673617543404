export type SecondLevelComment = {
    id: string;
    created: number;
    date: number;
    authorName: string;
    content: string;
    canEdit: boolean;
    notFetched: boolean;
    parentId: number;
    updateDate: number | null;
    isReplySuggestion: boolean;
};
export type FirstLevelComment = SecondLevelComment & {
    replies: Array<SecondLevelComment>;
};
export type Comments = Record<string, Array<FirstLevelComment>>;
