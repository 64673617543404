import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

export const StyledGraphSection = styled(Stack)`
    padding: 24px;
    background-color: ${({ theme }) => theme.colors.theme.background};
    border-radius: 8px;
`;

export const StyledGraphContainer = styled.div`
    height: 400px;
`;
