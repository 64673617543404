import { useCallback } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { useHasCompetitiveBenchmarkPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasCompetitiveBenchmarkPage';
import { useHasPresenceAnalyticsPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasPresenceAnalyticsPage';
import { useHasReviewAnalyticsPage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewAnalyticsPage';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { CompetitiveBenchmarkPage } from 'app/pages/analytics/CompetitiveBenchmark';
import { useHasReviewAnalyticsV2 } from 'app/pages/analytics/hooks';
import { ReviewAnalyticsPage } from 'app/pages/analytics/ReviewAnalytics';
import { ReviewAnalyticsV2Page } from 'app/pages/analytics/ReviewAnalyticsV2';
import { VisibilityAnalyticsV2Page } from 'app/pages/analytics/VisibilityAnalyticsV2';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import {
    ANALYTICS_REVIEW_PATH,
    ANALYTICS_VISIBILITY_PATH,
    COMPETITIVE_BENCHMARK_PATH_TOKEN,
    NOT_FOUND_PATH,
    REVIEWS_PATH_TOKEN,
    VISIBILITY_PATH_TOKEN,
} from 'app/routing/routeIds';

const AnalyticsRoutes = () => {
    const { data: me } = useMe();
    const meRole = me ? me.role : null;

    const hasReviewAnalyticsV2 = useHasReviewAnalyticsV2();

    const hasPresenceAnalyticsPage = !!useHasPresenceAnalyticsPage();
    const hasReviewAnalyticsPage = !!useHasReviewAnalyticsPage();
    const hasCompetitiveBenchmarkPage = useHasCompetitiveBenchmarkPage();

    const getDefaultAnalyticsPath = useCallback(() => {
        if (hasPresenceAnalyticsPage) {
            return ANALYTICS_VISIBILITY_PATH;
        }
        if (hasReviewAnalyticsPage) {
            return ANALYTICS_REVIEW_PATH;
        }
        return ANALYTICS_VISIBILITY_PATH;
    }, []);

    const routes = [
        {
            index: true,
            element: <Navigate replace to={getDefaultAnalyticsPath()} />,
        },
        {
            path: VISIBILITY_PATH_TOKEN,
            element: (
                <ProtectedRoute userRole={meRole}>
                    <VisibilityAnalyticsV2Page />
                </ProtectedRoute>
            ),
        },
        {
            path: REVIEWS_PATH_TOKEN,
            element: (
                <ProtectedRoute userRole={meRole}>
                    <ReviewAnalyticsPage meRole={meRole} />
                </ProtectedRoute>
            ),
        },
        ...(hasCompetitiveBenchmarkPage
            ? [
                  {
                      path: COMPETITIVE_BENCHMARK_PATH_TOKEN,
                      element: <CompetitiveBenchmarkPage />,
                  },
              ]
            : []),
        ...(hasReviewAnalyticsV2
            ? [
                  {
                      path: `${REVIEWS_PATH_TOKEN}/v2`,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <ReviewAnalyticsV2Page />
                          </ProtectedRoute>
                      ),
                  },
              ]
            : []),
        {
            path: '*',
            element: <Navigate replace to={NOT_FOUND_PATH} />,
        },
    ];

    return useRoutes(routes);
};

export default AnalyticsRoutes;
