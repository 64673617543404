import { ORG_ADMIN } from 'app/common/data/roles';
import { useHasMessagePage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasMessagePage';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useIsConversationStartersPageEnabled = (): boolean => {
    const { data: me } = useMe();
    const hasMessagePage = useHasMessagePage();

    if (me === undefined) {
        return false;
    }

    if (!hasMessagePage || me?.role !== ORG_ADMIN) {
        return false;
    }

    return true;
};
