import { Banner } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useGetCommentsNotFetchedCount } from 'app/reviewManagement/reviewList/hooks/useGetCommentsNotFetchedCount';

export const RepliesNotFetchedBanner = () => {
    const { t } = useTranslation();
    const count = useGetCommentsNotFetchedCount();

    return count ? (
        <Banner
            dataTrackId="replies-not-fetched-banner"
            variant="danger"
            hideAction={true}
            // todo display the CTA when the feature is done
            //actionType={'confirm'}
            //confirmText={t('send_them_back')}
        >
            {t('replies_not_fetched_banner__count', { count: count })}
        </Banner>
    ) : null;
};
