import { ReviewCountByType, ReviewObject } from '../dataTypes';
import { ReviewFiltersState } from '../filters';
import { ReviewsStatsState } from '../stats';

export type ReviewsState = {
    byId: Record<string, ReviewObject>;
    ids: Array<string>;
    hasMore: boolean;
    count: number | null;
    countByType: ReviewCountByType;
    stats: ReviewsStatsState;
    filters: ReviewFiltersState;
};
