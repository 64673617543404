import { useEffect } from 'react';

import { css } from '@emotion/react';
import { FontAwesomeIconsPartooUsed, Icon, IconPrefix, Text } from '@partoohub/ui';
import { isEqual } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { GroupSection } from 'app/api/types/groupsV2';
import { useBusinessModalCustomActions } from 'app/common/components/businessModal/hooks/useBusinessModalCustomActions';
import useBusinessModalFilters from 'app/common/components/businessModal/hooks/useBusinessModalFilters';
import { useAppDataContext } from 'app/common/contexts/appData';
import { BUSINESS_MANAGER } from 'app/common/data/roles';
import { ButtonWithMenu } from 'app/common/designSystem/components/molecules/ButtonWithMenu/ButtonWithMenu';
import useGroupsV2 from 'app/common/hooks/queries/useGroupsV2';

import { StyledSectionSelectButtonIcons } from './SectionSelect.styled';
import { useInternalRankingParameters } from '../hooks/useInternalRankingParameters';

const getSectionGroups = (section: GroupSection | undefined) => {
    if (!section) return { filter: [], groupIds: [] };

    const groupsWithBusinesses = section.groups.filter(group => group.business_count);

    return {
        filter: groupsWithBusinesses.map(group => ({
            id: [group.id.toString()],
            label: group.name,
        })),
        groupIds: groupsWithBusinesses.map(group => group.id),
    };
};

export const SectionSelect = () => {
    const { t } = useTranslation();
    const { setBusinessModalGroups } = useBusinessModalCustomActions();
    const businessModalFilters = useBusinessModalFilters();
    const { me } = useAppDataContext();

    const {
        query: { hasNextPage, fetchNextPage, isLoading: isGroupsLoading },
        sections,
    } = useGroupsV2();
    const sectionOptions = (sections ?? [])
        .filter(s => s.groups.reduce((acc, g) => acc + g.business_count, 0))
        .map(s => {
            return {
                label: s.name,
                value: `${s.id}`,
                name: s.name,
            };
        });
    const options = [{ label: t('businesses'), value: '' as const, name: '' }, ...sectionOptions];

    const {
        section: [selectedSection, setSection],
        resetParameters,
    } = useInternalRankingParameters();

    useEffect(() => {
        if (!isGroupsLoading) resetParameters({ section: options });
    }, [isGroupsLoading]);

    useEffect(() => {
        if (selectedSection) {
            setBusinessModalGroups(
                getSectionGroups(sections?.find(s => `${s.id}` === selectedSection)).filter,
            );
        } else {
            setBusinessModalGroups([]);
        }
    }, [selectedSection]);

    useEffect(() => {
        // If the user makes a change on the business modal after selecting a section, return to the list grouped by business
        if (
            selectedSection &&
            !isEqual(
                getSectionGroups(sections?.find(s => `${s.id}` === selectedSection)).groupIds.map(
                    groupId => `${groupId}`,
                ),
                businessModalFilters.groups?.split(','),
            )
        ) {
            setSection('');
        }
    }, [businessModalFilters]);

    if (isGroupsLoading || !sectionOptions.length || me.role === BUSINESS_MANAGER) return undefined;

    return (
        <ButtonWithMenu
            dataTrackId=""
            size="small"
            options={options}
            onMenuClick={setSection}
            icon={
                <StyledSectionSelectButtonIcons>
                    <Icon icon={[FontAwesomeIconsPartooUsed.faCaretUp, IconPrefix.SOLID]} />
                    <Icon icon={[FontAwesomeIconsPartooUsed.faCaretDown, IconPrefix.SOLID]} />
                </StyledSectionSelectButtonIcons>
            }
            iconPosition="right"
            variant="secondary"
            appearance="outlined"
            text={
                <>
                    <Text as="span" color="secondary" variant="bodyMBold">
                        {t('display_by')}
                        {' : '}
                    </Text>
                    <Text as="span" variant="bodyMBold">
                        {options.find(dim => dim.value === selectedSection)?.label ?? ''}
                    </Text>
                </>
            }
            cssMenu={css`
                top: 40px;
                right: 0px;
                left: unset;
            `}
            hasMore={hasNextPage}
            loadMore={fetchNextPage}
        />
    );
};
