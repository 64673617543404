import { BusinessCreationRequestData, BusinessReachEnum } from 'app/api/types/business';

export const getDefaultBrandPayload = (
    brandName: string,
    country: string,
): BusinessCreationRequestData => {
    return {
        name: brandName,
        reach: BusinessReachEnum.GLOBAL,
        country: country,
        categories: ['gcid:restaurant'],
        contacts: [],
        manual_lat: null,
        manual_long: null,
        opening_date: '',
    };
};
