export enum MetricsApiKeys {
    METRICS = 'metrics',
    DIMENSIONS = 'dimensions',
    ORDER_BY = 'order_by',
    BUSINESS_IN = 'business__in',
    BUSINESS_NOT_IN = 'business__notin',
    FILTER_DATE_GTE = 'filter_date__gte',
    FILTER_DATE_LTE = 'filter_date__lte',
    PER_PAGE = 'per_page',
}
