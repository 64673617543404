import React, { useEffect, useState } from 'react';

import { useMutation } from 'react-query';

import { useSelector } from 'react-redux';

import businessImportFormatter from 'app/api/formatters/request/businessImportFormatter';
import { AccountPartnerResourceName } from 'app/api/types/account';
import api from 'app/api/v2/api_calls';
import { usePartnerConnectionContext } from 'app/businessConnection/components/PartnerConnectionContext';
import { partnerToPartnerResourceName } from 'app/businessConnection/services/PartnerToAccountsMethods';
import StatefulButton, {
    ButtonStatus,
    DISABLED,
    ERROR,
    LOADING,
    SUCCESSFUL,
    TOSAVE,
} from 'app/common/components/buttons/StatefulButton';
import { BUSINESS_CONNECTION, CONNECTION_STATS } from 'app/common/data/queryKeysConstants';
import { businessByIdSelector } from 'app/common/reducers/newBusinesses';
import { businessImportSelector } from 'app/states/knowledge/reducers/businessConnection/connection';
import queryClient from 'app/states/queryClient';
import { AppState } from 'app/states/reducers';

const getLabel = (
    status: ButtonStatus,
    partnerResourceName: AccountPartnerResourceName,
    hasPM: boolean,
) => {
    switch (status) {
        case LOADING:
            return `connection_to_${partnerResourceName}_location_in_progress`;

        case SUCCESSFUL:
            return `connection_to_${partnerResourceName}_location_successful`;

        case TOSAVE:
            return hasPM
                ? `connection_to_${partnerResourceName}_location_validate_location_and_push`
                : `connection_to_${partnerResourceName}_location_validate_location`;

        default:
            return `connection_to_${partnerResourceName}_location_select_location`;
    }
};

export const ConnectButton = () => {
    const {
        connectedLocationId,
        selectedLocationId,
        setSelectedLocationId,
        setConnectedLocationId,
        owningBusinessId,
        partnerName,
        setShowConnectionModal,
        setOwningBusinessId,
    } = usePartnerConnectionContext();

    const businessImport = useSelector((state: AppState) =>
        businessImportSelector(state.businessConnection.page),
    );
    const business = useSelector((state: AppState) =>
        businessByIdSelector(state.businesses, owningBusinessId),
    );

    const [label, setLabel] = useState<string>('');
    const [status, setStatus] = useState<ButtonStatus>(DISABLED);
    const [preventClick, setPreventClick] = useState<boolean>(false);
    const [saveIcon, setSaveIcon] = useState<string>('');

    const hasPM = business ? business.subscriptions.presence_management.active : false;

    const importGMBInfos = useMutation(() => {
        const businessImportFormattedData = businessImportFormatter(businessImport, false, false);

        return api.business.importGMBInfos(owningBusinessId, businessImportFormattedData);
    });

    const connectBusiness = useMutation(
        () =>
            api.businessModule.publisherConnection.connectBusinessToLocation(
                owningBusinessId,
                selectedLocationId || connectedLocationId,
                partnerToPartnerResourceName[partnerName],
                {
                    is_bulk_import: false,
                    is_quick_connect: false,
                },
            ),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([BUSINESS_CONNECTION]);
                queryClient.invalidateQueries([CONNECTION_STATS]);
                if (partnerName === 'google') {
                    importGMBInfos.mutate();
                }
                setStatus(SUCCESSFUL);
                setPreventClick(true);
                setShowConnectionModal(false);
                setOwningBusinessId('');
                setSelectedLocationId('');
                setConnectedLocationId('');
            },
            onError: () => {
                setStatus(ERROR);
                setPreventClick(true);
            },
        },
    );

    useEffect(() => {
        if (connectedLocationId) {
            if (
                Object.values(businessImport).includes(true) ||
                Object.values(businessImport.businessInfos).includes(true)
            ) {
                setStatus(TOSAVE);
                setSaveIcon('fa fa-check');
            } else {
                setStatus(DISABLED);
            }

            setLabel('confirm');
        } else if (selectedLocationId) {
            setStatus(TOSAVE);
        } else {
            setStatus(DISABLED);
        }
    }, [connectedLocationId, selectedLocationId, businessImport]);

    useEffect(() => {
        if (connectBusiness.isLoading) {
            setStatus(LOADING);
        }
    }, [connectBusiness]);

    return (
        <StatefulButton
            status={status}
            label={label || getLabel(status, partnerToPartnerResourceName[partnerName], hasPM)}
            onClick={() => connectBusiness.mutate()}
            preventClick={preventClick}
            saveIcon={saveIcon}
        />
    );
};
