import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useQuery } from 'react-query';

import { BusinessUser } from 'app/api/types/user';
import User from 'app/api/v2/api_calls/userApiCalls';
import { USERS_LIST } from 'app/common/data/queryKeysConstants';

type Users = { [id: string]: BusinessUser };

const MessagingUsersContext = React.createContext<MessagingUsersContextType>(null as any);

interface MessagingUsersContextType {
    loadedUsers: Users;
    setLoadedUsers: React.Dispatch<React.SetStateAction<Users>>;
    loadedAssignedUserIds: string[];
    setLoadedAssignedUserIds: React.Dispatch<React.SetStateAction<string[]>>;
}

interface MessagingUsersProviderProps {
    children: React.ReactNode;
}

export const MessagingUsersProvider = ({ children }: MessagingUsersProviderProps) => {
    const defaultLoadedUsers: Users = {};
    const [loadedAssignedUserIds, setLoadedAssignedUserIds] = useState<string[]>([]);
    const [loadedUsers, setLoadedUsers] = useState<Users>(defaultLoadedUsers);
    const usersToFetch = [...loadedAssignedUserIds].filter(userId => !(userId in loadedUsers));

    const { data: newUsers, isLoading: newUsersIsLoading } = useQuery(
        [USERS_LIST, { usersToFetch }],
        () => User.listUsers(usersToFetch.join(',')),
        { enabled: usersToFetch.length > 0 },
    );

    const value = useMemo(
        () => ({
            loadedUsers,
            setLoadedUsers,
            loadedAssignedUserIds,
            setLoadedAssignedUserIds,
        }),
        [loadedUsers, setLoadedUsers, loadedAssignedUserIds, setLoadedAssignedUserIds],
    );

    useEffect(() => {
        if (newUsers && newUsers.length > 0 && !newUsersIsLoading) {
            const newLoadedUsers = newUsers.reduce(
                (acc: { str: BusinessUser }, user: BusinessUser) => ({
                    ...acc,
                    [user.id]: user,
                }),
                loadedUsers,
            );
            setLoadedUsers(newLoadedUsers);
        }
    }, [loadedAssignedUserIds, newUsers]);

    return (
        <MessagingUsersContext.Provider value={value}>{children}</MessagingUsersContext.Provider>
    );
};

export const useMessagingUsers = () => useContext(MessagingUsersContext);
