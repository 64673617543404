import { useMemo } from 'react';

import {
    addDays,
    addMonths,
    addYears,
    differenceInDays,
    differenceInMonths,
    differenceInYears,
    format,
    isEqual,
    parse,
    startOfDay,
} from 'date-fns';
import { parseISO } from 'date-fns/parseISO';
import { isEmpty } from 'lodash-es';
import { UseQueryOptions, useQuery } from 'react-query';

import {
    PaginatedMetricsResponse,
    ReviewAnalyticsDimension,
    ReviewAnalyticsFiltersType,
    ReviewAnalyticsItemType,
    ReviewAnalyticsMetric,
} from 'app/api/types/review_analytics';
import api from 'app/api/v2/api_calls';
import { REVIEW_ANALYTICS_METRICS } from 'app/common/data/queryKeysConstants';

import { useDateDimension } from './useDateDimension';
import { useInternalRankingDimension } from './useInternalRankingDimension';
import { usePreviousPeriodFilters, useReviewAnalyticsFilters } from './useReviewAnalyticsFilters';

// Ranking table page size (i.e. number of items displayed at once)
export const TABLE_PAGE_SIZE = 10;

// We are loading 10 times more data than the PAGE_SIZE
const PAGE_SIZE_API_RATIO = 10;

export const getApiPage = (tablePage: number): number => {
    return Math.floor((tablePage - 1) / PAGE_SIZE_API_RATIO) + 1;
};

export const getDataForTablePage = (data: any[], tablePage: number): any[] => {
    return data.slice(
        ((tablePage - 1) * PAGE_SIZE_API_RATIO) % (TABLE_PAGE_SIZE * PAGE_SIZE_API_RATIO),
        TABLE_PAGE_SIZE * tablePage,
    );
};

const useMetrics = <T extends ReviewAnalyticsDimension, U extends readonly ReviewAnalyticsMetric[]>(
    dimensions: T,
    metrics: U,
    filters: ReviewAnalyticsFiltersType,
    options?: UseQueryOptions<PaginatedMetricsResponse<T, U>>,
) => {
    const { data } = useQuery<PaginatedMetricsResponse<T, U>>(
        [REVIEW_ANALYTICS_METRICS, dimensions, metrics, filters],
        () =>
            api.reviewAnalytics.getMetrics<T, U>(dimensions, metrics, filters).catch(() => {
                return null;
            }),
        {
            keepPreviousData: true,
            ...options,
        },
    );
    return data;
};

export const useReviewTotalMetrics = () => {
    const filters = useReviewAnalyticsFilters();
    const dimensions = null;
    const metrics = ['average_rating', 'rating_distribution', 'reply_time'] as const;

    return useMetrics(dimensions, metrics, filters);
};

export const useReviewTagMetrics = () => {
    const filters = useReviewAnalyticsFilters();
    const dimensions = 'tag';
    const metrics = ['average_rating', 'rating_distribution'] as const;

    return useMetrics(dimensions, metrics, filters);
};

const METRIC_BY_DATE_ZERO = {
    rating_distribution: {
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
    },
    reply_means: {
        manual: 0,
        reply_template: 0,
        auto_reply: 0,
        ai_suggestion: 0,
    },
    reply_time: { not_replied: 0, fast: 0, slow: 0 },
    average_rating: null,
};

export const useReviewMetricsByDate = () => {
    const filters = useReviewAnalyticsFilters();
    const previousMetricsFilters = usePreviousPeriodFilters();

    const dimensions = useDateDimension();
    const metrics = ['rating_distribution', 'reply_time', 'reply_means', 'average_rating'] as const;

    const metricsResult = useMetrics(dimensions, metrics, filters);
    const previousMetricsResult = useMetrics(dimensions, metrics, previousMetricsFilters, {
        keepPreviousData: false,
        enabled: !isEmpty(previousMetricsFilters),
    });

    const formatMetricsData = (
        data: NonNullable<typeof metricsResult>['data'],
        dataFilters: ReviewAnalyticsFiltersType,
    ) => {
        /* Format data fetched from backend:
         **  - Format dates
         **  - Add zeros on dates that have no data
         */
        if (!data.length) return data;

        const dimensionConfig = {
            day: {
                inputFormat: 'yyyy-MM-dd',
                outputFormat: 'P',
                difference: differenceInDays,
                add: addDays,
            },
            month: {
                inputFormat: 'yyyy-MM',
                outputFormat: 'MMM yyyy',
                difference: differenceInMonths,
                add: addMonths,
            },
            year: {
                inputFormat: 'yyyy',
                outputFormat: 'yyyy',
                difference: differenceInYears,
                add: addYears,
            },
        }[dimensions];

        const startDate = dataFilters.update_date__gte
            ? startOfDay(parseISO(dataFilters.update_date__gte))
            : parse(data[0]!.dimension, dimensionConfig.inputFormat, new Date());
        const endDate = dataFilters.update_date__lte
            ? startOfDay(parseISO(dataFilters.update_date__lte))
            : new Date();

        const dataPoints = dimensionConfig.difference(endDate, startDate) + 1;

        const filledData: typeof data = Array(dataPoints)
            .fill(null)
            .map((_, index) => {
                const dataPointDate = dimensionConfig.add(startDate, index);
                return {
                    dimension: format(dataPointDate, dimensionConfig.outputFormat),
                    dimension_name: undefined,
                    metrics:
                        data.find(d =>
                            isEqual(
                                dimensionConfig.add(startDate, index),
                                parse(d.dimension, dimensionConfig.inputFormat, new Date()),
                            ),
                        )?.metrics ?? METRIC_BY_DATE_ZERO,
                };
            });
        return filledData;
    };

    const formattedData = useMemo(() => {
        if (!metricsResult) return [];
        return formatMetricsData(metricsResult.data, filters);
    }, [metricsResult]);

    const formattedPreviousData = useMemo(() => {
        if (!previousMetricsResult) return undefined;
        return formatMetricsData(previousMetricsResult.data, previousMetricsFilters);
    }, [previousMetricsResult]);

    return metricsResult
        ? {
              ...metricsResult,
              data: formattedData,
              previousData: formattedPreviousData,
          }
        : undefined;
};

export const useEReputationInternalRanking = (
    dimensions: 'group' | 'business',
    page: number,
    orderBy?: string,
) => {
    const filters = {
        ...useReviewAnalyticsFilters(),
        page,
        per_page: TABLE_PAGE_SIZE * PAGE_SIZE_API_RATIO,
        order_by: orderBy,
    };
    const metrics = ['average_rating', 'rating_distribution', 'rank'] as const;

    return useMetrics(dimensions, metrics, filters);
};

export const useReplyMeansInternalRanking = (page: number, orderBy?: string) => {
    const filters = {
        ...useReviewAnalyticsFilters(),
        page,
        per_page: TABLE_PAGE_SIZE * PAGE_SIZE_API_RATIO,
        order_by: orderBy,
    };
    const dimensions = useInternalRankingDimension();
    const metrics = dimensions === 'business' ? (['reply_means', 'rank'] as const) : [];

    return useMetrics(dimensions, metrics, filters);
};

export const useReplyTimeInternalRanking = (page: number, orderBy?: string) => {
    const filters = {
        ...useReviewAnalyticsFilters(),
        page,
        per_page: TABLE_PAGE_SIZE * PAGE_SIZE_API_RATIO,
        order_by: orderBy,
    };
    const dimensions = useInternalRankingDimension();
    const metrics =
        dimensions === 'business' ? (['reply_time', 'rank'] as const) : (['reply_time'] as const);

    return useMetrics(dimensions, metrics, filters);
};

export const useAverageRatingDistribution = () => {
    const filters = useReviewAnalyticsFilters();
    const dimensions = null;
    const metrics = ['average_rating_distribution'] as const;

    return useMetrics(dimensions, metrics, filters);
};
