import { V2FormattedLocationData, V2LocationData } from 'app/api/types/account';
import { CategoryChoice, Choice } from 'app/api/types/user';
import { FilterMode } from 'app/common/components/businessModalDeprecated/reducers/filterByBusiness';
import { CLOSED, CLOSED_TEMPORARILY, OPEN, OPENING_SOON } from 'app/common/data/businessStatuses';
import { AppProduct } from 'app/common/data/productIds';

import { News, PriceRange, SuccessResponse } from './index';

export type CompletionRateResponse = SuccessResponse & {
    id: string;
    completion_rate: number;
};

export type InfosFormData = {
    name: string;
    addressFull: string;
    addressComplement: string;
    zipCode: string;
    country: Choice;
    city: Choice | null;
    lat: number | null;
    long: number | null;
    manualLat: number | null;
    manualLong: number | null;
    categories: Array<CategoryChoice>;
};

// Contact
type ContactRequestData = {
    name?: string;
    email?: string;
    phone_numbers?: Array<string>;
    fax?: string;
};

export type Contact = {
    email: string;
    phone_numbers: Array<string>;
    name: string;
    fax: string;
    partoo_phone_number: string;
};

// Photo
export type PhotosData = {
    primary: string | null;
    LOGO: string | null;
    EXTERIOR: Array<string>;
    INTERIOR: Array<string>;
    PRODUCT: Array<string>;
    AT_WORK: Array<string>;
    FOOD_AND_DRINK: Array<string>;
    MENU: Array<string>;
    COMMON_AREAS: Array<string>;
    ROOMS: Array<string>;
    TEAM: Array<string>;
    secondary: Array<string>;
    preferred_photo: string;
};

// Hours
export type OpenHoursData = {
    monday: Array<string>;
    tuesday: Array<string>;
    wednesday: Array<string>;
    thursday: Array<string>;
    friday: Array<string>;
    saturday: Array<string>;
    sunday: Array<string>;
};

export type SpecificClosePeriod = {
    startsAt: string;
    endsAt: string;
};

export type SpecificOpenPeriod = {
    startsAt: string;
    endsAt: string;
    openHours: Array<string>;
};

export type SpecificHoursData = {
    close: Array<SpecificClosePeriod>;
    open: Array<SpecificOpenPeriod>;
};

export type MergedSpecificPeriod = {
    startsAt: string;
    endsAt: string;
    openHours: Array<string>;
    open: boolean;
};

export type FormattedSpecificClosePeriod = {
    starts_at: string;
    ends_at: string;
};

export type FormattedSpecificOpenPeriod = {
    starts_at: string;
    ends_at: string;
    open_hours: Array<string>;
};

export type FormattedSpecificHoursData = {
    close: Array<FormattedSpecificClosePeriod>;
    open: Array<FormattedSpecificOpenPeriod>;
};

export type SpecificHourFormPeriod = {
    starts_at: string;
    ends_at: string;
    open: boolean;
    open_hours: Array<string>;
};

export type SpecificHourFormData = Array<SpecificHourFormPeriod>;

// Custom Fields
export type CustomFieldsObject = {
    id: number;
    type: string;
    name: string;
    value: any;
    order: number;
    section_id: number;
};

export type CustomFieldsCreationPayload = {
    id?: number;
    type: string;
    order?: number;
    name: string;
    min?: number | null;
    max?: number | null;
    max_len?: number | null;
    possible_values?: Array<string>;
    possible_images_urls?: Array<string>;
    possible_images_labels?: Array<string | null>;
    section_id?: number;
};

export type CustomFieldsEditPayload = {
    id: number;
    type: string;
    order: number;
    name: string;
    forced_update?: boolean;
    min?: number | null;
    max?: number | null;
    max_len?: number | null;
    created_values?: Array<string | Record<string, any>>;
    deleted_values?: Array<string>;
    renamed_values?: Array<Record<string, any>>;
    edited_values?: Array<Record<string, any>>;
    section_id?: number;
};

export type CustomFieldsDeletePayload = {
    forced_update?: boolean;
};

type CustomFieldsChangeOrderSection = {
    id: number;
    order: number;
};

type CustomFieldsChangeOrderField = CustomFieldsChangeOrderSection & {
    section_id: number | null;
};

export type CustomFieldsChangeOrderPayload = {
    orders: Array<CustomFieldsChangeOrderField>;
    section_orders: Array<CustomFieldsChangeOrderSection>;
};

export type CustomFieldsCreationData = {
    custom_field_id: number;
};

// Custom Fields Sections
export type CustomFieldsSectionData = {
    id: number;
    name: string;
    order: number;
};
export type CustomFieldsSectionsGetData = {
    sections: Array<CustomFieldsSectionData>;
};
export type CustomFieldsSectionCreationPayload = {
    name: string;
};
export type CustomFieldsSectionsCreateData = {
    section_id: number;
};

// Business fields
export type BusinessFieldType = {
    name: string;
    enabled: boolean;
    enabled_for_gm: boolean;
    enabled_for_bm: boolean;
    id?: number;
    type?: string;
    order?: number;
    min?: number | null;
    max?: number | null;
    max_len?: number | null;
    possible_values?: Array<string>;
    possible_images_urls?: Array<string>;
    possible_images_labels?: Array<string | null>;
    section_id?: number | null;
    max_lengths?: Array<number>;
    text_fields?: Array<string>;
};

export type BusinessFieldArrayType = Array<BusinessFieldType>;

export type BusinessSectionType = {
    name: string;
    fields: BusinessFieldArrayType;
};

export type BusinessFieldsType = Array<BusinessSectionType>;

// Business
export type CreationSuccessResponses = {
    business_id: string;
};

export type OnboardingCreationSuccessResponses = {
    business_ids: Array<string>;
};

type ContactErrorJSON = {
    phone_numbers?: Array<string>;
};

export type ReceivedBusinessCreationErrorJSON = {
    address_full?: string;
    name?: string;
    zipcode?: string;
    city?: string;
    country?: string;
    categories?: string;
    contacts?: Array<ContactErrorJSON>;
    code?: string;
    opening_date?: string;
};

export type ReceivedBusinessCreationErrors = {
    json: ReceivedBusinessCreationErrorJSON;
};

export type CreationFailureResponse = {
    errors: ReceivedBusinessCreationErrors;
};

export type BusinessCreationRequestData = {
    name: string;
    reach: string;
    address_full?: string;
    address2?: string;
    city?: string;
    country: string;
    zipcode?: string;
    categories: Array<string>;
    contacts: Array<any>;
    code?: string;
    manual_lat: number | null;
    manual_long: number | null;
    opening_date: string;
};

export type BusinessSuccessResponse = {
    status: string;
    business_id: string;
};

export type ReceivedBusinessData = {
    available_partners: Array<string>;
    subscription_products: {
        presence_management: {
            expiration_date?: number;
        };
        review_management: {
            expiration_date?: number;
        };
    };
    client_statuses: {
        facebook: string;
        google_my_business: string;
    };
    id: string;
    urls: Record<string, string>;
    name: string;
    address_full: string;
    zipcode: string;
    city: string;
    country: string;
    phone_number: string;
};

export enum BusinessReachEnum {
    LOCAL = 'LOCAL',
    GLOBAL = 'GLOBAL',
}

export type V2BusinessData = {
    id: string;
    code: string;
    opening_date: string;
    status: string;
    org_id: number;
    group_id: number | null;
    groups?: Array<number>; // USE_NEW_GROUPS
    name: string;
    address2: string;
    city: string;
    zipcode: string;
    siret: string;
    country: string;
    default_lang: string;
    description_short: string | null;
    description_long: string | null;
    website_url: string | null;
    facebook_url: string | null;
    twitter_url: string | null;
    created: number;
    modified: number;
    lat: number | null;
    long: number | null;
    manual_lat: number | null;
    manual_long: number | null;
    address_full: string;
    contacts: Array<Contact>;
    open_hours: OpenHoursData;
    specific_hours: FormattedSpecificHoursData;
    time_slot_reference: Array<string>;
    photos: PhotosData;
    price_range: PriceRange;
    news: Array<News>;
    logo_url: string;
    categories: Array<string>;
    promos: Array<Record<string, any>>;
    national_identification_number: string;
    subscriptions: {
        review_booster: {
            active: boolean;
        };
        presence_management: {
            active: boolean;
        };
        review_management: {
            active: boolean;
        };
        messages: {
            active: boolean;
        };
    };
    google_location_id: string | null;
    gmb_location: V2LocationData | null;
    google_place_id?: string | null;
    facebook_page_id: string | null;
    custom_fields?: Array<CustomFieldsObject>;
    completion_rate: number | null;
    time_of_last_photo_update: string;
    media_mapping?: Record<string, string>;
    reach: BusinessReachEnum;
};

export type V2BusinessStatus =
    | typeof OPEN
    | typeof CLOSED
    | typeof CLOSED_TEMPORARILY
    | typeof OPENING_SOON
    | string; // Comma separated combination of statuses

export type BusinessSubscriptions = Record<AppProduct, { active: boolean }>;

export type V2FormattedBusinessData = {
    id: string;
    code: string;
    status: string;
    orgId: number;
    groupId: number | null;
    name: string;
    addressFull: string;
    address2: string;
    city: string;
    zipcode: string;
    siret: string;
    country: string;
    defaultLang: string;
    descriptionShort: string | null;
    descriptionLong: string | null;
    websiteUrl: string | null;
    facebookUrl: string | null;
    twitterUrl: string | null;
    created: number;
    modified: number;
    lat: number;
    long: number;
    locale: string | null;
    manualLat: number | null;
    manualLong: number | null;
    contacts: Array<Contact>;
    openHours: OpenHoursData;
    specificHours: SpecificHoursData;
    timeSlotReference: Array<string>;
    photos: PhotosData;
    priceRange: PriceRange;
    news: Array<News>;
    logoUrl: string;
    categories: Array<string>;
    promos: Array<Record<string, any>>;
    nationalIdentificationNumber: string;
    subscriptions: BusinessSubscriptions;
    googleLocationId: string | null;
    googlePlaceId: string | null;
    facebookPageId: string | null;
    customFields: Array<CustomFieldsObject>;
    reach: BusinessReachEnum;
};

export type BusinessUpdateRequestData = {
    name?: string;
    country?: string;
    zipcode?: string;
    city?: string;
    address_full?: string;
    categories?: Array<string>;
    contacts?: Array<ContactRequestData>;
    code?: string;
    opening_date?: string;
    status?: V2BusinessStatus;
    address2?: string;
    lat?: number;
    long?: number;
    manual_lat?: number;
    manual_long?: number;
    national_identification_number?: string;
    description_short?: string;
    description_long?: string;
    website_url?: string;
    facebook_url?: string;
    twitter_url?: string;
    photos?: PhotosData;
    open_hours?: OpenHoursData;
    specific_hours?: SpecificHoursData;
    timeSlotReference?: Array<string>;
    promos?: Array<any>;
    news?: Array<any>;
    time_slot_reference?: Array<string>;
};

export type FetchGmbImagesReceivedData = {
    status: string;
    job_ids: Array<string>;
};

type SearchBusinessOrderByBaseValue =
    | 'id'
    | 'name'
    | 'code'
    | 'created'
    | 'modified'
    | 'country'
    | 'city'
    | 'completion_rate';

export type SearchBusinessOrderByValue =
    | `-${SearchBusinessOrderByBaseValue}`
    | SearchBusinessOrderByBaseValue;

export type SearchBusinessParams = {
    query?: string;
    business__in?: string | null;
    org_id?: number | null;
    org_id__in?: string | null;
    name?: string;
    has_promo?: 'True' | 'False';
    page?: number;
    per_page?: number;
    status?: V2BusinessStatus | null;
    country?: string | null;
    code?: string | null;
    reach?: string | null; // comma separated values of BusinessReachEnum
    city?: string | null;
    zipcode?: string | null;
    connected_to_gmb_location?: 'True' | 'False' | null;
    connected_to_facebook_location?: 'True' | 'False' | null;
    subscribed_to_rb?: 'True' | 'False' | null;
    subscribed_to_pm?: 'True' | 'False' | null;
    subscribed_to_rm?: 'True' | 'False' | null;
    subscribed_to_bm?: 'True' | 'False' | null;
    group_id?: number | null;
    group_id__in?: string | null;
    group_id__is_null?: boolean;
    groups?: string | null; // USE_NEW_GROUPS
    partoo_ui?: boolean;
    is_google_post_api_disabled?: boolean | null;
    can_modify_service_list?: boolean | null;
    can_have_food_menus?: boolean;
    completion_rate?: string;
    order_by?: SearchBusinessOrderByValue;
};

export type SubscriptionsStatsParams = {
    status?: V2BusinessStatus | null;
    query?: string;
    group_id?: number | null;
    group_id__in?: string | null;
    group_id__is_null?: boolean;
};

export type SearchBusinessesReceivedData = {
    businesses: Array<V2BusinessData>;
    count: number;
    max_page: number;
    page: number;
};

export type SearchBusinessesStatsData = {
    average_completion_rate: number | null;
    counts: {
        low: number;
        mid: number;
        high: number;
    };
};

export type GmbAutoMatchSuggestionsResponse = {
    count: number;
    auto_match_suggestions: Record<string, string>;
};

export type FacebookAutoMatchSuggestionsResponse = {
    results: [V2FormattedBusinessData, V2FormattedLocationData];
};

export type BusinessCreationErrorsObject = {
    name?: string;
    zipcode?: string;
    streetName?: string;
    country?: string;
    city?: string;
    categories?: string;
    phoneNumber?: string;
    addressFull?: string;
    code?: string;
    openingDate?: string;
};

export type FilteredBusinessData = {
    mode: FilterMode;
    includedIds: Array<string>;
    excludedIds: Array<string>;
    queries: Array<string>;
    cities: Array<string>;
    orgs: Array<string>;
    groupIdIn: Array<number>;
    groupIsNull: boolean | null;
    searchCount: number;
};

export type DuplicateBusinessSearchSuccessResponse = {
    status: string;
    business_ids?: Array<string>;
    org_id?: number;
};

type DuplicateBusinessSearchEmailError = {
    email: string;
};

export type DuplicateBusinessSearchFailureResponse = {
    errors: string | DuplicateBusinessSearchEmailError;
};

export type ConnectionsStatsReceivedData = {
    count: number;
    linked_to_gmb_count: number;
    linked_to_facebook_count: number;
    linked_to_tripadvisor_count: number;
};

export type BusinessInfosImportType = {
    name: boolean;
    code: boolean;
    categories: boolean;
    addressFull: boolean;
    coordinates: boolean;
    description: boolean;
    phone: boolean;
    website: boolean;
    openHours: boolean;
    moreHours: boolean;
    specificHours: boolean;
    openingDate: boolean;
    additionalUrls: boolean;
};

export type BusinessImportFieldsType = {
    businessInfos: BusinessInfosImportType;
    photos: boolean;
    attributes: boolean;
    services: boolean;
    foodMenu: boolean;
    googlePosts: boolean;
};

export type FormattedBusinessInfosImportType = {
    name: boolean;
    code: boolean;
    categories: boolean;
    address_full: boolean;
    coordinates: boolean;
    description: boolean;
    phone: boolean;
    website: boolean;
    open_hours: boolean;
    more_hours: boolean;
    specific_hours: boolean;
    opening_date: boolean;
    additional_urls: boolean;
};

export type FormattedBusinessImportFieldsType = {
    business_infos: FormattedBusinessInfosImportType;
    photos: boolean;
    attributes: boolean;
    services: boolean;
    google_posts: boolean;
    food_menu: boolean;
    is_bulk_import: boolean;
    is_quick_import: boolean;
};

export type FormattedBusinessConnectToLocationType = {
    is_bulk_import: boolean;
    is_quick_connect: boolean;
};

export type BusinessConnectionList = {
    business_id: string;
    status: V2BusinessStatus;
};

export type GooglePostStatusType = {
    hasGooglePost: boolean;
    displayGooglePost: boolean;
};

export type BusinessDisabledDiffusersGetDatatype = {
    diffusers: string[];
};

export type BusinessLocationAddress = {
    country: string;
    zipcode?: string;
    city: string;
    address2?: string;
    address_full: string;
};

export type BusinessLocation = {
    id: string;
    address?: BusinessLocationAddress;
};

export type BusinessGoogleConnectionsErrorsCountData = {
    partner_connections_errors_count?: number;
};

export interface BusinessConnectedChannels {
    sms: boolean;
    whatsapp: boolean;
    messenger: boolean;
}
