import { IllustrationCategoryEnum, IllustrationLaptopEnum } from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';

import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { MessagingEmptyPlaceholderContainer } from 'app/reviewManagement/messaging/MessagingEmptyPlaceholder.styled';
import { AssignFilterOption, StatusFilterOption, URLParams } from 'app/states/messaging';

export const MessagingEmptyPlaceholder = () => {
    const { t } = useTranslation();
    const [statusFilter] = useStateQueryParams<StatusFilterOption>(URLParams.STATUS);
    const [assignFilter] = useStateQueryParams<AssignFilterOption>(URLParams.ASSIGN);

    const defaults = {
        [StatusFilterOption.OPEN]: {
            title: 'messaging_placeholder_empty_inbox_open_all_title',
            subtitle: 'messaging_placeholder_empty_inbox_open_all_subtitle',
            illustrationName: IllustrationLaptopEnum.Happy,
        },
        [StatusFilterOption.CLOSED]: {
            title: 'messaging_placeholder_empty_inbox_closed_all_title',
            subtitle: 'messaging_placeholder_empty_inbox_closed_all_subtitle',
            illustrationName: IllustrationLaptopEnum.Monocle,
        },
    };
    const keys = {
        [StatusFilterOption.OPEN]: {
            [AssignFilterOption.ALL]: {
                title: 'messaging_placeholder_empty_inbox_open_all_title',
                subtitle: 'messaging_placeholder_empty_inbox_open_all_subtitle',
                illustrationName: IllustrationLaptopEnum.Happy,
            },
            [AssignFilterOption.ME]: {
                title: 'messaging_placeholder_empty_inbox_open_assigned_to_me_title',
                subtitle: 'messaging_placeholder_empty_inbox_open_assigned_to_me_subtitle',
                illustrationName: IllustrationLaptopEnum.VeryHappySuccess,
            },
            [AssignFilterOption.UNASSIGNED]: {
                title: 'messaging_placeholder_empty_inbox_open_unassigned_title',
                subtitle: 'messaging_placeholder_empty_inbox_open_unassigned_subtitle',
                illustrationName: IllustrationLaptopEnum.Glasses,
            },
        },
        [StatusFilterOption.CLOSED]: {
            [AssignFilterOption.ALL]: {
                title: 'messaging_placeholder_empty_inbox_closed_all_title',
                subtitle: 'messaging_placeholder_empty_inbox_closed_all_subtitle',
                illustrationName: IllustrationLaptopEnum.Monocle,
            },
            [AssignFilterOption.ME]: {
                title: 'messaging_placeholder_empty_inbox_closed_assigned_to_me_title',
                subtitle: 'messaging_placeholder_empty_inbox_closed_assigned_to_me_subtitle',
                illustrationName: IllustrationLaptopEnum.VeryHappy,
            },
            [AssignFilterOption.UNASSIGNED]: {
                title: 'messaging_placeholder_empty_inbox_closed_unassigned_title',
                subtitle: 'messaging_placeholder_empty_inbox_closed_unassigned_subtitle',
                illustrationName: IllustrationLaptopEnum.Wink,
            },
        },
    };
    const { title, subtitle, illustrationName } =
        keys[statusFilter][assignFilter] || defaults[statusFilter];

    const illustrationElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: illustrationName ?? IllustrationLaptopEnum.Sad,
    };

    return (
        <MessagingEmptyPlaceholderContainer>
            <NotFoundTemplate
                show
                title={t(title)}
                subtitle={t(subtitle)}
                imgSrc={illustrationElement}
                withButton={false}
            />
        </MessagingEmptyPlaceholderContainer>
    );
};
