import { InteractionTagType } from 'app/api/types/interactionTag';
import { ReviewObject } from 'app/api/types/review';
import { ReviewCountByType } from 'app/states/reviews';

import * as constants from './constants';

// ACTION FLOW TYPES

export type SearchReviewsFailureType = {
    type: typeof constants.SEARCH_REVIEWS_FAILURE;
    error: Record<string, any>;
};

export type SearchReviewsSuccessType = {
    type: typeof constants.SEARCH_REVIEWS_SUCCESS;
    byId: Record<string, ReviewObject>;
    ids: Array<string>;
    hasMore: boolean;
    count: number;
    countByType: ReviewCountByType;
};

export type SearchReviewsResetType = {
    type: typeof constants.SEARCH_REVIEWS_RESET;
};

export type UpdateReviewSuccessType = {
    type: typeof constants.UPDATE_REVIEW_SUCCESS;
    review: ReviewObject;
};

export type DeleteReviewType = {
    type: typeof constants.DELETE_REVIEW;
    review: ReviewObject;
};

export type UpdateReviewMarkAsTreatedType = {
    type: typeof constants.UPDATE_REVIEW_MARK_AS_TREATED;
    review: ReviewObject;
};

export type UpdateReviewMarkAsNotTreatedType = {
    type: typeof constants.UPDATE_REVIEW_MARK_AS_NOT_TREATED;
    review: ReviewObject;
};

export type UpdateReviewMarkAsDeletedType = {
    type: typeof constants.UPDATE_REVIEW_MARK_AS_DELETED;
    review: ReviewObject;
};

export type UpdateCountType = {
    type: typeof constants.UPDATE_COUNT;
    count: number;
};

export type UpdateTagOnReviewsType = {
    type: typeof constants.UPDATE_TAG_ON_REVIEWS;
    tag: InteractionTagType;
};

export type DeleteTagOnReviewsType = {
    type: typeof constants.DELETE_TAG_ON_REVIEWS;
    tag: InteractionTagType;
};

export type ReviewsActionType =
    | SearchReviewsFailureType
    | SearchReviewsSuccessType
    | SearchReviewsResetType
    | UpdateReviewSuccessType
    | DeleteReviewType
    | UpdateReviewMarkAsTreatedType
    | UpdateReviewMarkAsNotTreatedType
    | UpdateReviewMarkAsDeletedType
    | UpdateCountType
    | UpdateTagOnReviewsType
    | DeleteTagOnReviewsType;
