import { BUSINESS_MANAGER, GROUP_MANAGER, ORG_ADMIN } from 'app/common/data/roles';
import { useHasMessagePage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasMessagePage';
import { useHasDiffusionPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasDiffusionPage';
import { useHasPresenceAnalyticsPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasPresenceAnalyticsPage';
import { useHasReviewManagementPage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewManagementPage';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useHasHomepage = (): {
    hasHomepage: boolean;
    isLoading: boolean;
} => {
    const { data: me, isLoading } = useMe();

    const hasDiffusion = useHasDiffusionPage();
    const hasPresenceAnalytics = useHasPresenceAnalyticsPage();

    const hasPM = !!(hasDiffusion || hasPresenceAnalytics);
    const hasRM = useHasReviewManagementPage();
    const hasBM = useHasMessagePage();

    const hasOnlyPM = hasPM && !hasRM && !hasBM;
    const hasAtLeastOneProduct = hasPM || hasRM || hasBM;
    const hasCorrectRole = [BUSINESS_MANAGER, GROUP_MANAGER, ORG_ADMIN].includes(me?.role ?? '');

    return {
        hasHomepage: !!(hasAtLeastOneProduct && hasCorrectRole && !hasOnlyPM),
        isLoading,
    };
};
