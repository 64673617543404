import { FC, useEffect, useState } from 'react';

import { Icon, Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { AccountPartnerName } from 'app/api/types/account';
import { BusinessDetailsData } from 'app/api/types/publisher_state';
import LoadingRectangle from 'app/common/components/loaders/LoadingRectangle';

import { ShowLessButton, ShowMoreButton } from './DropdownListBusinesses.styled';
import { getStatusObject } from '../../helpers/helpers';
import { PublisherIcon } from '../../PublisherIcon/PublisherIcon';
import { DropdownBusinessDetail } from '../Dropdown/DropdownBusinessDetail/DropdownBusinessDetail';

const BUSINESSES_PER_PAGE = 5;

type Props = {
    title: string;
    businesses: Array<BusinessDetailsData>;
    status: string;
    isFetching: boolean;
    publisherName: AccountPartnerName;
    publisherFormattedName: string;
    fetchNextPage: () => void;
    hasNextPage: boolean;
};

export const DropdownListBusinesses: FC<Props> = ({
    title,
    businesses,
    status,
    isFetching,
    publisherName,
    publisherFormattedName,
    fetchNextPage,
    hasNextPage,
}) => {
    const { t } = useTranslation();
    const [displayedBusinesses, setDisplayedBusinesses] =
        useState<BusinessDetailsData[]>(businesses);
    const totalFetchedBusinesses = businesses.length;

    const showLess = () =>
        setDisplayedBusinesses(displayedBusinesses.slice(0, BUSINESSES_PER_PAGE));

    const showMore = () => {
        if (hasNextPage && totalFetchedBusinesses === displayedBusinesses.length) {
            fetchNextPage();
        } else {
            setDisplayedBusinesses(
                businesses.slice(0, displayedBusinesses.length + BUSINESSES_PER_PAGE),
            );
        }
    };

    useEffect(() => {
        setDisplayedBusinesses(businesses);
    }, [businesses]);

    const showMoreButton = displayedBusinesses.length < businesses.length || hasNextPage;
    const showLessButton = displayedBusinesses.length > BUSINESSES_PER_PAGE;

    return (
        <Stack
            gap={'8px'}
            style={{ paddingRight: '12px' }}
            data-track={`visibility_diffusion__publisher_table_row_${publisherName}__dropdown_${status}`}
            data-intercom-target={`visibility_diffusion__publisher_table_row_${publisherName}__dropdown_${status}`}
        >
            {totalFetchedBusinesses > 0 && (
                <Stack gap="8px" direction="row" alignItems="center">
                    <PublisherIcon
                        {...getStatusObject(title === 'transmitted' ? 'success' : title)}
                    />
                    <Text variant="heading6" as="span">
                        {t(title)}
                    </Text>
                </Stack>
            )}
            {displayedBusinesses.length !== 0 && (
                <Stack gap={'4px'} style={{ paddingLeft: '32px', paddingBottom: '16px' }}>
                    {displayedBusinesses.map(business => (
                        <DropdownBusinessDetail
                            key={business.business_id}
                            businessDetails={business}
                            publisherName={publisherName}
                            publisherFormattedName={publisherFormattedName}
                            status={status}
                        />
                    ))}
                    {isFetching && (
                        <>
                            {[1, 2, 3].map(index => (
                                <LoadingRectangle
                                    key={index}
                                    recWidth="200px"
                                    recHeight="8px"
                                    recRadius="5%"
                                    recMargin={index === 1 ? '12px 0' : '0 0 12px 0'}
                                />
                            ))}
                        </>
                    )}
                    {showMoreButton && (
                        <ShowMoreButton
                            dataTrackId="show_less"
                            onClick={showMore}
                            appearance="text"
                        >
                            <Stack alignItems="center" direction="row" justifyContent="flex-start">
                                <Text as="span" variant="bodyMBold">
                                    {t('see_more_businesses')}
                                </Text>
                                <Icon icon={['fa-angle-right']} />
                            </Stack>
                        </ShowMoreButton>
                    )}
                    {showLessButton && (
                        <ShowLessButton
                            dataTrackId="show_less"
                            onClick={showLess}
                            appearance="text"
                        >
                            <Stack alignItems="center" direction="row" justifyContent="flex-start">
                                <Text as="span" variant="bodyMBold">
                                    {t('see_less_businesses')}
                                </Text>
                                <Icon icon={['fa-angle-up']} />
                            </Stack>
                        </ShowLessButton>
                    )}
                </Stack>
            )}
        </Stack>
    );
};
