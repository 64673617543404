import { greyColorObject } from '@partoohub/branding';
import { Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import ImportSection from 'app/businessConnection/components/modals/ConnectionModal/InfoSection/ImportSection.container';
import { NoPreview } from 'app/businessConnection/components/modals/ModalCommonComponents/NoPreview';
import { usePartnerConnectionContext } from 'app/businessConnection/components/PartnerConnectionContext';

import { ConnectionModalInfoSection, ContentText, StyledDescription } from './InfoSection.styled';

type Props = {
    locationHasError: boolean;
};

const InfoSection = ({ locationHasError }: Props) => {
    const { t } = useTranslation();
    const { selectedLocationId, connectedLocationId, partnerName } = usePartnerConnectionContext();

    if (partnerName !== 'google') {
        return <NoPreview />;
    }

    return (
        <ConnectionModalInfoSection>
            <Stack gap="32px">
                <Text variant="heading5">{t('import_information')}</Text>
                <StyledDescription variant="bodyMRegular" color="secondary">
                    {t('import_information_description')}
                </StyledDescription>
            </Stack>
            <Stack style={{ flex: 1, overflowY: 'auto' }}>
                {connectedLocationId || selectedLocationId ? (
                    <ImportSection locationHasError={locationHasError} />
                ) : (
                    <ContentText variant="heading3" color={greyColorObject.initial}>
                        {t('select_location_page')}
                    </ContentText>
                )}
            </Stack>
        </ConnectionModalInfoSection>
    );
};

export default InfoSection;
