import { Navigate, useRoutes } from 'react-router-dom';

import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { CONVERSATION_ROUTING } from 'app/common/data/featureFlags';
import { useHasChatbotPage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasChatbotPage';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks/useDeviceType';
import useHasWhatsappAccess from 'app/pages/settings/Whatsapp/hooks/useHasWhatsappAccess';
import { findFirstDisplayPathname } from 'app/pages/settingsV2/helpers';
import { useCanAccessMessagesSetting } from 'app/pages/settingsV2/hooks/Messages/useCanAccessMessagesSetting';
import { useCanAccessWidgetSetupMenu } from 'app/pages/settingsV2/hooks/Messages/useCanAccessWidgetSetupMenu';
import { useSettingMessagesMenu } from 'app/pages/settingsV2/hooks/Messages/useSettingMessagesMenu';
import { Chatbots } from 'app/pages/settingsV2/subPages/Messages/components/Chatbots/Chatbots';
import { ChatPreferences } from 'app/pages/settingsV2/subPages/Messages/components/ChatPreferences/ChatPreferences';
import { ConversationRouter } from 'app/pages/settingsV2/subPages/Messages/components/ConversationRouter/ConversationRouter';
import { ConversationStarters as ConversationStartersV2 } from 'app/pages/settingsV2/subPages/Messages/components/ConversationStarters/ConversationStarters.v2';
import { MessagesReplyTemplates } from 'app/pages/settingsV2/subPages/Messages/components/ReplyTemplates';
import { Whatsapp } from 'app/pages/settingsV2/subPages/Messages/components/Whatsapp/Whatsapp';
import { WhatsappPhoneNumberPage } from 'app/pages/settingsV2/subPages/Messages/components/Whatsapp/WhatsappPhoneNumberPage';
import { WidgetSetup } from 'app/pages/settingsV2/subPages/Messages/components/WidgetSetup/WidgetSetup';
import { SettingMessages } from 'app/pages/settingsV2/subPages/Messages/SettingMessages';
import {
    CHATBOTS_PATH_TOKEN,
    CHAT_PREFERENCES_PATH_TOKEN,
    CONVERSATION_ROUTER_SETTINGS_PATH,
    CONVERSATION_STARTERS_PATH_TOKEN,
    REPLY_TEMPLATES_PATH_TOKEN,
    SETTINGS_MESSAGES_PATH,
    WHATSAPP_PHONE_NUMBER_ID,
    WHATSAPP_SETTINGS_PATH_TOKEN,
    WIDGET_SETUP_ID,
    WIDGET_SETUP_PATH_TOKEN,
} from 'app/routing/routeIds';

export const MessagesRoutes = () => {
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    const { menus: settingsMessagesMenu } = useSettingMessagesMenu();
    const defaultSettingMessagesPathname = findFirstDisplayPathname(settingsMessagesMenu);

    const conversationRoutingFeatureFlag = useFeatureFlag(CONVERSATION_ROUTING);
    const hasChatbotPage = useHasChatbotPage();
    const hasWhatsappAccess = useHasWhatsappAccess();
    const hasCanAccessWidgetSetup = useCanAccessWidgetSetupMenu();
    const { canAccess: hasMessagesSettingAccess } = useCanAccessMessagesSetting();

    const indexRoute = isBelowProvidedDevices
        ? SETTINGS_MESSAGES_PATH
        : defaultSettingMessagesPathname;

    const hasMessagesSettingAccessRoutes = hasMessagesSettingAccess
        ? [
              {
                  index: true,
                  element: <Navigate replace to={indexRoute} />,
              },
              {
                  path: CHAT_PREFERENCES_PATH_TOKEN,
                  element: <ChatPreferences />,
              },
              {
                  path: REPLY_TEMPLATES_PATH_TOKEN,
                  element: <MessagesReplyTemplates />,
              },
              {
                  path: CONVERSATION_STARTERS_PATH_TOKEN,
                  element: <ConversationStartersV2 />,
              },
              ...(hasChatbotPage
                  ? [
                        {
                            path: CHATBOTS_PATH_TOKEN,
                            element: <Chatbots />,
                        },
                    ]
                  : []),
              ...(hasWhatsappAccess
                  ? [
                        {
                            path: WHATSAPP_SETTINGS_PATH_TOKEN,
                            children: [
                                {
                                    index: true,
                                    element: <Whatsapp />,
                                },
                                {
                                    path: `:${WHATSAPP_PHONE_NUMBER_ID}`,
                                    element: <WhatsappPhoneNumberPage />,
                                },
                            ],
                        },
                    ]
                  : []),
              ...(conversationRoutingFeatureFlag && hasChatbotPage && hasWhatsappAccess
                  ? [
                        {
                            path: CONVERSATION_ROUTER_SETTINGS_PATH,
                            element: <ConversationRouter />,
                        },
                    ]
                  : []),
              ...(hasCanAccessWidgetSetup
                  ? [
                        {
                            path: WIDGET_SETUP_PATH_TOKEN,
                            children: [
                                {
                                    path: 'add',
                                    element: <WidgetSetup />,
                                },
                                {
                                    path: `:${WIDGET_SETUP_ID}`,
                                    element: <WidgetSetup />,
                                },
                            ],
                        },
                    ]
                  : []),
          ]
        : [];

    const routes = [
        {
            element: <SettingMessages />,
            children: hasMessagesSettingAccessRoutes,
        },
    ];

    return useRoutes(routes);
};
