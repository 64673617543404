import { useHasMessagePage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasMessagePage';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';

export const useCanAccessMessagesSetting = () => {
    // Sidebar product (BUSINESS_MESSAGE) ON for user
    const userHasBusinessMessage = useHasMessagePage();

    // Valid BM subscription on org
    const {
        hasBusinesses,
        subscribedToBM,
        isLoading: isLoadingBusinessMessageSubscription,
    } = useBusinessSubscriptions();

    const canAccess = userHasBusinessMessage && hasBusinesses && subscribedToBM;
    const isLoading = userHasBusinessMessage === undefined || isLoadingBusinessMessageSubscription;

    return { canAccess, isLoading };
};
