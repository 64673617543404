import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import pictoUrl from 'app/assets/whatsapp/mobile_onboarding_picto_3.png';

import {
    CenteredBody,
    CenteredText,
    CenteredTitle,
    FullWidthBackgroundPicto,
    PageWrapper,
} from './WhatsappAccountImport.styled';

export const WhatsappAccountImportErrorPage: FC = () => {
    const { t } = useTranslation();

    return (
        <PageWrapper>
            <CenteredBody>
                <FullWidthBackgroundPicto src={pictoUrl} />
                <CenteredTitle as={'div'} variant="heading2">
                    {t('whatsapp_mobile_onboarding_error_title')}
                </CenteredTitle>
                <CenteredText as={'div'} variant="bodyLRegular">
                    {t('whatsapp_mobile_onboarding_error_text_1')}{' '}
                    <b>{t('whatsapp_mobile_onboarding_error_text_2')}</b>{' '}
                    {t('whatsapp_mobile_onboarding_error_text_3')}
                </CenteredText>
            </CenteredBody>
        </PageWrapper>
    );
};
