import React from 'react';

import { AccountImportModal } from 'app/businessConnection/components/modals/AccountImportModal/AccountImportModal';
import { PageHeader } from 'app/businessConnection/components/PageHeader/PageHeader';
import { PartnerConnectionsContainer } from 'app/businessConnection/components/PartnerConnections.styled';

import ConnectionsTable from './connectionsTable';
import ConnectionModal from './modals/ConnectionModal';

const PartnerConnections = () => {
    return (
        <PartnerConnectionsContainer>
            <AccountImportModal />
            <ConnectionModal />
            <PageHeader />
            <ConnectionsTable />
        </PartnerConnectionsContainer>
    );
};

export default PartnerConnections;
