import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

export type WidgetSetupIdsData = {
    ids: Array<string>;
};

export class MessagingWidgetSetup extends ApiResourceBaseClass {
    static getWidgetSetupIds(): Promise<WidgetSetupIdsData> {
        return MessagingWidgetSetup.get('widgets/ids').then(({ data }) => {
            return data;
        });
    }
}
