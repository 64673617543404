import styled from '@emotion/styled';
import { Progress, Stack, Text } from '@partoohub/ui';

import { COLORS_FLOW } from 'app/styles/constants/colors_flow';

export const StyledCardContent = styled(Stack)`
    width: 100%;
`;

export const StyledBars = styled(Stack)`
    width: 100%;
    justify-content: space-between;
    flex-direction: column-reverse;
`;

export const StyledBar = styled(Stack)`
    width: 100%;
    align-items: center;
`;

export const StyledProgress = styled(Progress)`
    width: 100%;
    height: 8px;
`;

export const StyledReviewCount = styled(Text)`
    text-wrap: nowrap;
`;
