import { useStateQueryParams } from 'app/common/hooks/useStateQueryParams';
import { ConnectionQueryKeys } from 'app/pages/settingsV2/subPages/Platforms/components/Connection/utils/enums';

export const ALL_TAB = 'all';
export const TO_CONNECT_TO_GMB = 'google';
export const TO_CONNECT_TO_FACEBOOK = 'facebook';
export const CONNECTIONS_PUBLISHERS = [ALL_TAB, TO_CONNECT_TO_GMB, TO_CONNECT_TO_FACEBOOK];
export type NavValue = 'all' | 'google' | 'facebook';
export const useNavTabSelector = (): NavValue => {
    const [publisher] = useStateQueryParams<NavValue>(ConnectionQueryKeys.PUBLISHER);
    if (!CONNECTIONS_PUBLISHERS.includes(publisher)) {
        return ALL_TAB;
    }
    return publisher;
};
