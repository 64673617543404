import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import { MESSAGING_WIDGET_SETUP } from 'app/common/data/queryKeysConstants';

export const useMessagingWidgetSetupIds = () => {
    const { data, isLoading } = useQuery(
        MESSAGING_WIDGET_SETUP,
        api.messagingWidgetSetup.getWidgetSetupIds,
    );
    return { widgetIds: data?.ids, isLoading };
};
