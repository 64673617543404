import { useQuery } from 'react-query';

import Business from 'app/api/v2/api_calls/businessApiCalls';
import { MESSAGING_BUSINESS_CONNECTED_CHANNELS } from 'app/common/data/queryKeysConstants';

export const useBusinessConnectedChannels = (
    selectedBusinessId?: string,
    options?: { enabled?: boolean },
) => {
    return useQuery(
        [MESSAGING_BUSINESS_CONNECTED_CHANNELS, selectedBusinessId],
        () => Business.getBusinessConnectedChannels(selectedBusinessId!),
        options,
    );
};
