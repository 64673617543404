// LOCATION STATUS
import { AccountPartner } from 'app/api/types/account';
import { BusinessImportFieldsType, BusinessInfosImportType } from 'app/api/types/business';
import { GOOGLE_MY_BUSINESS } from 'app/states/knowledge/reducers/businessConnection/accounts';

export const OWNED_LOCATION_STATUS = 'owned';
export const MISSING_LINK_LOCATION_STATUS = 'missing';
export const BROKEN_LOCATION_STATUS = 'broken';

export type LocationStatusType = 'owned' | 'available' | 'missing' | 'broken' | null;

export type GoogleVerificationStatusType =
    | 'verified'
    | 'unverified'
    | 'pending'
    | 'in_review'
    | 'has_duplicate'
    | 'disabled'
    | 'suspended'
    | null;

// ACTION TYPES
export const DISPLAY_CONNECTION_MODAL = 'DISPLAY_CONNECTION_MODAL';
const HIDE_CONNECTION_MODAL = 'HIDE_CONNECTION_MODAL';
const UPDATE_LOCATION_SEARCH_INPUT = 'UPDATE_LOCATION_SEARCH_INPUT';
export const CONNECT_BUSINESS_TO_LOCATION = 'CONNECT_BUSINESS_TO_LOCATION';
const CONNECT_BUSINESS_TO_LOCATION_SUCCESS = 'CONNECT_BUSINESS_TO_LOCATION_SUCCESS';
const CONNECT_BUSINESS_TO_LOCATION_FAILURE = 'CONNECT_BUSINESS_TO_LOCATION_FAILURE';
export const DISCONNECT_BUSINESS_FROM_LOCATION = 'DISCONNECT_BUSINESS_FROM_LOCATION';
const DISCONNECT_BUSINESS_FROM_LOCATION_SUCCESS = 'DISCONNECT_BUSINESS_FROM_LOCATION_SUCCESS';
const DISCONNECT_BUSINESS_FROM_LOCATION_FAILURE = 'DISCONNECT_BUSINESS_FROM_LOCATION_FAILURE';
const RESET_CONNECTION_MODAL = 'RESET_CONNECTION_MODAL';
const UPDATE_BUSINESS_IMPORT = 'UPDATE_BUSINESS_IMPORT';

// ACTION FLOW TYPES
export type DisplayConnectionModalAction = {
    type: 'DISPLAY_CONNECTION_MODAL';
    businessId: string;
    locationId: string | null;
    partner: AccountPartner;
};

type HideConnectionModalAction = {
    type: 'HIDE_CONNECTION_MODAL';
};

type ResetConnectionModalAction = {
    type: 'RESET_CONNECTION_MODAL';
};

type UpdateLocationSearchInputAction = {
    type: 'UPDATE_LOCATION_SEARCH_INPUT';
    input: string;
};

export type ConnectBusinessToLocationAction = {
    type: 'CONNECT_BUSINESS_TO_LOCATION';
    locationId: string;
    owningBusinessId: string;
};

type ConnectBusinessToLocationSuccessAction = {
    type: 'CONNECT_BUSINESS_TO_LOCATION_SUCCESS';
};

type ConnectBusinessToLocationFailureAction = {
    type: 'CONNECT_BUSINESS_TO_LOCATION_FAILURE';
    locationId: string;
};

type DisconnectBusinessFromLocationAction = {
    type: 'DISCONNECT_BUSINESS_FROM_LOCATION';
};

type DisconnectBusinessFromLocationSuccessAction = {
    type: 'DISCONNECT_BUSINESS_FROM_LOCATION_SUCCESS';
};

type DisconnectBusinessFromLocationFailureAction = {
    type: 'DISCONNECT_BUSINESS_FROM_LOCATION_FAILURE';
    error: Record<string, any>;
};

type UpdateBusinessImportAction = {
    type: 'UPDATE_BUSINESS_IMPORT';
    name: string;
    value: BusinessInfosImportType | boolean;
};

export type PartnerConnectionState = {
    showConnectionModal: boolean;
    selectedBusinessId: string | null;
    connectedLocationId: string | null;
    searchLocationInput: string;
    connectionInProgress: boolean;
    connectionError: Record<string, any>;
    connectionSuccess: boolean;
    disconnectionInProgress: boolean;
    disconnectionError: Record<string, any>;
    disconnectionSuccess: boolean;
    selectedPartner: AccountPartner;
    businessImport: BusinessImportFieldsType;
};

export type PartnerConnectionAction =
    | UpdateLocationSearchInputAction
    | ConnectBusinessToLocationAction
    | ConnectBusinessToLocationSuccessAction
    | ConnectBusinessToLocationFailureAction
    | DisconnectBusinessFromLocationAction
    | DisconnectBusinessFromLocationSuccessAction
    | DisconnectBusinessFromLocationFailureAction
    | ResetConnectionModalAction
    | HideConnectionModalAction
    | UpdateBusinessImportAction;

// ACTION CREATORS
export const hideConnectionModal = (): HideConnectionModalAction => ({
    type: HIDE_CONNECTION_MODAL,
});

export const resetConnectionModal = (): ResetConnectionModalAction => ({
    type: RESET_CONNECTION_MODAL,
});

export const updateLocationSearchInput = (input: string): UpdateLocationSearchInputAction => ({
    type: UPDATE_LOCATION_SEARCH_INPUT,
    input,
});

export const connectBusinessToLocation = (
    locationId: string,
    owningBusinessId: string,
): ConnectBusinessToLocationAction => ({
    type: CONNECT_BUSINESS_TO_LOCATION,
    locationId,
    owningBusinessId,
});

export const connectBusinessToLocationSuccess = (): ConnectBusinessToLocationSuccessAction => ({
    type: CONNECT_BUSINESS_TO_LOCATION_SUCCESS,
});

export const connectBusinessToLocationFailure = (
    locationId: string,
): ConnectBusinessToLocationFailureAction => ({
    type: CONNECT_BUSINESS_TO_LOCATION_FAILURE,
    locationId,
});

export const disconnectBusinessFromLocation = (): DisconnectBusinessFromLocationAction => ({
    type: DISCONNECT_BUSINESS_FROM_LOCATION,
});

export const disconnectBusinessFromLocationSuccess =
    (): DisconnectBusinessFromLocationSuccessAction => ({
        type: DISCONNECT_BUSINESS_FROM_LOCATION_SUCCESS,
    });

export const disconnectBusinessFromLocationFailure = (
    error: Record<string, any>,
): DisconnectBusinessFromLocationFailureAction => ({
    type: DISCONNECT_BUSINESS_FROM_LOCATION_FAILURE,
    error,
});
export const updateBusinessImport = (
    name: string,
    value: BusinessInfosImportType | boolean,
): UpdateBusinessImportAction => ({
    type: UPDATE_BUSINESS_IMPORT,
    name,
    value,
});

// INITIAL STATES
const initialState: PartnerConnectionState = {
    showConnectionModal: false,
    selectedBusinessId: null,
    connectedLocationId: null,
    searchLocationInput: '',
    connectionInProgress: false,
    connectionError: {},
    connectionSuccess: false,
    disconnectionInProgress: false,
    disconnectionError: {},
    disconnectionSuccess: false,
    selectedPartner: GOOGLE_MY_BUSINESS as AccountPartner,
    businessImport: {
        businessInfos: {
            name: false,
            code: false,
            categories: false,
            coordinates: false,
            description: false,
            phone: false,
            website: false,
            openHours: false,
            specificHours: false,
            openingDate: false,
            additionalUrls: false,
            addressFull: false,
            moreHours: false,
        },
        photos: false,
        attributes: false,
        services: false,
        foodMenu: false,
        googlePosts: false,
    },
};

// REDUCER
const partnerConnectionReducer = (
    state: PartnerConnectionState = initialState,
    action: PartnerConnectionAction,
): PartnerConnectionState => {
    switch (action.type) {
        case RESET_CONNECTION_MODAL:
            return { ...initialState, selectedPartner: state.selectedPartner };

        case HIDE_CONNECTION_MODAL:
            return { ...state, showConnectionModal: false };

        case UPDATE_LOCATION_SEARCH_INPUT:
            return { ...state, searchLocationInput: action.input };

        case CONNECT_BUSINESS_TO_LOCATION:
            return {
                ...state,
                connectionInProgress: true,
                connectionError: {},
            };

        case CONNECT_BUSINESS_TO_LOCATION_SUCCESS:
            return {
                ...state,
                connectionInProgress: false,
                connectionSuccess: true,
            };

        case CONNECT_BUSINESS_TO_LOCATION_FAILURE:
            return {
                ...state,
                connectionInProgress: false,
                connectionError: {
                    ...state.connectionError,
                    [action.locationId]: true,
                },
            };

        case DISCONNECT_BUSINESS_FROM_LOCATION:
            return {
                ...state,
                disconnectionInProgress: true,
                disconnectionError: {},
            };

        case DISCONNECT_BUSINESS_FROM_LOCATION_SUCCESS:
            return {
                ...state,
                disconnectionInProgress: false,
                disconnectionSuccess: true,
            };

        case DISCONNECT_BUSINESS_FROM_LOCATION_FAILURE:
            return {
                ...state,
                disconnectionInProgress: false,
                disconnectionError: { ...action.error },
            };

        case UPDATE_BUSINESS_IMPORT:
            return {
                ...state,
                businessImport: {
                    ...state.businessImport,
                    [action.name]: action.value,
                },
            };

        default:
            return state;
    }
};

// SELECTORS
export const selectedBusinessIdSelector = (state: PartnerConnectionState): string | null =>
    state.selectedBusinessId;

export const connectedLocationIdSelector = (state: PartnerConnectionState): string | null =>
    state.connectedLocationId;

export const searchLocationInputSelector = (state: PartnerConnectionState): string =>
    state.searchLocationInput;

export const connectionInProgressSelector = (state: PartnerConnectionState): boolean =>
    state.connectionInProgress;

export const connectionSuccessSelector = (state: PartnerConnectionState): boolean =>
    state.connectionSuccess;

export const disconnectionInProgressSelector = (state: PartnerConnectionState): boolean =>
    state.disconnectionInProgress;

export const disconnectionSuccessSelector = (state: PartnerConnectionState): boolean =>
    state.disconnectionSuccess;

export const selectedPartnerSelector = (state: PartnerConnectionState): AccountPartner =>
    state.selectedPartner;

export const businessImportSelector = (state: PartnerConnectionState): BusinessImportFieldsType =>
    state.businessImport;

export const connectionErrorSelector = (state: PartnerConnectionState): Record<string, any> =>
    state.connectionError;

export default partnerConnectionReducer;
