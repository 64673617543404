import React from 'react';

import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import DesktopHeader from 'app/businessConnection/components/PageHeader/DesktopHeader/DesktopHeader';
import MobileHeader from 'app/businessConnection/components/PageHeader/MobileHeader/MobileHeader';
import { usePartnerConnectionContext } from 'app/businessConnection/components/PartnerConnectionContext';
import { CONNECTION_STATS } from 'app/common/data/queryKeysConstants';
import { useIsTablet } from 'app/hooks/useIsTablet';

export const PageHeader = () => {
    const { t } = useTranslation();
    const { setBusinessConnectionsStats } = usePartnerConnectionContext();
    const isTablet = useIsTablet();

    useQuery([CONNECTION_STATS], () => api.business.getUserBusinessConnectionsStats(), {
        onSuccess: data => {
            setBusinessConnectionsStats(data);
        },
        onError: () => toast.error(null, t('unknown error')),
        staleTime: 0,
    });

    return <>{isTablet ? <MobileHeader /> : <DesktopHeader />}</>;
};
