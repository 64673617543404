import { ADMIN } from 'app/common/data/roles';
import { useHasPostsPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasPostsPage';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { useHasPostPermission } from 'app/presence/googlePosts/hooks/useHasPostPermission';

export const useHomepageCanCreatePost = (): boolean => {
    const { data: me } = useMe();
    const hasPostPage = useHasPostsPage();
    const canCreatePost = useHasPostPermission() ?? false;

    return !!hasPostPage && canCreatePost && me?.role !== ADMIN;
};
