import { useState } from 'react';

import {
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    Stack,
    Table,
    Text,
} from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { ReviewAnalyticsDimension } from 'app/api/types/review_analytics';
import { sumValues } from 'app/common/utils/sumValues';

import { DimensionSwitch } from './common/DimensionSwitch';
import { useInternalRankingParameters } from './hooks/useInternalRankingParameters';
import { StyledCell, StyledHeader } from './Rankings.styled';
import { useReplyTimeInternalRanking } from '../../../hooks/useMetrics';
import { ExportModal } from '../../ExportModal/ExportModal';

export const ReplyTimeRanking = () => {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    const metrics = useReplyTimeInternalRanking();
    const {
        dimension: [selectedDimension],
    } = useInternalRankingParameters();

    return (
        <Stack>
            <StyledHeader>
                <Text variant="heading6">{t('review_analytics_internal_ranking')}</Text>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <DimensionSwitch />
                    <IconButton
                        dataTrackId=""
                        appearance="outlined"
                        icon={[FontAwesomeIconsPartooUsed.faArrowDownToLine, IconPrefix.REGULAR]}
                        tooltipPlacement="bottom"
                        tooltip={t('download_data')}
                        onClick={() => setOpenModal(true)}
                    />
                </Stack>
            </StyledHeader>

            <Table
                columns={[
                    {
                        label: '#',
                        width: '300px',
                    },
                    {
                        label: t('review_analytics_rankings_businesses'),
                        width: '3000px',
                    },
                    {
                        label: t('review_analytics_rankings_reviews'),
                        width: '1000px',
                    },
                    {
                        label: t('review_analytics_rankings_reply_time_fast'),
                        width: '1000px',
                    },
                    {
                        label: t('review_analytics_rankings_reply_time_slow'),
                        width: '1000px',
                    },
                    {
                        label: t('review_analytics_rankings_reply_time_not_replied'),
                        width: '1000px',
                    },
                ]}
            >
                {!!metrics ? (
                    <Table.Body>
                        {metrics.data.map(({ dimension, dimension_name, metrics }) => (
                            <Table.Row dataTrackId="" id={dimension} key={dimension}>
                                <StyledCell></StyledCell>
                                <StyledCell>{dimension_name}</StyledCell>
                                <StyledCell>{sumValues(metrics.reply_time)}</StyledCell>
                                <StyledCell>{metrics.reply_time.fast}</StyledCell>
                                <StyledCell>{metrics.reply_time.slow}</StyledCell>
                                <StyledCell>{metrics.reply_time.not_replied}</StyledCell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                ) : undefined}
            </Table>
            <ExportModal
                isOpenExportModal={openModal}
                setIsOpenExportModal={setOpenModal}
                dimensionType={selectedDimension as ReviewAnalyticsDimension}
            />
        </Stack>
    );
};
