import { useHasChatbotPage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasChatbotPage';
import { ShortcutJim } from 'app/pages/Homepage/sections/HomepageShortcuts/shortcuts/ShortcutJim';
import { Item } from 'app/pages/Homepage/utils/typing';

export const useShortcutJimItem = (): Item => {
    const isAvailable = !!useHasChatbotPage();

    return {
        order: 1,
        isAvailable,
        component: ShortcutJim,
        key: 'shortcut-jim',
    };
};
