import { useMemo, useState } from 'react';

import {
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    Stack,
    Table,
    Text,
} from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { sumValues } from 'app/common/utils/sumValues';

import { createColumnConfig } from './common/createColumnConfig';
import { PageNavigator } from './common/PageNavigator';
import { SectionSelect } from './common/SectionSelect';
import { BusinessDistributionBar } from './components';
import { useHandleSort } from './hooks/useHandleSort';
import { useInternalRankingParameters } from './hooks/useInternalRankingParameters';
import { StyledBackgroundTableCell, StyledCell, StyledHeader } from './Rankings.styled';
import { formatValue, generateTableColumns } from '../../../helper';
import {
    getApiPage,
    getDataForTablePage,
    useEReputationInternalRanking,
} from '../../../hooks/useMetrics';
import { ExportModal } from '../../ExportModal/ExportModal';

export const EReputationRanking = () => {
    const [page, setPage] = useState(1);
    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const {
        section: [currentSection],
    } = useInternalRankingParameters();
    const dimension = currentSection ? 'group' : 'business';

    // Use currentOrder to determine orderBy
    const { currentOrder, handleSort } = useHandleSort('dimension_name', 'asc');
    const orderBy =
        currentOrder.order === 'asc' ? currentOrder.columnId : `-${currentOrder.columnId}`;

    const metrics = useEReputationInternalRanking(dimension, getApiPage(page), orderBy);
    const metrics_data = metrics || { count: 0, data: [] };
    const data = useMemo(() => {
        return getDataForTablePage(metrics_data?.data ?? [], page);
    }, [metrics_data?.data, page]);

    const columnsConfig = useMemo(
        () => [
            createColumnConfig('#', '#', '300px', false),
            createColumnConfig(
                'dimension_name',
                t('review_analytics_rankings_businesses'),
                '3000px',
            ),
            createColumnConfig(
                'average_rating',
                t('review_analytics_rankings_average_rating'),
                '1000px',
            ),
            createColumnConfig('reviews_count', t('review_analytics_rankings_reviews'), '1000px'),
            createColumnConfig(
                'ratio__rating_distribution__5',
                t('review_analytics_rankings_5_stars_reviews'),
                '1000px',
            ),
            createColumnConfig(
                'ratio__rating_distribution__negative',
                t('review_analytics_rankings_1_2_stars_reviews'),
                '1000px',
            ),
        ],
        [t],
    );

    const tableColumns = generateTableColumns(currentOrder, handleSort, setPage, columnsConfig);

    return (
        <Stack>
            <StyledHeader>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Text variant="heading6">{t('review_analytics_internal_ranking')}</Text>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <SectionSelect />
                        <IconButton
                            dataTrackId=""
                            appearance="outlined"
                            icon={[
                                FontAwesomeIconsPartooUsed.faArrowDownToLine,
                                IconPrefix.REGULAR,
                            ]}
                            tooltipPlacement="bottom"
                            tooltip={t('download_data')}
                            onClick={() => setOpenModal(true)}
                        />
                    </Stack>
                </Stack>
                <BusinessDistributionBar />
            </StyledHeader>
            <Table columns={tableColumns}>
                <Table.Body>
                    {data.map(({ dimension, dimension_name, metrics }) => {
                        const reviewCount = sumValues(metrics.rating_distribution) ?? 0;
                        const star5 = formatValue(metrics.rating_distribution[5], reviewCount);
                        const star1And2 = formatValue(
                            metrics.rating_distribution[1] === null &&
                                metrics.rating_distribution[2] === null
                                ? null
                                : Number(metrics.rating_distribution[1]) +
                                      Number(metrics.rating_distribution[2]),
                            reviewCount,
                        );

                        const getColor = () => {
                            if (metrics.average_rating !== null) {
                                if (metrics.average_rating >= 4.3) return 'success';
                                else if (metrics.average_rating >= 3.8) return 'emerald';
                                else if (metrics.average_rating >= 3) return 'warning';
                                else if (metrics.average_rating >= 1) return 'danger';
                            }
                            return 'secondary';
                        };

                        return (
                            <Table.Row
                                dataTrackId="review_analytics__e-reputation_ranking"
                                id={dimension}
                                key={dimension}
                            >
                                <StyledCell>
                                    {reviewCount === 0 || metrics.rank == null
                                        ? 'NC'
                                        : metrics.rank}
                                </StyledCell>
                                <StyledCell align="left">{dimension_name}</StyledCell>
                                <StyledBackgroundTableCell color={getColor()}>
                                    {metrics.average_rating?.toFixed(2) ?? '-'}
                                </StyledBackgroundTableCell>
                                <StyledCell>{reviewCount}</StyledCell>
                                <StyledCell>{star5}</StyledCell>
                                <StyledCell>{star1And2}</StyledCell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
            <PageNavigator
                dataTrackIdKey="e-reputation"
                dataCount={metrics_data.count}
                dataLength={metrics_data.data.length}
                page={page}
                setPage={setPage}
            />
            <ExportModal
                isOpenExportModal={openModal}
                setIsOpenExportModal={setOpenModal}
                dimensionType={dimension}
            />
        </Stack>
    );
};
