import { toast } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import { ReviewAnalyticsDimension, ReviewAnalyticsMetric } from 'app/api/types/review_analytics';
import api from 'app/api/v2/api_calls';

import { useGraphSelection } from './useGraphSelection';
import { useReviewAnalyticsFilters } from './useReviewAnalyticsFilters';

export const useDownloadMetrics = <T extends ReviewAnalyticsDimension>(
    email: string,
    dimensions?: T,
) => {
    const { t } = useTranslation();
    const filters = useReviewAnalyticsFilters();
    const [graph] = useGraphSelection();

    let metrics: ReviewAnalyticsMetric[] = [];

    switch (graph) {
        case 'average_rating':
        case 'rating_distribution':
        case 'review_count':
        case 'tags':
            metrics = ['average_rating', 'rating_distribution', 'reviews_count'];
            break;
        case 'reply_means':
        case 'reply_time':
            metrics = [graph];
            break;
        default:
            metrics = [];
            break;
    }

    return useMutation(
        () => api.reviewAnalytics.downloadMetrics(metrics, email, filters, dimensions),
        {
            onSuccess: () => {
                toast.loading(
                    t('hours_update_progress_export_progress_report'),
                    t('hours_update_progress_export_progress'),
                );
            },
            onError: (error: Error) => {
                toast.error(error.message, t('request_issue'));
            },
        },
    );
};
