import { WebsocketObject } from 'app/states/realtimeUpdates';
import {
    CLEAR_ALL_REALTIME_REVIEWS,
    REALTIME_UPDATE_REVIEW_RECEIVED,
} from 'app/states/realtimeUpdates/actions/constants';

export type ClearAllRealtimeReviewsType = {
    type: typeof CLEAR_ALL_REALTIME_REVIEWS;
};

export type RealtimeUpdateReviewReceivedType = {
    type: typeof REALTIME_UPDATE_REVIEW_RECEIVED;
    review: WebsocketObject;
};

export type RealtimeUpdatesActionType =
    | ClearAllRealtimeReviewsType
    | RealtimeUpdateReviewReceivedType;
