export type ReviewAnalyticsState = {
    evolution: {
        analytics: {
            totalSum: Array<Record<string, any>>;
            periodSum: Array<Record<string, any>>;
            totalAverage: Array<Record<string, any>>;
            periodAverage: Array<Record<string, any>>;
            evolutionFetching: boolean;
        };
        isPeriod: boolean;
        isQualitative: boolean;
        bucket: string;
    };
    averageGrade: {
        total: {
            value: number;
            labels: {
                satisfied: number;
                neutral: number;
                dissatisfied: number;
            };
        };
        period: {
            value: number;
            labels: {
                satisfied: number;
                neutral: number;
                dissatisfied: number;
            };
        };
    };
    ratings: {
        totalReviews: number;
        periodReviews: number;
    };
    answerRate: {
        totalAnswerRate: number;
        periodAnswerRate: number;
        estimatedTotalTimeAnswerRate: number;
        estimatedPeriodTimeAnswerRate: number;
    };
    isFetchingPeriod: boolean;
    isFetchingTotal: boolean;
};
