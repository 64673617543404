import { ReviewAnalyticsData } from 'app/api/types/review';

import * as constants from './constants';

export type GetReviewStatsTotalRequestType = {
    type: typeof constants.GET_REVIEW_STATS_TOTAL_REQUEST;
};

export type GetReviewStatsTotalSuccessType = {
    type: typeof constants.GET_REVIEW_STATS_TOTAL_SUCCESS;
    average: number;
    satisfied: number;
    neutral: number;
    dissatisfied: number;
    answerTime: number;
    notTreated: number;
    total: number;
    treated: number;
    answerRate: number;
};

export type GetReviewStatsTotalFailureType = {
    type: typeof constants.GET_REVIEW_STATS_TOTAL_FAILURE;
    error: Record<string, any>;
};

export type GetReviewStatsPeriodRequestType = {
    type: typeof constants.GET_REVIEW_STATS_PERIOD_REQUEST;
};

export type GetReviewStatsPeriodSuccessType = {
    type: typeof constants.GET_REVIEW_STATS_PERIOD_SUCCESS;
    average: number;
    satisfied: number;
    neutral: number;
    dissatisfied: number;
    answerTime: number;
    notTreated: number;
    total: number;
    treated: number;
    answerRate: number;
};

export type GetReviewStatsPeriodFailureType = {
    type: typeof constants.GET_REVIEW_STATS_PERIOD_FAILURE;
    error: Record<string, any>;
};

export type GetReviewsPeriodTotalRequestType = {
    type: typeof constants.GET_REVIEWS_PERIOD_TOTAL_REQUEST;
    isPeriod: boolean;
};

export type GetReviewsEvolutionRequestType = {
    type: typeof constants.GET_REVIEWS_EVOLUTION_REQUEST;
    isQualitative: boolean;
    isPeriod: boolean;
};

export type GetReviewsEvolutionFailureType = {
    type: typeof constants.GET_REVIEWS_EVOLUTION_FAILURE;
    error: Record<string, any>;
};

export type GetReviewsEvolutionSuccessType = {
    type: typeof constants.GET_REVIEWS_EVOLUTION_SUCCESS;
    analytics: Array<ReviewAnalyticsData>;
    isQualitative: boolean;
    isPeriod: boolean;
};

export type UpdateIsQualitativeType = {
    type: typeof constants.UPDATE_IS_QUALITATIVE;
    isQualitative: boolean;
};

export type UpdateBucketType = {
    type: typeof constants.UPDATE_REVIEWS_EVOLUTION_BUCKET;
    bucket: string;
};

export type ResetEvolutionTotalAnalyticsType = {
    type: typeof constants.RESET_EVOLUTION_TOTAL_ANALYTICS;
};

export type ResetEvolutionPeriodAnalyticsType = {
    type: typeof constants.RESET_EVOLUTION_PERIOD_ANALYTICS;
};

export type ReviewAnalyticsActionType =
    | GetReviewStatsTotalRequestType
    | GetReviewStatsTotalSuccessType
    | GetReviewStatsTotalFailureType
    | GetReviewStatsPeriodRequestType
    | GetReviewStatsPeriodSuccessType
    | GetReviewStatsPeriodFailureType
    | GetReviewsPeriodTotalRequestType
    | GetReviewsEvolutionRequestType
    | GetReviewsEvolutionFailureType
    | GetReviewsEvolutionSuccessType
    | UpdateIsQualitativeType
    | ResetEvolutionTotalAnalyticsType
    | ResetEvolutionPeriodAnalyticsType
    | UpdateBucketType;
