import { ReactNode } from 'react';

import { HEX_COLORS } from '@partoohub/branding';
import { Chip, Stack, Text } from '@partoohub/ui';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { ReviewObject } from 'app/states/reviews';
import { PENDING_AUTO_REPLY_REVIEWS, TREATED_AUTO_REPLY_REVIEWS } from 'app/states/reviews/filters';

import { StyledAutoReplyDate, StyledCommentAuthorMessage } from './CommentAuthorMessage.styled';

type Props = {
    reviewState: ReviewObject['state'];
    canEdit: boolean;
    notFetched: boolean | undefined;
    author?: string;
    commentDate?: number | string;
    message?: string;
    commentMenu?: ReactNode;
    isReplySuggestion?: boolean;
};

export default function CommentAuthorMessage({
    reviewState,
    author,
    canEdit,
    notFetched,
    commentDate,
    message,
    commentMenu,
    isReplySuggestion,
}: Props) {
    const { t } = useTranslation();

    const isAutoReply =
        reviewState === TREATED_AUTO_REPLY_REVIEWS || reviewState === PENDING_AUTO_REPLY_REVIEWS;

    const displayedAuthor = (() => {
        if (isAutoReply) {
            return t('auto_reply_replied');
        }
        if (isReplySuggestion) {
            return `${t('review_ai_suggestion_reply_validation')} ${author}`;
        }
        return author || t('your_reply');
    })();

    const fromOtherAuthor = !(canEdit || isAutoReply);

    return (
        <StyledCommentAuthorMessage>
            <Stack direction="row" alignItems="center" gap="0">
                <Text
                    as="div"
                    variant={fromOtherAuthor ? 'bodyMSemibold' : 'bodyMBold'}
                    color={fromOtherAuthor ? HEX_COLORS.blackberry : 'default'}
                >
                    {displayedAuthor}
                </Text>
                {isAutoReply && commentDate && (
                    <StyledAutoReplyDate as="div" color={'secondary'}>
                        {commentDate ? format(new Date(commentDate), 'P p') : undefined}
                    </StyledAutoReplyDate>
                )}
                {commentMenu}
                {notFetched ? (
                    <Chip variant={'danger'} dataTrackId="">
                        {t('missing_google_reply')}
                    </Chip>
                ) : null}
            </Stack>
            <Text as="div">{message}</Text>
        </StyledCommentAuthorMessage>
    );
}
