import { useMutation } from 'react-query';

import { BusinessCreationRequestData, CreationSuccessResponses } from 'app/api/types/business';
import api from 'app/api/v2/api_calls';

import { useCanSendClevertapEvents } from 'app/common/hooks/queries/useCanSendClevertapEvents';
import { PushNotifsEvent } from 'app/common/services/pushNotifications/events';
import { PushNotificationsWrapper } from 'app/common/services/pushNotifications/pushNotificationsWrapper';

import { PARTOO_APP_EVENT_IDS, sdkBridge } from 'app/SDKBridge';

export function useBusinessCreate(
    onSuccess: (response: CreationSuccessResponses) => void = () => undefined,
    onError: (error: Error) => void = () => undefined,
) {
    const canSendClevertapEvents = useCanSendClevertapEvents();

    return useMutation(
        (payload: BusinessCreationRequestData) => api.business.createBusiness(payload),
        {
            onSuccess: async data => {
                const response = data as CreationSuccessResponses;
                const businessId = response.business_id;

                // CleverTap event
                if (canSendClevertapEvents) {
                    PushNotificationsWrapper.getInstance().sendEvent(
                        PushNotifsEvent.CREATE_BUSINESS,
                    );
                }

                sdkBridge.onEventOccurred(PARTOO_APP_EVENT_IDS.BUSINESS_CREATED_EVENT, businessId);
                return onSuccess(response);
            },
            onError,
        },
    );
}
