import { useHasMessagePage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasMessagePage';
import { SmallStatProps } from 'app/pages/Homepage/sections/HomepageStats/DefaultStatComponent/DefaultStatComponent';
import { StatMessages } from 'app/pages/Homepage/sections/HomepageStats/stats/StatMessages';
import { Item } from 'app/pages/Homepage/utils/typing';

export const useStatMessagesItem = (): Item<SmallStatProps> => {
    const isAvailable = !!useHasMessagePage();

    return {
        order: 3,
        isAvailable,
        component: StatMessages,
        key: 'stat-messages',
    };
};
