import { InteractionTagType } from 'app/api/types/interactionTag';
import { ReviewObject } from 'app/api/types/review';
import { ReviewCountByType } from 'app/states/reviews';

import * as constants from './constants';
import * as types from './types';

export const searchReviewsResetAction = (): types.SearchReviewsResetType => ({
    type: constants.SEARCH_REVIEWS_RESET,
});

export const searchReviewsFailureAction = (
    error: Record<string, any>,
): types.SearchReviewsFailureType => ({
    type: constants.SEARCH_REVIEWS_FAILURE,
    error,
});

export const searchReviewsSuccessAction = (
    byId: Record<string, ReviewObject>,
    ids: Array<string>,
    hasMore: boolean,
    count: number,
    countByType: ReviewCountByType,
): types.SearchReviewsSuccessType => ({
    type: constants.SEARCH_REVIEWS_SUCCESS,
    byId,
    ids,
    hasMore,
    count,
    countByType,
});

export const updateReviewSuccessAction = (review: ReviewObject): types.UpdateReviewSuccessType => ({
    type: constants.UPDATE_REVIEW_SUCCESS,
    review,
});

export const deleteReviewAction = (review: ReviewObject): types.DeleteReviewType => ({
    type: constants.DELETE_REVIEW,
    review,
});

export const updateReviewMarkAsTreatedAction = (
    review: ReviewObject,
): types.UpdateReviewMarkAsTreatedType => ({
    type: constants.UPDATE_REVIEW_MARK_AS_TREATED,
    review,
});

export const updateReviewMarkAsNotTreatedAction = (
    review: ReviewObject,
): types.UpdateReviewMarkAsNotTreatedType => ({
    type: constants.UPDATE_REVIEW_MARK_AS_NOT_TREATED,
    review,
});

export const updateReviewMarkAsDeletedAction = (
    review: ReviewObject,
): types.UpdateReviewMarkAsDeletedType => ({
    type: constants.UPDATE_REVIEW_MARK_AS_DELETED,
    review,
});

export const updateCount = (count: number): types.UpdateCountType => ({
    type: constants.UPDATE_COUNT,
    count,
});

export const updateTagOnReviews = (tag: InteractionTagType) => ({
    type: constants.UPDATE_TAG_ON_REVIEWS,
    tag,
});

export const deleteTagOnReviews = (tag: InteractionTagType) => ({
    type: constants.DELETE_TAG_ON_REVIEWS,
    tag,
});
