import { FontAwesomeIconsPartooUsed, IconButton } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useHasDiffusionPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasDiffusionPage';
import { VISIBILITY_DIFFUSION_PATH } from 'app/routing/routeIds';

export const DiffusionListButton = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const hasDiffusionPage = useHasDiffusionPage();

    const handleRedirect = () => {
        navigate(VISIBILITY_DIFFUSION_PATH);
    };

    if (!hasDiffusionPage) {
        return null;
    }
    return (
        <IconButton
            dataTrackId=""
            icon={[FontAwesomeIconsPartooUsed.faRadar]}
            onClick={handleRedirect}
            appearance="outlined"
            tooltip={t('listing')}
            tooltipPlacement="bottom"
        />
    );
};
