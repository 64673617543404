import { useEffect } from 'react';

import { AppDataContextType, useAppDataContext } from 'app/common/contexts/appData';

import { handleGoogleMap, removeGoogleMapScript } from './handleGoogleMap';
import { handleGoogleTagManager, removeGoogleTagManagerScript } from './handleGoogleTagManager';
import { handleIntercomPostLogin, removeIntercomPostLogin } from './handleIntercomPostLogin';
import { removeSurvicateScript, useHandleSurvicate } from './handleSurvicate';
import { handleWhatsappEmbeddedSignup } from './handleWhatsappEmbeddedSignupScript';

const useSetupPostLoginScripts = (appData: AppDataContextType) => {
    const handleSurvicate = useHandleSurvicate(appData.me, appData.organization);

    return () =>
        Promise.allSettled([
            handleGoogleMap(),
            handleWhatsappEmbeddedSignup(),
            handleSurvicate(),
            handleGoogleTagManager(appData.me, appData.organization),
            handleIntercomPostLogin(appData.me),
        ]);
};

const useRemovePostLoginScripts = () => {
    return () => {
        removeGoogleMapScript();
        removeSurvicateScript();
        removeGoogleTagManagerScript();
        removeIntercomPostLogin();
    };
};

export const useHandlePostLoginScripts = () => {
    const appData = useAppDataContext();
    const setupPostLoginScripts = useSetupPostLoginScripts(appData);
    const removePostLoginScripts = useRemovePostLoginScripts();

    // Handle post login scripts
    useEffect(() => {
        setupPostLoginScripts();
        return () => {
            removePostLoginScripts();
        };
    }, []);
};
