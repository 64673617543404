import { useState } from 'react';

import { V2BusinessData } from 'app/api/types/business';
import FoundLocationsSection from 'app/businessConnection/components/modals/ConnectionModal/SearchLocationsSection/FoundLocationsSection';
import { usePartnerConnectionContext } from 'app/businessConnection/components/PartnerConnectionContext';
import SearchField from 'app/common/designSystem/components/atoms/SearchField';

type Props = {
    business: V2BusinessData | undefined;
};

const SearchLocationsSection = ({ business }: Props) => {
    const { partnerName } = usePartnerConnectionContext();
    const [searchInput, setSearchInput] = useState('');

    const handleSearchChange = (value: string) => {
        setSearchInput(value);
    };

    return (
        <div>
            <SearchField
                leftIcon={<i className="fa-solid fa-search" />}
                focusOnInput
                initValue={business ? `${business.name} ${business.city}` : ''}
                value={searchInput}
                onChange={handleSearchChange}
                placeholder={`search_${partnerName}_location_placeholder`}
                name="search_location"
            />
            <FoundLocationsSection searchInput={searchInput} />
        </div>
    );
};

export default SearchLocationsSection;
