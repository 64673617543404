import { useTranslation } from 'react-i18next';

import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';

import CompetitiveBenchmarkRoot from './CompetitiveBenchmarkRoot';
import { CompetitiveBenchmarkRootWithNewSidebar } from './CompetitiveBenchmarkRootWithNewSidebar';

export const CompetitiveBenchmarkPage = () => {
    const { t } = useTranslation();

    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const CompetitiveBenchmarkRootComponent = isNewSidebarEnabled
        ? CompetitiveBenchmarkRootWithNewSidebar
        : CompetitiveBenchmarkRoot;

    return (
        <>
            <DynamicPageHeader title={t('competitive_benchmark_title')} />
            <CompetitiveBenchmarkRootComponent />
        </>
    );
};
