import { Navigate, useRoutes } from 'react-router-dom';

import { useHasDiffusionPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasDiffusionPage';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import { BulkUpdatePage } from 'app/pages/visibility/BulkUpdate';
import { BusinessCreatePage } from 'app/pages/visibility/BusinessCreate';
import { BusinessEditV2Page } from 'app/pages/visibility/BusinessEditV2';
import { BusinessListV2Page } from 'app/pages/visibility/BusinessListV2';
import useBusinessCount from 'app/pages/visibility/BusinessListV2/hooks/businesses/useBusinessCount';
import { BusinessSelectionProvider } from 'app/pages/visibility/BusinessListV2/hooks/businessSelection/useBusinessSelectionContext';
import { DiffusionPage } from 'app/pages/visibility/Diffusion';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import {
    ADD_PATH_TOKEN,
    BULK_UPDATE_EXPORT_PATH_TOKEN,
    BULK_UPDATE_PATH_TOKEN,
    BUSINESS_ID,
    DIFFUSION_PATH_TOKEN,
    LOCATIONS_PATH_TOKEN,
    NOT_FOUND_PATH,
    VISIBILITY_LOCATION_PATH,
} from 'app/routing/routeIds';

const VisibilityRoutes = () => {
    const { data: me } = useMe();
    const meRole = me ? me.role : null;

    const hasDiffusionPage = useHasDiffusionPage();

    const total = useBusinessCount();
    const hasOnlyOneBusiness = total === 1;

    const routes = [
        {
            index: true,
            element: <Navigate replace to={VISIBILITY_LOCATION_PATH} />,
        },
        {
            path: LOCATIONS_PATH_TOKEN,
            children: [
                {
                    index: true,
                    element: (
                        <ProtectedRoute userRole={meRole}>
                            <BusinessSelectionProvider>
                                <BusinessListV2Page />
                            </BusinessSelectionProvider>
                        </ProtectedRoute>
                    ),
                },
                {
                    path: ADD_PATH_TOKEN,
                    element: (
                        <ProtectedRoute userRole={meRole}>
                            <BusinessCreatePage />
                        </ProtectedRoute>
                    ),
                },
                {
                    path: `:${BUSINESS_ID}`,
                    element: (
                        <ProtectedRoute userRole={meRole}>
                            <BusinessSelectionProvider>
                                <BusinessEditV2Page />
                            </BusinessSelectionProvider>
                        </ProtectedRoute>
                    ),
                },
            ],
        },
        ...(hasDiffusionPage
            ? [
                  {
                      path: DIFFUSION_PATH_TOKEN,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <DiffusionPage />
                          </ProtectedRoute>
                      ),
                  },
              ]
            : []),
        ...(!hasOnlyOneBusiness
            ? [
                  {
                      path: BULK_UPDATE_PATH_TOKEN,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <BulkUpdatePage />
                          </ProtectedRoute>
                      ),
                      children: [
                          {
                              path: BULK_UPDATE_EXPORT_PATH_TOKEN,
                              element: (
                                  <ProtectedRoute userRole={meRole}>
                                      <BulkUpdatePage />
                                  </ProtectedRoute>
                              ),
                          },
                      ],
                  },
              ]
            : []),

        {
            path: '*',
            element: <Navigate replace to={NOT_FOUND_PATH} />,
        },
    ];

    return useRoutes(routes);
};

export default VisibilityRoutes;
