import { ReviewObject } from 'app/api/types/review';
import { Comments, formatComments } from 'app/states/comments';
import { getStateId } from 'app/states/reviews';

export const extractComments = (reviews: ReviewObject[]): Comments =>
    reviews.reduce(
        (comments, review) => ({
            ...comments,
            ...{
                [getStateId(review)]: formatComments(review.comments),
            },
        }),
        {},
    );
