import { AccountPartner } from 'app/api/types/account';

import ConnectedButton from 'app/businessConnection/components/common/ConnectedButton';
import ConnectionButton from 'app/businessConnection/components/common/ConnectionButton';

type Props = {
    partner: AccountPartner;
    businessId: string;
    locationId: string | null;
};

const LocationRowItem = ({ partner, businessId, locationId }: Props) => (
    <>
        {locationId ? (
            <ConnectedButton partner={partner} locationId={locationId} businessId={businessId} />
        ) : (
            <ConnectionButton partner={partner} loading={!businessId} businessId={businessId} />
        )}
    </>
);

export default LocationRowItem;
