import { useMutation } from 'react-query';

import {
    CreateWidgetSetupResponse,
    MessagingWidgetSetup,
} from 'app/api/v2/api_calls/messagingWidgetSetupApiCalls';

interface UpdateWidgetSetupAvatarData {
    id: string;
    avatar: File;
}

export const useWidgetSetupAvatarUpload = () => {
    return useMutation<CreateWidgetSetupResponse, unknown, UpdateWidgetSetupAvatarData>(
        ({ id, avatar }) => MessagingWidgetSetup.uploadAvatar(id, avatar),
    );
};
