import { FC } from 'react';

import { useTranslation } from 'react-i18next';

import pictoUrl from 'app/assets/whatsapp/mobile_onboarding_picto_2.png';

import {
    CenteredBody,
    CenteredText,
    CenteredTitle,
    ClosePicto,
    PageWrapper,
} from './WhatsappAccountImport.styled';

export const WhatsappAccountImportSuccessPage: FC = () => {
    const { t } = useTranslation();

    return (
        <PageWrapper>
            <CenteredBody>
                <ClosePicto src={pictoUrl} />
                <CenteredTitle as={'div'} variant="heading2">
                    {t('whatsapp_mobile_onboarding_success_title')}
                </CenteredTitle>
                <CenteredText as={'div'} variant="bodyLRegular">
                    {t('whatsapp_mobile_onboarding_success_text_1')}{' '}
                    <b>{t('whatsapp_mobile_onboarding_success_text_2')}</b>{' '}
                    {t('whatsapp_mobile_onboarding_success_text_3')}
                </CenteredText>
            </CenteredBody>
        </PageWrapper>
    );
};
