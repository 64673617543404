import { useHasMessagePage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasMessagePage';
import { SmallTodoProps } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent/DefaultTodoComponent';
import { TodoMessages } from 'app/pages/Homepage/sections/HomepageTodos/todos/TodoMessages';
import { Item } from 'app/pages/Homepage/utils/typing';

export const useTodoMessagesItem = (): Item<SmallTodoProps> => {
    const isAvailable = !!useHasMessagePage();

    return {
        order: 1,
        isAvailable,
        component: TodoMessages,
        key: 'todo-messages',
    };
};
