import { useDispatch } from 'react-redux';

import { GroupValue } from 'app/common/components/businessModal/context/businessModalFiltersReducer';

import {
    setSelectedBusinessIdsModalActions,
    setSelectedGroupIdsModalActions,
} from '../context/businessModalCustomActions';

export const useBusinessModalCustomActions = () => {
    const dispatch = useDispatch();

    const setBusinessModalBusinesses = (businessIds: Array<string>) =>
        dispatch(setSelectedBusinessIdsModalActions(businessIds));

    const setBusinessModalGroups = (groups: Array<GroupValue>) =>
        dispatch(setSelectedGroupIdsModalActions(groups));

    return {
        setBusinessModalBusinesses,
        setBusinessModalGroups,
    };
};
