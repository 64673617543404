import { ReviewObject } from 'app/api/types/review';
import {
    activeBusinessSelectionSelector,
    filterByBusinessSelector,
} from 'app/common/components/businessModalDeprecated/reducers';
import { AppState } from 'app/states/reducers';
import { activeReviewFiltersSelector, reviewFiltersSelector } from 'app/states/reviews/filters';

import { ReviewsState } from '../reducers';

import { filterReviews } from '../services/filterReviews';

export const reviewsSelector = (state: AppState): ReviewsState => state.reviews;

export const reviewsListSelector = (state: ReviewsState): Array<ReviewObject> => {
    const reviewsById = state.byId;
    const reviewsIds = state.ids;
    return reviewsIds.map(id => reviewsById[id]);
};

export const reviewsMatchingFiltersSelector = (state: AppState): Array<ReviewObject> => {
    const reviews = reviewsSelector(state);
    const reviewFilters = reviewFiltersSelector(state);
    const filterByBusiness = filterByBusinessSelector(state);
    return filterReviews(
        reviewsListSelector(reviews),
        activeReviewFiltersSelector(reviewFilters),
        activeBusinessSelectionSelector(filterByBusiness),
    );
};
