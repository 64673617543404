import { FC, useCallback, useEffect, useState } from 'react';

import { Button, IconButton, Stack, Text, theme } from '@partoohub/ui';
import { UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BusinessConnectedChannels } from 'app/api/types/business';
import messengerLogoUrl from 'app/assets/partooChat/messenger_logo.svg';
import smsLogoUrl from 'app/assets/partooChat/sms_logo.svg';
import whatsappLogoUrl from 'app/assets/partooChat/whatsapp_logo.svg';

import {
    CenteredText,
    IconContainer,
    InfoBubbleContainer,
} from 'app/common/components/SelectableCard/SelectableCard.styled';
import {
    SETTINGS_MESSAGES_WHATSAPP_PATHNAME,
    SETTINGS_PLATFORMS_CONNECTION_PATHNAME,
} from 'app/routing/routeIds';

import { ChannelSelectorCard } from './ChannelsSelector.styled';
import { useBusinessConnectedChannels } from '../../../hooks/useBusinessConnectedChannels';
import { ActiveChannelsFormValues, WidgetSetupFormValues } from '../../../WidgetSetup';

const validateChannels = (active_channels: ActiveChannelsFormValues) => {
    return active_channels.sms || active_channels.whatsapp || active_channels.messenger;
};

interface Props extends UseControllerProps<WidgetSetupFormValues> {
    selectedBusinessId?: string;
}

const noBusinessConnectedChannels: BusinessConnectedChannels = {
    sms: true,
    whatsapp: true,
    messenger: true,
};

export const ChannelsSelector: FC<Props> = ({ control, selectedBusinessId }) => {
    const navigate = useNavigate();
    const {
        field: { onChange, value: activeChannels },
        fieldState: { error },
    } = useController({
        name: 'active_channels',
        control,
        rules: { validate: validateChannels },
    });

    const { data: connectedChannels, isLoading: isConnectedChannelsLoading } =
        useBusinessConnectedChannels(selectedBusinessId, { enabled: !!selectedBusinessId });

    const [stableConnectedChannels, setStableConnectedChannels] =
        useState<BusinessConnectedChannels>(connectedChannels || noBusinessConnectedChannels);
    useEffect(() => {
        if (connectedChannels && connectedChannels !== stableConnectedChannels) {
            setStableConnectedChannels(connectedChannels);
        } else if (!connectedChannels && !isConnectedChannelsLoading) {
            setStableConnectedChannels(noBusinessConnectedChannels);
        }
    }, [
        connectedChannels,
        stableConnectedChannels,
        setStableConnectedChannels,
        isConnectedChannelsLoading,
    ]);

    const { t } = useTranslation();
    const canSetupSms = true;

    useEffect(() => {
        if (isConnectedChannelsLoading) {
            return;
        }

        if (
            (activeChannels.whatsapp && !stableConnectedChannels.whatsapp) ||
            (activeChannels.messenger && !stableConnectedChannels.messenger)
        ) {
            onChange({
                sms: activeChannels.sms,
                whatsapp: activeChannels.whatsapp && stableConnectedChannels.whatsapp,
                messenger: activeChannels.messenger && stableConnectedChannels.messenger,
            });
        }
    }, [onChange, isConnectedChannelsLoading, activeChannels, stableConnectedChannels]);

    const handleSmsClick = useCallback(
        (isCardSelected: boolean) => {
            onChange({ ...activeChannels, sms: isCardSelected });
        },
        [onChange, activeChannels],
    );

    const handleWhatsappClick = useCallback(
        (isCardSelected: boolean) => {
            if (stableConnectedChannels.whatsapp) {
                onChange({ ...activeChannels, whatsapp: isCardSelected });
            }
        },
        [onChange, activeChannels, stableConnectedChannels],
    );

    const handleMessengerClick = useCallback(
        (isCardSelected: boolean) => {
            if (stableConnectedChannels.messenger) {
                onChange({ ...activeChannels, messenger: isCardSelected });
            }
        },
        [onChange, activeChannels, stableConnectedChannels],
    );

    const handleConnectWhatsappClick = useCallback(() => {
        navigate(SETTINGS_MESSAGES_WHATSAPP_PATHNAME);
    }, [navigate, SETTINGS_MESSAGES_WHATSAPP_PATHNAME]);

    const handleConnectMessengerClick = useCallback(() => {
        navigate(SETTINGS_PLATFORMS_CONNECTION_PATHNAME);
    }, [navigate, SETTINGS_PLATFORMS_CONNECTION_PATHNAME]);

    return (
        <Stack direction="column" gap="16px">
            <Stack direction="row" gap="16px" wrap="wrap">
                <ChannelSelectorCard
                    isSelected={activeChannels.sms}
                    onChange={handleSmsClick}
                    disabled={!canSetupSms}
                    isConnected={true}
                >
                    <IconContainer as="div">
                        <img src={smsLogoUrl} />
                    </IconContainer>
                    <CenteredText variant="bodyLBold">
                        {t('widget_setup_channels_sms_title')}
                    </CenteredText>
                    <CenteredText color="secondary">
                        {t('widget_setup_channels_sms_description')}
                    </CenteredText>
                    {!canSetupSms && (
                        <InfoBubbleContainer isSelected={activeChannels.sms}>
                            <IconButton
                                dataTrackId="widget_setup__channel_sms_info_bubble"
                                icon={['fa-circle-info']}
                                appearance="outlined"
                                size="xsmall"
                                tooltip={t('widget_setup_channels_sms_error')}
                            />
                        </InfoBubbleContainer>
                    )}
                </ChannelSelectorCard>
                <ChannelSelectorCard
                    isSelected={activeChannels.whatsapp}
                    onChange={handleWhatsappClick}
                    isConnected={!!stableConnectedChannels.whatsapp}
                >
                    <IconContainer as="div">
                        <img src={whatsappLogoUrl} />
                    </IconContainer>
                    <CenteredText variant="bodyLBold">
                        {t('widget_setup_channels_whatsapp_title')}
                    </CenteredText>
                    {stableConnectedChannels.whatsapp ? (
                        <CenteredText color="secondary">
                            {t('widget_setup_channels_whatsapp_description')}
                        </CenteredText>
                    ) : (
                        <>
                            <Button
                                appearance="outlined"
                                dataTrackId={'widget_setup__channel_whatsapp_connect_button'}
                                onClick={handleConnectWhatsappClick}
                            >
                                {t('widget_setup_channels_connect_channel')}
                            </Button>
                            <InfoBubbleContainer isSelected={activeChannels.whatsapp}>
                                <IconButton
                                    dataTrackId="widget_setup__channel_whatsapp_info_bubble"
                                    icon={['fa-circle-info']}
                                    appearance="outlined"
                                    size="xsmall"
                                    tooltip={t('widget_setup_channels_whatsapp_error')}
                                />
                            </InfoBubbleContainer>
                        </>
                    )}
                </ChannelSelectorCard>
                <ChannelSelectorCard
                    isSelected={activeChannels.messenger}
                    onChange={handleMessengerClick}
                    isConnected={!!stableConnectedChannels.messenger}
                >
                    <IconContainer as="div">
                        <img src={messengerLogoUrl} />
                    </IconContainer>
                    <CenteredText variant="bodyLBold">
                        {t('widget_setup_channels_messenger_title')}
                    </CenteredText>

                    {stableConnectedChannels.messenger ? (
                        <CenteredText color="secondary">
                            {t('widget_setup_channels_messenger_description')}
                        </CenteredText>
                    ) : (
                        <>
                            <Button
                                appearance="outlined"
                                dataTrackId={'widget_setup__channel_messenger_connect_button'}
                                onClick={handleConnectMessengerClick}
                            >
                                {t('widget_setup_channels_connect_channel')}
                            </Button>
                            <InfoBubbleContainer isSelected={activeChannels.messenger}>
                                <IconButton
                                    dataTrackId="widget_setup__channel_messenger_info_bubble"
                                    icon={['fa-circle-info']}
                                    appearance="outlined"
                                    size="xsmall"
                                    tooltip={t('widget_setup_channels_messenger_error')}
                                />
                            </InfoBubbleContainer>
                        </>
                    )}
                </ChannelSelectorCard>
            </Stack>
            {error && (
                <Text color={theme.colors.danger.initial}>{t('widget_setup_channels_error')}</Text>
            )}
        </Stack>
    );
};
