import { useMemo, useState } from 'react';

import { Modal } from '@partoohub/ui';
import { useForm } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';

import businessCreationErrorsFormatter from 'app/api/formatters/response/businessCreationErrorsFormatter';
import { BusinessCreationRequestData, CreationSuccessResponses } from 'app/api/types/business';

import { trackSaveBusinessCreation } from 'app/businessEditV2/utils/tracking';
import { getNumberOfAdditionalCategories, invalidatesAny } from 'app/businessEditV2/utils/utils';
import LoadingView from 'app/common/components/LoadingView';
import { BUSINESSES } from 'app/common/data/queryKeysConstants';
import useGroupsV2, { hasAnyGroup } from 'app/common/hooks/queries/useGroupsV2';
import useNewGroups from 'app/common/hooks/queries/useNewGroups';
import { useBusinessCreate } from 'app/common/hooks/useBusinessCreate';
import {
    BusinessCreateFormValues,
    formatBusinessCreatePayload,
} from 'app/pages/visibility/BusinessCreate/utils/businessCreateForm';

import { VISIBILITY_LOCATION_PATH } from 'app/routing/routeIds';
import queryClient from 'app/states/queryClient';

import AddressSection from './AddressSection';
import BusinessCreateSaveButton from './BusinessCreateSaveButton';
import ConfirmAddressModal from './ConfirmAddressModal';
import MainInfoSection from './MainInfoSection';
import MaxBusinessReachedModal from './MaxBusinessReachedModal';
import NewGroupsSection from './NewGroupSection';
import useBusinessCalculateCompletionRate from '../../hooks/useBusinessCalculateCompletionRate';

const MAX_BUSINESSES_LIMIT_ERROR = 'max_businesses_limit_reached';

const BusinessCreateContent = () => {
    const [showAddressConfirmModal, setShowAddressConfirmModal] = useState<boolean>(false);
    const [showMaxBusinessModal, setShowMaxBusinessModal] = useState<boolean>(false);
    const navigate = useNavigate();
    const calculateCompletionRate = useBusinessCalculateCompletionRate();

    const onSuccess = async (response: CreationSuccessResponses) => {
        await calculateCompletionRate.mutateAsync(response.business_id).then(() => {
            queryClient.resetQueries(invalidatesAny(BUSINESSES)).then(() => {
                navigate(`${VISIBILITY_LOCATION_PATH}/${response.business_id}`);
            });
        });
    };
    const businessCreate = useBusinessCreate(onSuccess, error => {
        setShowAddressConfirmModal(false);
        if (error?.response?.data?.errors?.error === MAX_BUSINESSES_LIMIT_ERROR) {
            setShowMaxBusinessModal(true);
        }
    });

    const enableNewGroups = useNewGroups();
    const { query, sections } = useGroupsV2({}, { enabled: enableNewGroups });

    const setFormValue = (): BusinessCreateFormValues => ({
        name: '',
        code: '',
        phoneNumber: '',
        categories: [],
        openingDate: '',
        addressFull: '',
        addressComplement: '',
        zipcode: '',
        city: null,
        country: {
            value: 'FR',
            label: 'France',
        },
        groups: [],
        manualLat: null,
        manualLong: null,
    });

    const businessCreateForm = useForm<BusinessCreateFormValues>({
        defaultValues: useMemo(() => setFormValue(), []),
    });

    // @ts-ignore
    const errors = businessCreate.error?.response?.data?.errors?.json;
    const errorObject = errors ? businessCreationErrorsFormatter(errors) : {};

    // Form submission
    const onSubmit = (data: BusinessCreationRequestData) => {
        // GTM tracking for additional categories
        trackSaveBusinessCreation(getNumberOfAdditionalCategories(data.categories));

        businessCreate.mutate(data);
    };

    const submitForm = () => {
        businessCreateForm.handleSubmit((data: BusinessCreateFormValues) => {
            if (data.manualLat && data.manualLong) {
                onSubmit(formatBusinessCreatePayload(data, enableNewGroups));
            } else {
                setShowAddressConfirmModal(true);
            }
        })();
    };

    // Pin confirmation in the modal
    const confirmAddress = (lat: number, long: number) => {
        businessCreateForm.handleSubmit((data: BusinessCreateFormValues) =>
            onSubmit(
                formatBusinessCreatePayload(
                    {
                        ...data,
                        manualLat: lat,
                        manualLong: long,
                    },
                    enableNewGroups,
                ),
            ),
        )();
    };

    if (enableNewGroups === undefined || query.isLoading) {
        return <LoadingView />;
    }

    return (
        <>
            <MainInfoSection form={businessCreateForm} errors={errorObject} />

            {enableNewGroups && hasAnyGroup(sections) && (
                <NewGroupsSection name="groups" control={businessCreateForm.control} />
            )}

            <AddressSection form={businessCreateForm} errors={errorObject} />

            <BusinessCreateSaveButton
                isBusinessCreateLoading={businessCreate.isLoading}
                hasErrors={!!errors}
                submitForm={submitForm}
                watch={businessCreateForm.watch}
            />

            {/* Modal to confirm the pin location if the autocomplete has not been used */}
            <Modal
                isOpen={showAddressConfirmModal}
                closeModal={() => setShowAddressConfirmModal(false)}
            >
                <ConfirmAddressModal
                    watch={businessCreateForm.watch}
                    confirmAddress={confirmAddress}
                />
            </Modal>

            <Modal isOpen={showMaxBusinessModal} closeModal={() => setShowMaxBusinessModal(false)}>
                <MaxBusinessReachedModal closeModal={() => setShowMaxBusinessModal(false)} />
            </Modal>
        </>
    );
};

export default BusinessCreateContent;
