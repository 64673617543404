import styled from '@emotion/styled';
import { Image, theme } from '@partoohub/ui';

export const AvatarPickerImageInput = styled('input')`
    display: none;
`;

export const AvatarPickerImage = styled(Image)`
    border-radius: ${theme.radius.circle};
`;
