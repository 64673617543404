import { useTranslation } from 'react-i18next';

import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { CONVERSATION_ROUTING } from 'app/common/data/featureFlags';
import { useHasChatbotPage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasChatbotPage';
import useHasWhatsappAccess from 'app/pages/settings/Whatsapp/hooks/useHasWhatsappAccess';
import { useIsConversationStartersPageEnabled } from 'app/pages/settingsV2/hooks/Messages/pagesEnabled/useIsConversationStartersPageEnabled';
import { SettingMenuItemsData } from 'app/pages/settingsV2/subComponents/SettingsMenuList/SettingsMenuList';
import {
    SETTINGS_MESSAGES_CHATBOTS_PATHNAME,
    SETTINGS_MESSAGES_CONVERSATION_ROUTER_PATHNAME,
    SETTINGS_MESSAGES_CONVERSATION_STARTERS_PATHNAME,
    SETTINGS_MESSAGES_PATHNAME,
    SETTINGS_MESSAGES_REPLY_TEMPLATES_PATHNAME,
    SETTINGS_MESSAGES_WHATSAPP_PATHNAME,
    SETTINGS_MESSAGES_WIDGET_SETUP_ADD_PATHNAME,
    SETTINGS_MESSAGES_WIDGET_SETUP_WIDGET_PATHNAME,
} from 'app/routing/routeIds';

import { useCanAccessWidgetSetupMenu } from './useCanAccessWidgetSetupMenu';
import { useMessagingWidgetSetupIds } from './useWidgetSetup';

export const useSettingMessagesMenu = (): SettingMenuItemsData => {
    const { t } = useTranslation();
    const isConversationStartersPageEnabled = useIsConversationStartersPageEnabled();
    const hasChatbotPage = useHasChatbotPage();
    const hasWhatsappAccess = useHasWhatsappAccess();
    const conversationRoutingFeatureFlag = useFeatureFlag(CONVERSATION_ROUTING);

    const { widgetIds, isLoading: isWidgetIdsLoading } = useMessagingWidgetSetupIds();
    const canAccessWidgetSetupMenu =
        useCanAccessWidgetSetupMenu() && !isWidgetIdsLoading && widgetIds && widgetIds.length < 2;
    const widgetSetupMenuPathname =
        !widgetIds || widgetIds?.length === 0
            ? SETTINGS_MESSAGES_WIDGET_SETUP_ADD_PATHNAME
            : SETTINGS_MESSAGES_WIDGET_SETUP_WIDGET_PATHNAME + widgetIds[0];

    return {
        menus: [
            {
                label: t('settings_menu_messages'),
                pathname: SETTINGS_MESSAGES_PATHNAME,
                options: [
                    {
                        name: 'reply-templates',
                        value: 'reply-templates',
                        label: t('settings_menu_messages_reply_templates'),
                        pathname: SETTINGS_MESSAGES_REPLY_TEMPLATES_PATHNAME,
                        display: true,
                    },
                    {
                        name: 'conversation-starters',
                        value: 'conversation-starters',
                        label: t('settings_menu_messages_automatic_messages'),
                        pathname: SETTINGS_MESSAGES_CONVERSATION_STARTERS_PATHNAME,
                        display: isConversationStartersPageEnabled,
                    },
                    {
                        name: 'chatbots',
                        value: 'chatbots',
                        label: t('settings_menu_messages_chatbot'),
                        pathname: SETTINGS_MESSAGES_CHATBOTS_PATHNAME,
                        display: hasChatbotPage,
                    },
                    {
                        name: 'whatsapp',
                        value: 'whatsapp',
                        label: t('settings_menu_messages_whatsapp'),
                        pathname: SETTINGS_MESSAGES_WHATSAPP_PATHNAME,
                        display: hasWhatsappAccess,
                    },
                    {
                        name: 'conversation-router',
                        value: 'conversation-router',
                        label: t('settings_menu_messages_conversation_router'),
                        pathname: SETTINGS_MESSAGES_CONVERSATION_ROUTER_PATHNAME,
                        display:
                            hasChatbotPage && hasWhatsappAccess && conversationRoutingFeatureFlag,
                    },
                    {
                        name: 'widget-setup',
                        value: 'widget-setup',
                        label: t('settings_menu_messages_widget_setup'),
                        pathname: widgetSetupMenuPathname,
                        display: canAccessWidgetSetupMenu,
                    },
                ],
            },
        ],
        isLoading: false,
    };
};
