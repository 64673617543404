import { FEATURE_ENUM } from 'app/api/types/plans_features';
import { USE_USER_SIDEBAR_FEATURES } from 'app/common/data/featureFlags';
import { ORG_ADMIN } from 'app/common/data/roles';
import { useHasMessagePage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasMessagePage';
import { useHasPage } from 'app/common/hooks/accessesAndPermissions/useHasPage';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import env from 'app/common/services/getEnvironment';

export const useHasChatbotPage = (): boolean | undefined => {
    const { data: me } = useMe();

    const hasMessagePage = useHasMessagePage();
    const hasChatbot = useHasPage(FEATURE_ENUM.JIM);

    const hasChatbotFeature =
        env.isSandbox() ||
        (USE_USER_SIDEBAR_FEATURES ? hasChatbot : !me?.sidebar_excluded_pages.includes('jim'));

    const hasCorrectRole = me?.role === ORG_ADMIN;

    return hasMessagePage && hasChatbotFeature && hasCorrectRole;
};
