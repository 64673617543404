import { useState } from 'react';

import { Button, Modal, Option, SingleSelect } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { V2BusinessData } from 'app/api/types/business';

import { useGetBrands } from 'app/common/hooks/queries/useGetBrands';

import { CreationModal } from './CreationModal/CreationModal';

interface BrandCreatorProps {
    onChange?: (value: string) => void;
}

export const BrandSelector = ({ onChange }: BrandCreatorProps) => {
    const { t } = useTranslation();
    const [selectedValue, setSelectedValue] = useState<Option | undefined>(undefined);
    const [showModal, setShowModal] = useState<boolean>(false);
    const { query, businesses: brands } = useGetBrands();

    const handleChange = (brandOption: Option | undefined) => {
        if (!brandOption) return;

        setSelectedValue(brandOption);
        onChange?.(brandOption.value);
    };

    const handleClick = () => {
        setShowModal(true);
    };
    const closeModal = () => {
        setShowModal(false);
    };

    const options = brands?.map((brand: V2BusinessData) => ({
        label: brand.name,
        name: brand.name,
        value: brand.id,
    }));

    return (
        <>
            <SingleSelect
                dataTrackId=""
                onChange={handleChange}
                label={t('brand_selection_input') + '*'}
                sections={options ? [{ options }] : []}
                selectedValue={selectedValue}
                hasMore={query.hasNextPage}
                loadMore={query.fetchNextPage}
            />
            <Button dataTrackId={'brand_selection_create'} onClick={handleClick}>
                {t('brand_selection_create')}
            </Button>
            <Modal isOpen={showModal} closeModal={closeModal}>
                <CreationModal closeModal={closeModal} />
            </Modal>
        </>
    );
};
