import { ReactNode } from 'react';

import { Button, ButtonVariants, IconElement, Text, Tooltip } from '@partoohub/ui';

type Props = {
    text: string | ReactNode;
    onClick: () => void;
    color?: ButtonVariants;
    icon?: IconElement;
    disabled?: boolean;
    textTooltip?: string;
};

const StatusButton = ({
    text,
    color = 'primary',
    disabled = false,
    icon,
    onClick,
    textTooltip,
}: Props) => {
    const button = (
        <Button
            dataTrackId="status_button"
            size="medium"
            shape="cube"
            variant={color}
            disabled={disabled}
            icon={icon}
            onClick={onClick}
            appearance="outlined"
            full
        >
            <Text variant="bodyMBold" as="span" oneLine>
                {text}
            </Text>
        </Button>
    );

    if (textTooltip) {
        return (
            <Tooltip
                id="status_button_tooltip"
                key={textTooltip}
                text={textTooltip}
                caret={false}
                placement="bottom"
            >
                {button}
            </Tooltip>
        );
    }

    return button;
};

export default StatusButton;
