import { useRef } from 'react';

import { Button, Stack, toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import defaultImageUrl from 'app/assets/partooChat/default-avatar.png';

import { AvatarPickerImage, AvatarPickerImageInput } from './AvatarPicker.styled';

interface AvatarPickerProps {
    avatarUrl?: string;
    sizeLimit?: number;
    onChange?: (avatarUrl: string) => void;
}

const DEFAULT_MAX_SIZE = 250 * 1024; // 250kb

export const AvatarPicker = ({
    avatarUrl,
    sizeLimit = DEFAULT_MAX_SIZE,
    onChange,
}: AvatarPickerProps) => {
    const ref = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();

    const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const imageFile = e.target?.files?.[0];

        if (!imageFile) return;

        if (imageFile.size > sizeLimit) {
            toast.error(
                t('widget_setup_avatar_picker_too_big_error_description'),
                t('widget_setup_avatar_picker_too_big_error_title'),
            );
            return;
        }

        onChange?.(URL.createObjectURL(imageFile));
    };

    const handleOpenFileUpload = () => {
        ref.current?.click();
    };

    return (
        <Stack gap="8px" alignItems="center" justifyContent="center">
            <AvatarPickerImage src={avatarUrl || defaultImageUrl} width={128} height={128} />
            <AvatarPickerImageInput
                ref={ref}
                type="file"
                accept="image/*"
                onChange={handleImageChange}
            />
            <Button
                dataTrackId="settings_messages_widget_setup__avatar_picker_button"
                onClick={handleOpenFileUpload}
                type="button"
                variant="secondary"
                appearance="outlined"
            >
                {t('widget_setup_avatar_picker_button')}
            </Button>
        </Stack>
    );
};
