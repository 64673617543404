import { HEX_COLORS } from '@partoohub/branding';
import { Icon, IconPrefix, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useFormatNumber } from 'app/common/hooks/useFormatNumber';
import { IS_BELOW_TABLET, useDeviceType } from 'app/hooks';

import {
    BadgeCount,
    DoughnutLegendRowContainer,
    DoughnutLegendRowTitle,
} from './DoughnutLegendRow.styled';
import { getStatusObject } from '../../DiffusionPublishers/PublisherTable/helpers/helpers';
import { PublisherIcon } from '../../DiffusionPublishers/PublisherTable/PublisherIcon/PublisherIcon';

interface Props {
    label: string;
    count: string;
    isLoading: boolean;
}

export const DoughnutLegendRow = ({ label, count, isLoading }: Props) => {
    const { t } = useTranslation();
    const formattedCount = useFormatNumber(+count);
    const statusObject = getStatusObject(label);
    const { isBelowProvidedDevices } = useDeviceType(IS_BELOW_TABLET);
    return (
        <DoughnutLegendRowContainer
            gap="8px"
            justifyContent="flex-start"
            data-track={`visibility_diffusion__graph_legend_${label}`}
            data-intercom-target={`visibility_diffusion__graph_legend_${label}`}
        >
            <DoughnutLegendRowTitle direction="row" justifyContent="flex-start">
                <PublisherIcon {...getStatusObject(label)} />
                <Text variant={isBelowProvidedDevices ? 'bodyMBold' : 'heading6'} as="span">
                    {label === 'success' ? t('updated_or_transmitted') : t(label)}
                </Text>
                <BadgeCount badgeColor={statusObject.color}>
                    {isLoading ? (
                        <Icon
                            icon={['fa-circle-notch', IconPrefix.SOLID]}
                            color={HEX_COLORS.grey}
                            className="fa-spin"
                        />
                    ) : (
                        <Text as="span" variant="bodyMBold" color={statusObject.color}>
                            {formattedCount}
                        </Text>
                    )}
                </BadgeCount>
            </DoughnutLegendRowTitle>
            {!isBelowProvidedDevices && (
                <Stack style={{ padding: '0 40px' }}>
                    <Text as="span" variant="bodyMRegular" color="secondary">
                        {label === 'success'
                            ? t('transmitted_listing_description')
                            : t(`${label}_listing_description`)}
                    </Text>
                </Stack>
            )}
        </DoughnutLegendRowContainer>
    );
};
