import getLang from 'app/common/services/getLang';

export const numberFormatter = (value: number, locale?: string, humanize = false) => {
    const options: Intl.NumberFormatOptions = {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: humanize ? 1 : 6,
    };

    if (humanize) {
        options.notation = 'compact';
    }

    const formatted = String(
        `${new Intl.NumberFormat(locale ?? getLang(), options).format(value)}`,
    ).replaceAll('\u202F', '\u00A0');

    return formatted;
};
