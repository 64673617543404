import { Stack } from '@partoohub/ui';

import BusinessModalButton from 'app/common/components/businessModal';

import { FilterBusinessModalButton } from 'app/common/components/businessModal/components/FilterBusinessModalButton/FilterBusinessModalButton';

import { DateFilter, TagFilter } from './Filters';

export const FilterSection = () => {
    return (
        <Stack direction="row" alignItems="center" gap="8px">
            <BusinessModalButton
                key="business-modal-key"
                resetReducerOnUnmount={false}
                button={
                    <div>
                        <FilterBusinessModalButton />
                    </div>
                }
            />
            <DateFilter />
            <TagFilter />
        </Stack>
    );
};
