import { CommentData } from 'app/api/types/comments';
import { InteractionTagType } from 'app/api/types/interactionTag';
import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';
import { LanguageCodeType } from 'app/common/services/languages';

import { FeedbackFormFieldType } from './feedbackResults';

export type Keywords = {
    keywords?: Array<string>;
};

export type FrontendReviewState =
    | 'treated'
    | 'not-treated'
    | 'deleted'
    | 'pending_auto_reply'
    | 'treated_auto_reply';

export type LiteralRating = 'positive' | 'neutral' | 'negative';

export type CursorPaginationParams = {
    cursor?: number;
    next_cursor?: string;
    per_page?: number;
};

export type UpdateReviewParams = {
    id: number;
    state: 'treated' | 'not_treated';
};

export type ReviewQueryParams = {
    update_date__gte?: string | null;
    update_date__lte?: string | null;
    content__isnull?: boolean | null;
    gmb_rating__in?: number[] | null;
    tripadvisor_rating__in?: number[] | null;
    recommended__in?: boolean[] | null;
    partner__in?: string | null;
    partner?: string | null;
    state__in?: string[] | null;
    keywords?: string | null;
    tags?: string | null;
    without_tag?: boolean | null;
} & BusinessModalFiltersType;

export type ReviewEvolutionQueryParams = ReviewQueryParams & {
    cumulative: boolean;
    bucket: string | null;
};

export type ReviewAlertsQueryParams = ReviewQueryParams & {
    has_comment_not_fetched: boolean;
    fields: string;
};

export type ReviewStatsResponse = {
    average: number;
    satisfied: number;
    neutral: number;
    dissatisfied: number;
    answer_time: number;
    not_treated: number;
    total: number;
    treated: number;
    answered: number;
};

export enum ReviewDataState {
    TREATED = 'treated',
    NOT_TREATED = 'not_treated',
    DELETED = 'deleted',
    PENDING_AUTO_REPLY = 'pending_auto_reply',
    TREATED_AUTO_REPLY = 'treated_auto_reply',
}

export type ReviewData = {
    author_name: string | null;
    business_id: string;
    id: number;
    created: number;
    date: string;
    update_date: string;
    key: string;
    org_id: number;
    partner: string;
    partner_id: string;
    rating: number | null;
    state: ReviewDataState;
    comments: Array<CommentData>;
    tags: Array<InteractionTagType>;
    content?: string;
    delete_date?: string;
    recommended?: boolean;
    link?: string;
    title?: string;
    pending_auto_reply?: {
        content: string;
        scheduled_at: string;
    };
};

export type ReceivedReviewData = {
    reviews: Array<ReviewData>;
    count: number;
    max_page: number;
    page: number;
};

export type ReviewAnalyticsData = {
    date: string;
    cum_sum: number;
    added: number;
    previous_sum: number;
};

export type SearchTemplatesParams = {
    page?: number;
    language?: Array<LanguageCodeType>;
    title_query?: string;
    text_query?: string;
    positive?: boolean;
    negative?: boolean;
    neutral?: boolean;
    language__in?: Array<string>;
    is_auto_reply?: boolean;
    order_by?: string;
};

export type TemplatesStatsResponse = {
    count: number;
    positive: number;
    neutral: number;
    negative: number;
};

export enum ReviewObjectType {
    REVIEW = 'review',
    FEEDBACK_RESULT = 'feedback_result',
}

export type ReviewObject = {
    reviewObjectType: ReviewObjectType;
    authorName: string | null;
    businessId: string;
    content: string | null;
    date: number;
    updateDate: number;
    deleteDate: number | null;
    recommended: boolean;
    id: number;
    key: string;
    lang: string | null;
    link: string | null;
    orgId: number;
    partner: string;
    partnerId: string;
    rating: number;
    replyComment: string;
    state: FrontendReviewState;
    title: string | null;
    seqUnfoundCount: number;
    tags: Array<InteractionTagType>;
    pendingAutoReply: {
        content: string;
        scheduledAt: string;
    } | null;
    comments: Array<CommentData>;
    details:
        | Array<{
              type: FeedbackFormFieldType;
              question: string;
              rating: number | null;
              content: string | null;
          }> // Feedback result fields
        | undefined;
};

export type ReviewFilters = {
    rating: Array<LiteralRating>;
    gmbRatingRange: [1 | 2 | 3 | 4 | 5, 1 | 2 | 3 | 4 | 5];
    tripAdvisorRatingRange: [1 | 2 | 3 | 4 | 5, 1 | 2 | 3 | 4 | 5];
    dateFrom: Date | null;
    dateTo: Date | null;
    platform: Array<'google_my_business' | 'facebook' | 'tripadvisor'>;
    message: Array<'withMessage' | 'withoutMessage'>;
    reviewState: string;
    tags: Array<string>;
};

export type ReviewBusinessFilter = {
    includedIds: Array<string> | null;
    excludedIds: Array<string> | null;
};

export type Placeholder = {
    id: number;
    label: string;
    value: string;
    path: string;
};

export const REVIEW_PERMISSION_ASSIGN_TAG = 'review_assign_tag';
export const REVIEW_PERMISSION_REPLY_SUGGESTION = 'review_reply_suggestion';
export const REVIEW_PERMISSION_CREATE_REPLY_TEMPLATE = 'review_create_reply_template';
export type ReviewPermissionName =
    | typeof REVIEW_PERMISSION_ASSIGN_TAG
    | typeof REVIEW_PERMISSION_REPLY_SUGGESTION
    | typeof REVIEW_PERMISSION_CREATE_REPLY_TEMPLATE;
export type ReviewPermissions = Record<ReviewPermissionName, boolean>;

export type ReviewTagStat = {
    interaction_tag: InteractionTagType;
    review_count: number;
    review_avg_rating: number;
};

export type ReplySuggestionReport = {
    id: number;
    user_id: string;
    created_at: string;
};

export type ReplySuggestion = {
    id: number;
    index: number;
    review_id: number;
    content: string;
    report: ReplySuggestionReport | null;
};
