import { useCallback } from 'react';

import { Navigate, useRoutes } from 'react-router-dom';

import { useAppDataContext } from 'app/common/contexts/appData';
import { useHasMessagePage } from 'app/common/hooks/accessesAndPermissions/pages/messages/useHasMessagePage';
import { MessagingPage } from 'app/pages/conversations/Messaging';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import {
    ANALYTICS_REVIEW_PATH,
    CONVERSATIONS_MESSAGING_PATH,
    MESSAGING_PATH_TOKEN,
    NOT_FOUND_PATH,
} from 'app/routing/routeIds';

const ConversationsRoutes = () => {
    const { me } = useAppDataContext();
    const meRole = me ? me.role : null;
    const hasMessagePage = useHasMessagePage();

    const getDefaultConversationsPath = useCallback(() => {
        if (!hasMessagePage) {
            return ANALYTICS_REVIEW_PATH;
        }

        return CONVERSATIONS_MESSAGING_PATH;
    }, []);

    const routes = [
        {
            index: true,
            element: <Navigate replace to={getDefaultConversationsPath()} />,
        },
        ...(hasMessagePage
            ? [
                  {
                      path: MESSAGING_PATH_TOKEN,
                      element: (
                          <ProtectedRoute userRole={meRole}>
                              <MessagingPage hasBusinessMessage={hasMessagePage} />
                          </ProtectedRoute>
                      ),
                  },
              ]
            : []),
        {
            path: '*',
            element: <Navigate replace to={NOT_FOUND_PATH} />,
        },
    ];

    return useRoutes(routes);
};

export default ConversationsRoutes;
