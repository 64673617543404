import { useMemo, useState } from 'react';

import {
    FontAwesomeIconsPartooUsed,
    IconButton,
    IconPrefix,
    Stack,
    Table,
    Text,
} from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { ReviewAnalyticsDimension } from 'app/api/types/review_analytics';

import { sumValues } from 'app/common/utils/sumValues';

import { createColumnConfig } from './common/createColumnConfig';
import { DimensionSwitch } from './common/DimensionSwitch';
import { PageNavigator } from './common/PageNavigator';
import { useHandleSort } from './hooks/useHandleSort';
import { useInternalRankingParameters } from './hooks/useInternalRankingParameters';
import { StyledCell, StyledHeader } from './Rankings.styled';
import { formatValue, generateTableColumns } from '../../../helper';
import {
    getApiPage,
    getDataForTablePage,
    useReplyMeansInternalRanking,
} from '../../../hooks/useMetrics';
import { ExportModal } from '../../ExportModal/ExportModal';

export const ReplyMeansRanking = () => {
    const [page, setPage] = useState(1);

    const { t } = useTranslation();
    const [openModal, setOpenModal] = useState(false);

    const { currentOrder, handleSort } = useHandleSort('ratio__reply_means__manual', 'desc');
    const orderBy =
        currentOrder.order === 'asc' ? currentOrder.columnId : `-${currentOrder.columnId}`;
    const metrics = useReplyMeansInternalRanking(getApiPage(page), orderBy);
    const metrics_data = metrics || { count: 0, data: [] };
    const data = useMemo(() => {
        return getDataForTablePage(metrics_data?.data ?? [], page);
    }, [metrics_data?.data, page]);

    const {
        dimension: [selectedDimension],
    } = useInternalRankingParameters();

    const columnsConfig = useMemo(() => {
        const baseColumns = [
            createColumnConfig('#', '#', '300px', false),
            createColumnConfig(
                'dimension_name',
                t(`review_analytics_rankings_${selectedDimension}`),
                '3000px',
            ),
            createColumnConfig(
                'ratio__reply_means__manual',
                t('review_analytics_rankings_reply_means_manual'),
                '1000px',
            ),
            createColumnConfig(
                'ratio__reply_means__reply_template',
                t('review_analytics_rankings_reply_means_reply_template'),
                '1000px',
            ),
            createColumnConfig(
                'ratio__reply_means__ai_suggestion',
                t('review_analytics_rankings_reply_means_ai_suggestion'),
                '1000px',
            ),
        ];

        if (selectedDimension !== 'user') {
            baseColumns.push(
                createColumnConfig(
                    'ratio__reply_means__auto_reply',
                    t('review_analytics_rankings_reply_means_auto_reply'),
                    '1000px',
                ),
            );
        }

        return baseColumns;
    }, [selectedDimension, t]);

    const tableColumns = generateTableColumns(currentOrder, handleSort, setPage, columnsConfig);

    return (
        <Stack>
            <StyledHeader>
                <Text variant="heading6">{t('review_analytics_internal_ranking')}</Text>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <DimensionSwitch />
                    <IconButton
                        dataTrackId="review_analytics__ranking_reply_means_download"
                        appearance="outlined"
                        icon={[FontAwesomeIconsPartooUsed.faArrowDownToLine, IconPrefix.REGULAR]}
                        tooltipPlacement="bottom"
                        tooltip={t('download_data')}
                        onClick={() => setOpenModal(true)}
                    />
                </Stack>
            </StyledHeader>
            <Table columns={tableColumns}>
                <Table.Body>
                    {data.map(({ dimension, dimension_name, metrics }) => {
                        const reviewCount = sumValues(metrics.reply_means) ?? 0;

                        const manual = formatValue(metrics.reply_means?.manual, reviewCount);
                        const template = formatValue(
                            metrics.reply_means?.reply_template,
                            reviewCount,
                        );
                        const auto = formatValue(metrics.reply_means?.auto_reply, reviewCount);
                        const ai = formatValue(metrics.reply_means?.ai_suggestion, reviewCount);

                        return (
                            <Table.Row
                                dataTrackId="review_analytics__ranking_reply_means"
                                id={dimension}
                                key={dimension}
                            >
                                <StyledCell>
                                    {reviewCount === 0 || metrics.rank == null
                                        ? 'NC'
                                        : metrics.rank}
                                </StyledCell>
                                <StyledCell align="left">{dimension_name}</StyledCell>
                                <StyledCell>{manual}</StyledCell>
                                <StyledCell>{template}</StyledCell>
                                <StyledCell>{ai}</StyledCell>
                                {selectedDimension === 'business' && (
                                    <StyledCell>{auto}</StyledCell>
                                )}
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
            <PageNavigator
                dataTrackIdKey="reply_means"
                dataCount={metrics_data.count}
                dataLength={metrics_data.data.length}
                page={page}
                setPage={setPage}
            />
            <ExportModal
                isOpenExportModal={openModal}
                setIsOpenExportModal={setOpenModal}
                dimensionType={selectedDimension as ReviewAnalyticsDimension}
            />
        </Stack>
    );
};
