export enum TaskStatus {
    PENDING = 'PENDING',
    STARTED = 'STARTED',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE',
    RETRY = 'RETRY',
    REVOKED = 'REVOKED',
}

export type TaskInfo = {
    id: string;
    status: TaskStatus;
    type: string;
    timestamp: number;
    title?: string;
    meta?: object;
};

export type OAuthAccountTaskInfo = {
    taskID?: string;
    oauthAccountId?: string;
    status: TaskStatus;
    progress?: number;
    total?: number;
    eventType: string;
};

export type CommonMeta = {
    progress?: number;
    total?: number;
    topic?: string;
    oauth_account_id?: string;
    business_id?: string;
    message?: string;
};
