import { useCallback, useEffect, useRef, useState } from 'react';

import { Chip, IconButton, IconPrefix, Tooltip } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import { AccountPartner, AccountPartnerName, V2FormattedAccountData } from 'app/api/types/account';
import api from 'app/api/v2/api_calls';
import AccountBadge from 'app/businessConnection/components/AccountsList/AccountBadge/AccountBadge';
import { AccountDisconnect } from 'app/businessConnection/components/AccountsList/AccountDisconnect/AccountDisconnect';
import { AnimatedCounter } from 'app/businessConnection/components/AccountsList/AccountRow/AnimatedCounter';
import { partnerToPartnerName } from 'app/businessConnection/services/PartnerToAccountsMethods';
import { useRefreshOAuthAccount } from 'app/common/contexts/taskListener/useRefreshOAuthAccount';
import { OAUTH_ACCOUNTS } from 'app/common/data/queryKeysConstants';
import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';

import {
    NotClickableContainer,
    RowContainer,
    RowInfoContainer,
    RowInfoCount,
    RowInfoEmail,
    RowInfoIcons,
    RowInfoName,
} from './AccountRow.styled';

type Props = {
    oauthAccount: V2FormattedAccountData;
};

const AccountRow = ({ oauthAccount }: Props) => {
    const { t } = useTranslation();
    const partner = oauthAccount.partner as AccountPartner;

    const [showModal, setShowModal] = useState(false);
    const partnerName = partnerToPartnerName[partner] as AccountPartnerName;

    const [isAnimating, setIsAnimating] = useState(false);

    const { data, isLoading: isFetchingDetails } = useQuery(
        [OAUTH_ACCOUNTS, oauthAccount.id, partner],
        () => api.account.getAccountDetails(oauthAccount.id, partner),
    );

    const handleAnimationComplete = () => {
        setIsAnimating(false);
    };

    const {
        isSuccess,
        isLoading,
        isStarted,
        isError,
        errorMessage,
        percentageProgress,
        setStartRefresh,
    } = useRefreshOAuthAccount({
        oauthAccountId: oauthAccount.id,
        partner,
        startRefreshAtMount: false,
        fetch_origin: 'connection_settings_account_refresh',
    });

    const prevPercentageProgress = useRef(0);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        setIsAnimating(isLoading || (isStarted && !isSuccess && !isError));

        if (!isLoading && (isSuccess || isError)) {
            timeout = setTimeout(handleAnimationComplete, 1000);
        }

        return () => clearTimeout(timeout);
    }, [isLoading, isStarted, isSuccess, isError]);

    useEffect(() => {
        prevPercentageProgress.current = percentageProgress;
        if (percentageProgress > 0) setIsAnimating(true);
    }, [percentageProgress]);

    const startRefresh = useCallback(() => {
        setStartRefresh(true);
    }, []);

    const disconnectAccount = useCallback(() => {
        setShowModal(true);
    }, []);

    const handleClick = useCallback(() => undefined, []);

    const locationsCount = data?.locations_count || 0;
    const accountsCount = data?.accounts_count || 0;
    const status_is_connected = data?.status === 'OK';

    let chips;
    if (status_is_connected) {
        chips = (
            <Chip dataTrackId="status_is_connected__chip" variant="success" onClick={handleClick}>
                {t('pam_status_connected')}
            </Chip>
        );
    } else {
        chips = (
            <TooltipWrapper
                onHide={handleAnimationComplete}
                text={t('refresh_to_reconnect')}
                position="bottom"
            >
                <div>
                    <Chip
                        dataTrackId="status_is_disconnected__chip"
                        variant="danger"
                        onClick={handleClick}
                    >
                        {t('pam_status_disconnected')}
                    </Chip>
                </div>
            </TooltipWrapper>
        );
    }

    let numberAccounts = `${t('google_location_page', {
        count: locationsCount,
    })}`;

    if (partnerName === 'facebook') {
        numberAccounts = `${t('facebook_brand_page', {
            count: accountsCount,
        })} · ${t('facebook_location_page', {
            count: locationsCount,
        })}`;
    }

    const icon = isAnimating || isLoading ? 'fa-sync fa-spin' : 'fa-sync';
    const isDisabled = isSuccess || isError || isLoading || isStarted || locationsCount >= 3000;

    const loadingMessage = () => {
        if (isError) return errorMessage || t('error');
        if (isSuccess && !isAnimating) return `${t('refreshed')} !`;
        if (isLoading || isAnimating)
            return (
                <>
                    {t('loading')}{' '}
                    <AnimatedCounter
                        from={prevPercentageProgress.current}
                        to={percentageProgress}
                        onAnimationComplete={handleAnimationComplete}
                    />{' '}
                    %
                </>
            );
        if (isDisabled) return '';
        return t('refresh');
    };

    return (
        <RowContainer>
            <AccountBadge
                accountInitials={oauthAccount.name ? oauthAccount.name[0] : ''}
                photoUrl={oauthAccount.picture}
                tooltip={oauthAccount.name}
                name={partnerName}
            />
            <RowInfoContainer>
                <RowInfoName>{oauthAccount.name || oauthAccount.email}</RowInfoName>
                <RowInfoEmail>{oauthAccount.email}</RowInfoEmail>
                <RowInfoCount>{isFetchingDetails ? t('loading') : numberAccounts}</RowInfoCount>
            </RowInfoContainer>
            <NotClickableContainer>{chips}</NotClickableContainer>
            <RowInfoIcons>
                <Tooltip text={loadingMessage()} zIndex={2000}>
                    <IconButton
                        dataTrackId="refresh_account"
                        appearance="outlined"
                        variant="primary"
                        icon={[icon, IconPrefix.SOLID]}
                        disabled={isDisabled}
                        onClick={startRefresh}
                    />
                </Tooltip>

                <Tooltip text={t('delete')} zIndex={2000}>
                    <IconButton
                        dataTrackId="disconnect_account"
                        appearance="outlined"
                        variant="danger"
                        icon={['fa-trash', IconPrefix.SOLID]}
                        onClick={disconnectAccount}
                    />
                </Tooltip>
                <AccountDisconnect
                    accountID={oauthAccount.id}
                    showModal={showModal}
                    setShowModal={setShowModal}
                />
            </RowInfoIcons>
        </RowContainer>
    );
};

export default AccountRow;
