import 'regenerator-runtime/runtime';
import { createRouterMiddleware } from '@lagunovsky/redux-react-router';
import axios from 'axios';
import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { SET_ANY_STATE, setAnyStateReducer } from 'app/common/hooks/useGetParamReduxSync';
import { actionGenerators } from 'app/common/reducers/newBusinesses';
import { axiosInterceptor401 } from 'app/pages/app/hooks/useHandleAxiosInterceptor';
import createSagaOrchestrator from 'app/sagaOrchestrator';
import { browserHistory } from 'app/states/history';
import reducers from 'app/states/reducers';
import reviewAnalyticsPageSagas from 'app/states/reviewAnalytics/sagas';
import { sharedSagas } from 'app/states/sagas';

// Used to reset all reducers data on logout
export const LOGOUT_RESET_REDUCERS_DATA = 'LOGOUT_RESET_REDUCERS_DATA';
export const logoutResetReducersData = () => ({
    type: LOGOUT_RESET_REDUCERS_DATA,
});

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const middlewares = [createRouterMiddleware(browserHistory), sagaMiddleware];
    let composeEnhancer = compose;

    if (process.env.NODE_ENV !== 'production') {
        composeEnhancer = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
                  actionCreators: actionGenerators,
              })
            : compose; // eslint-enable
    }

    const appReducers = reducers(browserHistory);

    const rootReducer = (state, action) => {
        if (action.type === LOGOUT_RESET_REDUCERS_DATA) {
            state = undefined;
        } else if (action.type === SET_ANY_STATE) {
            state = setAnyStateReducer(state, action);
        }

        return appReducers(state, action);
    };

    const store = createStore(rootReducer, composeEnhancer(applyMiddleware(...middlewares)));
    axios.interceptors.response.use(undefined, error => {
        if (error.response?.status === 401) {
            store.dispatch(axiosInterceptor401());
        }
        throw error;
    });

    const sagaOrchestrator = createSagaOrchestrator(sharedSagas, reviewAnalyticsPageSagas);
    sagaMiddleware.run(sagaOrchestrator);
    return store;
};

export default configureStore;
