import getLang from 'app/common/services/getLang';
import {
    ANALYTICS_REVIEW_PATH,
    ANALYTICS_VISIBILITY_PATH,
    CONVERSATIONS_MESSAGING_PATH,
    CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH,
    CUSTOMER_EXPERIENCE_SEND_INVITE_PATH,
    POSTS_PATH,
    SETTINGS_INTEGRATIONS_API_KEYS_PATHNAME,
    SETTINGS_INTEGRATIONS_CUSTOM_FIELDS_PATHNAME,
    SETTINGS_MAIN_NOTIFICATIONS_PATHNAME,
    SETTINGS_MAIN_PROFILE_PATHNAME,
    SETTINGS_MESSAGES_CHATBOTS_PATHNAME,
    SETTINGS_MESSAGES_CONVERSATION_STARTERS_PATHNAME,
    SETTINGS_MESSAGES_REPLY_TEMPLATES_PATHNAME,
    SETTINGS_MESSAGES_WHATSAPP_PATHNAME,
    SETTINGS_PLATFORMS_CONNECTION_PATHNAME,
    SETTINGS_PLATFORMS_GOOGLE_VERIFICATION_PATHNAME,
    SETTINGS_REVIEWS_AUTO_REPLY_PATHNAME,
    SETTINGS_REVIEWS_REPLY_TEMPLATES_PATHNAME,
    SETTINGS_TEAM_PERMISSIONS_PATHNAME,
    SETTINGS_TEAM_USERS_PATHNAME,
    SETTINGS_TEAM_USER_ADD_PATHNAME,
    VISIBILITY_DIFFUSION_PATH,
    VISIBILITY_LOCATION_PATH,
} from 'app/routing/routeIds';

export enum ActionTypeEnum {
    APP_LINK = 'app_link',
    EXTERNAL_LINK = 'external_link',
}

export enum ActionDomainEnum {
    VISIBILITY = 'visibility',
    CONVERSATIONS = 'conversations',
    ANALYTICS = 'analytics',
    POSTS = 'posts',
    SETTINGS = 'settings',
}

export interface QuickActionLink {
    title: string;
    subtitle: string;
    link: string;
    domain: ActionDomainEnum;
    type: ActionTypeEnum;
}

export const QUICK_ACTION_LINKS = {
    BUSINESS_LIST: {
        title: 'quick_action__business_list__title',
        subtitle: 'quick_action__subtitles__businesses',
        link: `${VISIBILITY_LOCATION_PATH}?group=all`,
        domain: ActionDomainEnum.VISIBILITY,
        type: ActionTypeEnum.APP_LINK,
    },
    DIFFUSION_STATUS: {
        title: 'quick_action__diffusion_status__title',
        subtitle: 'quick_action__subtitles__businesses',
        link: VISIBILITY_DIFFUSION_PATH,
        domain: ActionDomainEnum.VISIBILITY,
        type: ActionTypeEnum.APP_LINK,
    },
    POSTS: {
        title: 'quick_action__posts__title',
        subtitle: 'quick_action__subtitles__businesses',
        link: POSTS_PATH,
        domain: ActionDomainEnum.POSTS,
        type: ActionTypeEnum.APP_LINK,
    },
    REVIEWS: {
        title: 'quick_action__reviews__title',
        subtitle: 'quick_action__subtitles__businesses',
        link: `${CUSTOMER_EXPERIENCE_CLIENT_REVIEWS_PATH}?status=live`,
        domain: ActionDomainEnum.CONVERSATIONS,
        type: ActionTypeEnum.APP_LINK,
    },
    REVIEW_BOOSTER: {
        title: 'quick_action__review_booster__title',
        subtitle: 'quick_action__subtitles__reviews',
        link: CUSTOMER_EXPERIENCE_SEND_INVITE_PATH,
        domain: ActionDomainEnum.CONVERSATIONS,
        type: ActionTypeEnum.APP_LINK,
    },
    MESSAGES: {
        title: 'quick_action__messages__title',
        subtitle: 'quick_action__subtitles__messages',
        link: CONVERSATIONS_MESSAGING_PATH,
        domain: ActionDomainEnum.CONVERSATIONS,
        type: ActionTypeEnum.APP_LINK,
    },
    MESSAGE_REPLY_TEMPLATES: {
        title: 'quick_action__message_reply_templates__title',
        subtitle: 'quick_action__subtitles__messages',
        link: SETTINGS_MESSAGES_REPLY_TEMPLATES_PATHNAME,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    MESSAGE_CONVERSATION_STARTERS: {
        title: 'quick_action__message_conversation_starters__title',
        subtitle: 'quick_action__subtitles__messages',
        link: SETTINGS_MESSAGES_CONVERSATION_STARTERS_PATHNAME,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    MESSAGE_JIM: {
        title: 'quick_action__message_jim__title',
        subtitle: 'quick_action__subtitles__messages',
        link: SETTINGS_MESSAGES_CHATBOTS_PATHNAME,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    MESSAGE_WHATSAPP: {
        title: 'quick_action__message_whatsapp__title',
        subtitle: 'quick_action__subtitles__messages',
        link: SETTINGS_MESSAGES_WHATSAPP_PATHNAME,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    VISIBILITY_STATS: {
        title: 'quick_action__visibility_stats__title',
        subtitle: 'quick_action__subtitles__businesses',
        link: ANALYTICS_VISIBILITY_PATH,
        domain: ActionDomainEnum.ANALYTICS,
        type: ActionTypeEnum.APP_LINK,
    },
    REVIEWS_STATS: {
        title: 'quick_action__reviews_stats__title',
        subtitle: 'quick_action__subtitles__reviews',
        link: ANALYTICS_REVIEW_PATH,
        domain: ActionDomainEnum.ANALYTICS,
        type: ActionTypeEnum.APP_LINK,
    },
    PROFILE_SETTINGS: {
        title: 'quick_action__profile_settings__title',
        subtitle: 'quick_action__subtitles__settings',
        link: SETTINGS_MAIN_PROFILE_PATHNAME,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    NOTIFICATIONS_SETTINGS: {
        title: 'quick_action__notifications_settings__title',
        subtitle: 'quick_action__subtitles__settings',
        link: SETTINGS_MAIN_NOTIFICATIONS_PATHNAME,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    REVIEW_TEMPLATES: {
        title: 'quick_action__review_templates__title',
        subtitle: 'quick_action__subtitles__reviews',
        link: `${SETTINGS_REVIEWS_REPLY_TEMPLATES_PATHNAME}?rating=all`,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    REVIEW_AUTO_REPLY: {
        title: 'quick_action__review_auto_reply__title',
        subtitle: 'quick_action__subtitles__reviews',
        link: SETTINGS_REVIEWS_AUTO_REPLY_PATHNAME,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    USER_LIST: {
        title: 'quick_action__user_list__title',
        subtitle: 'quick_action__subtitles__team',
        link: SETTINGS_TEAM_USERS_PATHNAME,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    CREATE_USER: {
        title: 'quick_action__create_user__title',
        subtitle: 'quick_action__subtitles__team',
        link: SETTINGS_TEAM_USER_ADD_PATHNAME,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    USER_PERMISSIONS: {
        title: 'quick_action__user_permissions__title',
        subtitle: 'quick_action__subtitles__team',
        link: SETTINGS_TEAM_PERMISSIONS_PATHNAME,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    BUSINESS_CONNECTIONS: {
        title: 'quick_action__business_connections__title',
        subtitle: 'quick_action__subtitles__businesses',
        link: `${SETTINGS_PLATFORMS_CONNECTION_PATHNAME}?publisher=all`,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    VERIFY_LISTINGS: {
        title: 'quick_action__verify_listings__title',
        subtitle: 'quick_action__subtitles__businesses',
        link: SETTINGS_PLATFORMS_GOOGLE_VERIFICATION_PATHNAME,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    CUSTOM_FIELDS: {
        title: 'quick_action__custom_fields__title',
        subtitle: 'quick_action__subtitles__businesses',
        link: SETTINGS_INTEGRATIONS_CUSTOM_FIELDS_PATHNAME,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    API_KEYS: {
        title: 'quick_action__api_keys__title',
        subtitle: 'quick_action__subtitles__settings',
        link: `${SETTINGS_INTEGRATIONS_API_KEYS_PATHNAME}?type=bot`,
        domain: ActionDomainEnum.SETTINGS,
        type: ActionTypeEnum.APP_LINK,
    },
    HELP_CENTER: {
        title: 'quick_action__help_center__title',
        subtitle: 'quick_action__subtitles__help',
        link: `https://help.partoo.co/${getLang()}`,
        domain: ActionDomainEnum.VISIBILITY,
        type: ActionTypeEnum.EXTERNAL_LINK,
    },
};
