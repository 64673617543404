import {
    IllustrationCategoryEnum,
    IllustrationLaptopElement,
    IllustrationLaptopEnum,
} from '@partoohub/iconography';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import api from 'app/api/v2/api_calls';
import LocationsList from 'app/businessConnection/components/modals/ConnectionModal/SearchLocationsSection/FoundLocationsSection/FoundLocations/LocationsList';
import { usePartnerConnectionContext } from 'app/businessConnection/components/PartnerConnectionContext';
import formatLocations from 'app/businessConnection/services/formatLocations';
import { partnerToPartnerResourceName } from 'app/businessConnection/services/PartnerToAccountsMethods';
import NotFoundTemplate from 'app/common/components/placeholder/NotFoundTemplate';
import { BUSINESS_LOCATIONS } from 'app/common/data/queryKeysConstants';
import useDebounce from 'app/common/hooks/useDebounce';

type Props = {
    searchInput: string;
};

const FoundLocationsSection = ({ searchInput = '' }: Props) => {
    const { t } = useTranslation();
    const debouncedSearchQuery = useDebounce(searchInput, 500);
    const { partnerName } = usePartnerConnectionContext();
    const {
        isFetched,
        isLoading,
        data: { locations: locationsData } = {},
    } = useQuery(
        [BUSINESS_LOCATIONS, { debouncedSearchQuery, partnerName }],
        ({ pageParam = 1 }) =>
            api.account.fuzzySearchLocations(
                partnerToPartnerResourceName[partnerName],
                debouncedSearchQuery ?? '',
                pageParam,
            ),
        {
            enabled: debouncedSearchQuery.length > 3,
        },
    );
    const locations = locationsData && formatLocations(locationsData);

    const illustrationElement: IllustrationLaptopElement = {
        type: IllustrationCategoryEnum.Laptop,
        name: IllustrationLaptopEnum.Sad,
    };

    return (
        <>
            <LocationsList locations={locations} partnerName={partnerName} isLoading={isLoading} />
            {!isLoading && !locations?.length && isFetched && (
                <div className="margin_top--triple margin_bottom--triple">
                    <NotFoundTemplate
                        show
                        small
                        title={t('no_location_page_found')}
                        subtitle={t(`no_${partnerName}_location_found_description`)}
                        imgSrc={illustrationElement}
                        withButton={false}
                    />
                </div>
            )}
        </>
    );
};

export default FoundLocationsSection;
