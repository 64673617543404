import { css } from '@emotion/react';
import { Stack } from '@partoohub/ui';

export const createColumnConfig = (
    id: string,
    labelText: string,
    width: string,
    sortable = true,
) => ({
    id,
    label:
        id === 'dimension_name' ? (
            labelText
        ) : (
            <Stack
                css={css`
                    flex: 1;
                `}
            >
                {labelText}
            </Stack>
        ),
    width,
    sortable,
});
