import { BusinessModalFiltersType } from 'app/common/components/businessModal/utils/generateFiltersFromBusinessModal';

export type FeedbackResultQueryParams = {
    created__lte?: string | null;
    created__gte?: string | null;
    nps__in?: number[] | null;
    state__in?: string[] | null;
    search?: string | null;
    optin?: number | null;
} & BusinessModalFiltersType;

export enum FeedbackResultState {
    TREATED = 'treated',
    NOT_TREATED = 'not_treated',
}

export enum FeedbackFormFieldType {
    CSAT = 'CSAT',
    NPS = 'NPS',
    FREE_FORM = 'FREE_FORM',
}

export type FeedbackResultField = {
    feedback_form_field: {
        id: string;
        field_type: FeedbackFormFieldType;
        field_label: string;
    };
    rating: number | null;
    content: string | null;
};

export type FeedbackResultComment = {
    author_name: string;
    content: string;
    created: string;
    feedback_result_id: number;
    id: number;
    is_reply_suggestion: boolean;
    user_id: string;
};

export type FeedbackResultData = {
    id: string;
    business_id: string;
    firstname: string | null;
    lastname: string | null;
    nps: number;
    content: string;
    created: string;
    feedback_result_fields: Array<FeedbackResultField>;
    state: FeedbackResultState;
    comments: Array<FeedbackResultComment>;
};

export type ReceivedFeedbackResultsData = {
    items: Array<FeedbackResultData>;
    count: number;
    max_page: number;
    page: number;
};

export type ReplyFeedbackResultsPayload = {
    content: string;
};

export type ReplyFeedbackResultsResponse = {
    feedback_result_id: string;
    author_name: string;
    content: string;
};
