import { CommentData } from 'app/api/types/comments';
import { FirstLevelComment } from 'app/states/comments';

// Rick: rewrite this function after replacing old API calls
export const formatComment = (comment: CommentData): FirstLevelComment => ({
    // @ts-ignore
    id: comment.id,
    created: Date.parse(comment.created),
    date: Date.parse(comment.date),
    authorName: comment.author_name,
    content: comment.content,
    canEdit: comment.can_edit,
    notFetched: comment.not_fetched,
    parentId: comment.parent_id,
    updateDate: comment.update_date ? Date.parse(comment.update_date) : null,
    isReplySuggestion: comment.is_reply_suggestion,
    ...(comment.replies
        ? {
              replies: comment.replies.map(reply => formatComment(reply)),
          }
        : {}),
});
export const formatComments = (
    comments: Array<Record<string, any>>, // @ts-ignore
): Array<FirstLevelComment> => comments.map(comment => formatComment(comment));
