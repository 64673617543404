import { useHasReviewManagementPage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewManagementPage';
import { SmallTodoProps } from 'app/pages/Homepage/sections/HomepageTodos/DefaultTodoComponent/DefaultTodoComponent';
import { TodoReviews } from 'app/pages/Homepage/sections/HomepageTodos/todos/TodoReviews';
import { Item } from 'app/pages/Homepage/utils/typing';

export const useTodoReviewsItem = (): Item<SmallTodoProps> => {
    const isAvailable = !!useHasReviewManagementPage();

    return {
        order: 2,
        isAvailable,
        component: TodoReviews,
        key: 'todo-reviews',
    };
};
