import { AccountPartnerName } from 'app/api/types/account';
import { BusinessDetailsData } from 'app/api/types/publisher_state';
import PublisherBusinessDetails from 'app/common/designSystem/components/molecules/PublisherBusinessDetails';

import { DropdownBusinessDetailContainer } from './DropdownBusinessDetail.styled';
import ClickItem from '../ClickItem';

interface Props {
    businessDetails: BusinessDetailsData;
    status: string;
    publisherName: AccountPartnerName;
    publisherFormattedName: string;
}

export const DropdownBusinessDetail = ({
    businessDetails,
    status,
    publisherName,
    publisherFormattedName,
}: Props) => (
    <DropdownBusinessDetailContainer>
        <PublisherBusinessDetails
            title={businessDetails.business_name}
            subtitle={businessDetails.business_formatted_address}
            rightComponent={
                <ClickItem
                    businessDetails={businessDetails}
                    status={status}
                    publisherName={publisherName}
                    publisherFormattedName={publisherFormattedName}
                />
            }
        />
    </DropdownBusinessDetailContainer>
);
