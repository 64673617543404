import { useTranslation } from 'react-i18next';

import { Navigate } from 'react-router-dom';

import { RoleValue } from 'app/api/types/user';
import DynamicPageHeader from 'app/common/components/DynamicPageHeader';
import NotAccessibleForAdmin from 'app/common/components/placeholder/NotAccessibleForAdmin';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import { ADMIN } from 'app/common/data/roles';
import { useHasReviewAnalyticsPage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewAnalyticsPage';
import { ReviewAnalytics } from 'app/pages/analytics/ReviewAnalytics/ReviewAnalytics';
import { ReviewAnalyticsWithNewSidebar } from 'app/pages/analytics/ReviewAnalytics/ReviewAnalyticsWithNewSidebar';
import { NOT_FOUND_PATH } from 'app/routing/routeIds';

type Props = {
    meRole: RoleValue | null;
};

export const ReviewAnalyticsPage = ({ meRole }: Props) => {
    const { t } = useTranslation();

    const hasReviewAnalyticsPage = useHasReviewAnalyticsPage();
    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const ReviewAnalyticsComponent = isNewSidebarEnabled
        ? ReviewAnalyticsWithNewSidebar
        : ReviewAnalytics;

    return (
        <>
            <DynamicPageHeader title={t('analytics')} />
            {hasReviewAnalyticsPage && meRole !== ADMIN && <ReviewAnalyticsComponent />}
            {hasReviewAnalyticsPage && meRole === ADMIN && <NotAccessibleForAdmin />}
            {!hasReviewAnalyticsPage && <Navigate replace to={NOT_FOUND_PATH} />}
        </>
    );
};
