import { useTranslation } from 'react-i18next';

import { useHasApiKeyManagerPage } from 'app/common/hooks/accessesAndPermissions/pages/general/useHasApiKeyManagerPage';
import { SettingMenuItemsData } from 'app/pages/settingsV2/subComponents/SettingsMenuList/SettingsMenuList';
import {
    SETTINGS_INTEGRATIONS_API_KEYS_PATHNAME,
    SETTINGS_INTEGRATIONS_CUSTOM_FIELDS_PATHNAME,
    SETTINGS_INTEGRATIONS_PATHNAME,
} from 'app/routing/routeIds';

import { useIsCustomFieldsPageEnabled } from './pagesEnabled/useIsCustomFieldsPageEnabled';

export const useSettingIntegrationsMenu = (): SettingMenuItemsData => {
    const { t } = useTranslation();

    const isCustomFieldsPageEnabled = useIsCustomFieldsPageEnabled();
    const hasApiKeyManagerPage = !!useHasApiKeyManagerPage();

    return {
        menus: [
            {
                label: t('settings_menu_integrations'),
                pathname: SETTINGS_INTEGRATIONS_PATHNAME,
                options: [
                    {
                        name: 'custom-fields',
                        value: 'custom-fields',
                        label: t('settings_menu_integrations_custom_fields'),
                        pathname: SETTINGS_INTEGRATIONS_CUSTOM_FIELDS_PATHNAME,
                        display: isCustomFieldsPageEnabled,
                    },
                    {
                        name: 'api-keys',
                        value: 'api-keys',
                        label: t('settings_menu_integrations_api_keys'),
                        pathname: SETTINGS_INTEGRATIONS_API_KEYS_PATHNAME,
                        display: hasApiKeyManagerPage,
                    },
                ],
            },
        ],
    };
};
