export const MAX_TOKENS = 128000;

// 40% are allocated to system prompts, remaining 60% are allocated to extra knowledge.
export const REMAINING_TOKENS = MAX_TOKENS * 0.6;

// 60% are allocated to knowledge
export const KNOWLEDGE_MAX_TOKENS = REMAINING_TOKENS * 0.6;

// 15% are allocated to custom prompts, the remaining 25% are for services and attributes
export const CUSTOM_PROMPT_MAX_TOKENS = REMAINING_TOKENS * 0.15;
