import {
    FACEBOOK,
    GOOGLE_MY_BUSINESS,
} from 'app/states/knowledge/reducers/businessConnection/accounts';
import {
    fuzzySearchFacebookLocations,
    fuzzySearchFacebookLocationsFailure,
    fuzzySearchFacebookLocationsSuccess,
} from 'app/states/knowledge/reducers/businessConnection/facebook/facebookLocations';
import {
    fuzzySearchGmbLocations,
    fuzzySearchGmbLocationsFailure,
    fuzzySearchGmbLocationsSuccess,
} from 'app/states/knowledge/reducers/businessConnection/gmb/gmbLocations';

export const partnerToPartnerName = {
    [GOOGLE_MY_BUSINESS]: 'google',
    [FACEBOOK]: 'facebook',
};

export const partnerToPartnerResourceName = {
    [GOOGLE_MY_BUSINESS]: 'gmb',
    google: 'gmb',
    [FACEBOOK]: 'facebook',
    facebook: 'facebook',
};

export const partnerNameToPartner = {
    google: GOOGLE_MY_BUSINESS,
    facebook: FACEBOOK,
};

export const partnerToLocationFuzzy = {
    [GOOGLE_MY_BUSINESS]: fuzzySearchGmbLocations,
    [FACEBOOK]: fuzzySearchFacebookLocations,
};
export const partnerToLocationFuzzySuccess = {
    [GOOGLE_MY_BUSINESS]: fuzzySearchGmbLocationsSuccess,
    [FACEBOOK]: fuzzySearchFacebookLocationsSuccess,
};

export const partnerToLocationFuzzyFailure = {
    [GOOGLE_MY_BUSINESS]: fuzzySearchGmbLocationsFailure,
    [FACEBOOK]: fuzzySearchFacebookLocationsFailure,
};
