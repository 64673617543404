import React from 'react';

import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';

import MessageDate from 'app/reviewManagement/messaging/activeConversation/messages/MessageDate';
import {
    MessageBubbleFlexbox,
    MessageSender,
} from 'app/reviewManagement/messaging/activeConversation/messages/MessagesList.styled';
import {
    Conversation,
    ConversationMessage,
    MESSAGE_CONTENT_TYPE,
    MESSAGE_SENDER_TYPE,
    MessageContentType,
    MessagingPartnerEnum,
} from 'app/states/messaging';

import { MessageBubble } from './MessageBubble';
import MetadataMessage from './MetadataMessage';
import { useHasAssignConversationFeature } from '../../hooks/useHasAssignConversationFeature';

dayjs.extend(customParseFormat);

interface MessagesListProps {
    messages: Array<ConversationMessage>;
    activeConversation: Conversation;
}

// The following content types do not render the MessageSender component at the
// top of the stack.
const MESSAGE_SENDER_CONTENT_TYPE_SKIP_LIST: Array<MessageContentType> = [
    MESSAGE_CONTENT_TYPE.IG_STORY_MENTION,
    MESSAGE_CONTENT_TYPE.IG_STORY_REPLY,
];

const ASSGINMENT_CONTENT_TYPES: Array<MessageContentType> = [
    MESSAGE_CONTENT_TYPE.ASSIGNING,
    MESSAGE_CONTENT_TYPE.UNASSIGNING,
];

const METADATA_MESSAGE_CONTENT_TYPES: Array<MessageContentType> = [
    MESSAGE_CONTENT_TYPE.SOLVING,
    MESSAGE_CONTENT_TYPE.REOPENING,
    MESSAGE_CONTENT_TYPE.SPAM,
    MESSAGE_CONTENT_TYPE.HANDOVER,
];

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSSSSSZ';
const TIME_LIMITATION_FOR_NEW_STACK = 120000;

export const MessagesList = ({ messages, activeConversation }: MessagesListProps) => {
    const { t } = useTranslation();
    const { hasAssignConversationFeature } = useHasAssignConversationFeature();

    const isDelightConversation =
        activeConversation.messaging_partner === MessagingPartnerEnum.DELIGHT;
    const metadataContentTypes: Array<MessageContentType> = hasAssignConversationFeature
        ? METADATA_MESSAGE_CONTENT_TYPES.concat(ASSGINMENT_CONTENT_TYPES)
        : METADATA_MESSAGE_CONTENT_TYPES;

    let bottomMessageOfStackDate = dayjs(messages[0].date, DATE_FORMAT);

    return messages.map<React.ReactNode>((message, index) => {
        const currentMessageDate = dayjs(message.date, DATE_FORMAT);
        const prevMsg = index > 0 ? messages[index - 1] : null;

        const isBottomOfStack =
            index === 0 ||
            prevMsg?.sender_type !== message.sender_type ||
            (prevMsg.sender_type === message.sender_type &&
                +bottomMessageOfStackDate - +currentMessageDate > TIME_LIMITATION_FOR_NEW_STACK);

        if (isBottomOfStack) {
            bottomMessageOfStackDate = dayjs(message.date, DATE_FORMAT);
        }

        const nextMsg = index < messages.length - 1 ? messages[index + 1] : null;
        const isTopOfStack =
            !nextMsg ||
            nextMsg.sender_type !== message.sender_type ||
            (nextMsg.sender_type === message.sender_type &&
                +bottomMessageOfStackDate - +dayjs(nextMsg.date, DATE_FORMAT) >
                    TIME_LIMITATION_FOR_NEW_STACK);

        const isConsumerMessage = message.sender_type === MESSAGE_SENDER_TYPE.CONSUMER;
        const consumerName =
            activeConversation?.consumer_name || t('messaging_conversation_consumer_name_unknown');

        const isHandledMetadataContentType =
            metadataContentTypes.indexOf(message.content_type) !== -1 ||
            (message.content_type === MESSAGE_CONTENT_TYPE.ROUTING &&
                message.sender_type === MESSAGE_SENDER_TYPE.METADATA);

        const showMessageSender =
            !MESSAGE_SENDER_CONTENT_TYPE_SKIP_LIST.includes(message.content_type) &&
            !isHandledMetadataContentType;

        if (
            message.content_type == MESSAGE_CONTENT_TYPE.ROUTING &&
            message.sender_type !== MESSAGE_SENDER_TYPE.METADATA
        )
            return null;

        return (
            // Use date as a key when a user sends a message in which case no id is stored in front.
            <div key={message.id || message.date}>
                {isTopOfStack && message.sender_type !== MESSAGE_SENDER_TYPE.METADATA && (
                    <>
                        {!isDelightConversation && <MessageDate date={message.date} />}
                        {showMessageSender && (
                            <MessageSender
                                isConsumer={isConsumerMessage}
                                color="secondary"
                                variant="bodySRegular"
                            >
                                {getSenderLabel(message, activeConversation, consumerName, t)}
                            </MessageSender>
                        )}
                    </>
                )}
                {isHandledMetadataContentType ? (
                    <MetadataMessage message={message} consumerName={consumerName} />
                ) : (
                    <BubbleFlexbox
                        activeConversation={activeConversation}
                        message={message}
                        consumerName={consumerName}
                    />
                )}
            </div>
        );
    });
};

const getSenderLabel = (
    message: ConversationMessage,
    activeConversation: Conversation,
    consumerName: string,
    t: TFunction,
) => {
    if (message.sender_type !== MESSAGE_SENDER_TYPE.CONSUMER) {
        const isBeingRouted = !!activeConversation.routed_business;
        const business = activeConversation.routed_business ?? activeConversation.business;
        let senderLabel = isBeingRouted
            ? business.name
            : (activeConversation.display_name ?? business.name);
        if (message.is_automated) {
            senderLabel = `${senderLabel} - ${t('messaging_conversation_message_is_automated')}`;
        }
        return senderLabel;
    }
    return consumerName;
};

interface MessageBubbleFlexboxProps {
    activeConversation: Conversation;
    message: ConversationMessage;
    consumerName: string;
}

const BubbleFlexbox = ({
    activeConversation,
    message,
    consumerName,
}: MessageBubbleFlexboxProps) => {
    const isConsumerMessage = message.sender_type === MESSAGE_SENDER_TYPE.CONSUMER;

    return (
        <MessageBubbleFlexbox
            flexDirection="row"
            justifyContent={isConsumerMessage ? 'flex-start' : 'flex-end'}
            alignItems="flex-end"
        >
            <MessageBubble
                message={message}
                conversationId={activeConversation.id}
                conversation={activeConversation}
                consumerName={consumerName}
            />
        </MessageBubbleFlexbox>
    );
};
