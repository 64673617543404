import {
    NavValue,
    TO_CONNECT_TO_FACEBOOK,
    TO_CONNECT_TO_GMB,
} from 'app/states/knowledge/reducers/businessConnection/nav';

interface BusinessConnectionsStats {
    count: number;
    linked_to_gmb_count: number;
    linked_to_facebook_count: number;
}
export const totalBusinessCount = (
    navTab: NavValue,
    { count, linked_to_gmb_count, linked_to_facebook_count }: BusinessConnectionsStats,
): number => {
    if (navTab === TO_CONNECT_TO_GMB) {
        return linked_to_gmb_count;
    } else if (navTab === TO_CONNECT_TO_FACEBOOK) {
        return linked_to_facebook_count;
    } else {
        return count;
    }
};

export const totalBusinessCountToLink = (
    navTab: NavValue,
    { count, linked_to_gmb_count, linked_to_facebook_count }: BusinessConnectionsStats,
): number => {
    if (navTab === TO_CONNECT_TO_GMB) {
        return Math.max(count - linked_to_gmb_count, 0);
    } else if (navTab === TO_CONNECT_TO_FACEBOOK) {
        return Math.max(count - linked_to_facebook_count, 0);
    } else {
        return count;
    }
};
