import { useTranslation } from 'react-i18next';

import { useHasPresenceAnalyticsPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasPresenceAnalyticsPage';
import { Export } from 'app/pages/analytics/VisibilityAnalyticsV2/components/Export/Export';
import { PageHeaderWithAvatar } from 'app/routing/components/PageHeaderWithAvatar';

export const VisibilityPageHeader = () => {
    const { t } = useTranslation();

    const hasPresenceAnalyticsPage = useHasPresenceAnalyticsPage();

    if (!hasPresenceAnalyticsPage) {
        return null;
    }

    return (
        <PageHeaderWithAvatar
            title={t('analytics')}
            actions={[<Export key="--export-analytics-button-key--" />]}
        />
    );
};
