import { useEffect, useState } from 'react';

import { Banner, FontAwesomeIconsPartooUsed, Icon, IconPrefix, Stack, Text } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';

import { useQuery } from 'react-query';

import { useDispatch } from 'react-redux';

import { AccountPartnerName, V2FormattedLocationData } from 'app/api/types/account';
import CamelApi from 'app/api/v2/api_calls/camel';
import PartnerIcon from 'app/businessConnection/components/common/PartnerIcon';
import { ConnectButton } from 'app/businessConnection/components/modals/ConnectionModal/ConnectButton';
import { usePartnerConnectionContext } from 'app/businessConnection/components/PartnerConnectionContext';
import { partnerNameToPartner } from 'app/businessConnection/services/PartnerToAccountsMethods';
import { BUSINESS_LOCATIONS } from 'app/common/data/queryKeysConstants';
import FullScreenModal from 'app/common/designSystem/components/molecules/FullScreenModal';

import useBusiness from 'app/common/hooks/queries/useBusiness';
import { getBusinessEditFields } from 'app/common/reducers/businessEditFields';
import formatBusiness from 'app/common/services/formatBusiness';
import {
    BROKEN_LOCATION_STATUS,
    MISSING_LINK_LOCATION_STATUS,
} from 'app/states/knowledge/reducers/businessConnection/connection';

import {
    FullscreenConnectionModal,
    FullscreenConnectionModalFooter,
    FullscreenConnectionModalHeader,
    FullscreenConnectionModalHeaderDescription,
    FullscreenConnectionModalHeaderTitle,
    FullscreenConnectionModalInfos,
    FullscreenConnectionModalScrollable,
    LoadingError,
    LocationCardStyled,
} from './ConnectionModal.styled';
import { DisconnectButton } from './DisconnectButton';
import InfoSection from './InfoSection';
import SearchLocationsSection from './SearchLocationsSection';
import BusinessCard from '../../common/BusinessCard';

const ConnectionModal = () => {
    const {
        connectedLocationId,
        showConnectionModal,
        setSelectedLocationId,
        setConnectedLocationId,
        setShowConnectionModal,
        partnerName,
        owningBusinessId,
        setOwningBusinessId,
    } = usePartnerConnectionContext();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const state = connectedLocationId ? 'connected' : 'searching';
    const { data: business } = useBusiness(owningBusinessId);
    const {
        isLoading,
        isError,
        data: location,
    } = useQuery(
        [BUSINESS_LOCATIONS, connectedLocationId, partnerName],
        () =>
            CamelApi.account.getLocationDetail(
                connectedLocationId,
                partnerNameToPartner[partnerName],
            ),
        {
            enabled: showConnectionModal && state === 'connected',
        },
    );

    useEffect(() => {
        if (showConnectionModal && partnerName === 'google') {
            dispatch(getBusinessEditFields(owningBusinessId));
        }
    }, [showConnectionModal]);

    const locationHasError = [MISSING_LINK_LOCATION_STATUS, BROKEN_LOCATION_STATUS].includes(
        location?.partnerConnection.status || '',
    );

    const handleClose = () => {
        setShowConnectionModal(false);
        setOwningBusinessId('');
        setSelectedLocationId('');
        setConnectedLocationId('');
    };

    const content = (
        <FullscreenConnectionModal>
            <FullscreenConnectionModalScrollable>
                <FullscreenConnectionModalHeader>
                    <FullscreenConnectionModalHeaderTitle>
                        {PartnerIcon(partnerName)}
                        <Text variant="heading3" as="h3">
                            {t(`connection_${partnerName}_location__title`)}
                        </Text>
                    </FullscreenConnectionModalHeaderTitle>
                    <FullscreenConnectionModalHeaderDescription>
                        <Icon
                            icon={[FontAwesomeIconsPartooUsed.faCircleInfo, IconPrefix.SOLID]}
                            color="secondary"
                            iconSize="14px"
                        />
                        <Text variant="bodyMBold" color="secondary">
                            {t(`connection_modal_description_${partnerName}`)}
                        </Text>
                    </FullscreenConnectionModalHeaderDescription>
                    <ConnectionModalBanner
                        isBannerShown={state === 'connected' && locationHasError}
                        partnerName={partnerName}
                    />
                </FullscreenConnectionModalHeader>
                <FullscreenConnectionModalInfos>
                    <Text variant="heading6">{t('your_business')}</Text>
                    {owningBusinessId && (
                        <BusinessCard business={business ? formatBusiness(business) : undefined} />
                    )}
                </FullscreenConnectionModalInfos>
                {location && state === 'connected' && (
                    <div className="margin_bottom--triple">
                        <LocationCardStyled
                            location={location as V2FormattedLocationData}
                            partnerName={partnerName}
                            isSelected
                        />
                    </div>
                )}
                {isLoading && <LoadingError>{t('loading')}</LoadingError>}
                {isError && <LoadingError>{t('error')}</LoadingError>}
                {state === 'searching' && showConnectionModal && (
                    <SearchLocationsSection business={business} />
                )}
            </FullscreenConnectionModalScrollable>
            <FullscreenConnectionModalFooter>
                <Stack direction="row">
                    <ConnectButton />
                    {state === 'connected' && <DisconnectButton />}
                </Stack>
            </FullscreenConnectionModalFooter>
        </FullscreenConnectionModal>
    );
    return (
        <FullScreenModal
            show={showConnectionModal}
            childrenLeft={content}
            childrenRight={<InfoSection locationHasError={locationHasError} />}
            onHideClick={handleClose}
        />
    );
};

interface ConnectionModalBannerProps {
    isBannerShown: boolean;
    partnerName: AccountPartnerName;
}

const ConnectionModalBanner = ({ isBannerShown, partnerName }: ConnectionModalBannerProps) => {
    const { t } = useTranslation();
    const [isShown, setIsShown] = useState<boolean>(isBannerShown);

    useEffect(() => {
        setIsShown(isBannerShown);
    }, [isBannerShown]);

    const onClose = () => {
        setIsShown(false);
    };

    if (!isShown) return null;

    return (
        <Banner
            dataTrackId={`${partnerName}_connection_modal_banner`}
            variant="danger"
            onClose={onClose}
        >
            {t(`${partnerName}_connection_modal_banner__permission_lost`)}
        </Banner>
    );
};

export default ConnectionModal;
