import { useTranslation } from 'react-i18next';

import { formatPhoneNumberIntl } from 'react-phone-number-input';

import TooltipWrapper from 'app/common/designSystem/components/atoms/TooltipWrapper';
import { ConsumerIcon } from 'app/reviewManagement/common/icon/consumerIcon';
import { Conversation, MessagingPartnerEnum } from 'app/states/messaging';
import Flexbox from 'app/styles/utils/flexbox';

import { AssignConversationButton } from './AssignConversationButton/AssignConversationButton';
import {
    ActionContainer,
    ConvBusinessInfoContainer,
    ConvHeaderBusinessInfo,
    ConvHeaderConsumerName,
    ConvHeaderConsumerPhoneNumber,
    ConvHeaderContainer,
    ConvHeaderInfo,
    ConvIconContainer,
} from './ConversationHeader.styled';
import { StatusChangeButton } from './StatusChangeButton/StatusChangeButton';

type ConversationHeaderProps = {
    activeConversation: Conversation;
};

const ConversationHeader = ({ activeConversation }: ConversationHeaderProps) => {
    const { t } = useTranslation();
    const business = activeConversation.routed_business ?? activeConversation.business;
    const businessInfo =
        activeConversation.display_name || `${business.name} - ${business.formatted_address}`;

    const formattedPhoneNumber =
        activeConversation.consumer_phone_number &&
        formatPhoneNumberIntl(activeConversation.consumer_phone_number);
    const consumerName =
        activeConversation.consumer_name ||
        formattedPhoneNumber ||
        t('messaging_conversation_consumer_name_unknown');
    const hasConsumerNameAndPhoneNumber = activeConversation.consumer_name && formattedPhoneNumber;

    const isDelightConversation =
        activeConversation.messaging_partner === MessagingPartnerEnum.DELIGHT;

    return (
        <ConvHeaderContainer>
            <ConvBusinessInfoContainer>
                <ConvIconContainer>
                    <ConsumerIcon conversation={activeConversation} size={32} />
                </ConvIconContainer>
                <ConvHeaderInfo>
                    <Flexbox flexDirection="row" flexWrap="wrap">
                        <ConvHeaderConsumerName variant="bodyMBold" as="span">
                            {consumerName}
                        </ConvHeaderConsumerName>
                        {hasConsumerNameAndPhoneNumber && (
                            <ConvHeaderConsumerPhoneNumber
                                variant="bodySBold"
                                as="span"
                                color={'secondary'}
                            >
                                ({formattedPhoneNumber})
                            </ConvHeaderConsumerPhoneNumber>
                        )}
                    </Flexbox>
                    <TooltipWrapper text={businessInfo} position="bottom-end">
                        <div>
                            <ConvHeaderBusinessInfo
                                variant="bodySBold"
                                as="span"
                                color={'secondary'}
                            >
                                {businessInfo}
                            </ConvHeaderBusinessInfo>
                        </div>
                    </TooltipWrapper>
                </ConvHeaderInfo>
            </ConvBusinessInfoContainer>
            {!isDelightConversation && (
                <ActionContainer>
                    <AssignConversationButton activeConversation={activeConversation} />
                    <StatusChangeButton activeConversation={activeConversation} />
                </ActionContainer>
            )}
        </ConvHeaderContainer>
    );
};

export default ConversationHeader;
