import { FEATURE_ENUM } from 'app/api/types/plans_features';
import { USE_USER_SIDEBAR_FEATURES } from 'app/common/data/featureFlags';
import { APP_PRODUCTS, REVIEW_MANAGEMENT } from 'app/common/data/productIds';
import { useHasPage } from 'app/common/hooks/accessesAndPermissions/useHasPage';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useHasReviewManagementPage = (): boolean | undefined => {
    const { data: me } = useMe();

    if (USE_USER_SIDEBAR_FEATURES) {
        return useHasPage(FEATURE_ENUM.REVIEW_MANAGEMENT);
    } else {
        return me?.sidebar_products.includes(APP_PRODUCTS[REVIEW_MANAGEMENT]);
    }
};
