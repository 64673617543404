import { useMemo, useState } from 'react';

import { Button, Option, SingleSelect, Stack, Text, TextInput, toast } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import useCountry from 'app/businessEditV2/hooks/business/useCountry';

import { invalidatesAny } from 'app/businessEditV2/utils/utils';
import { useBusinessCreate } from 'app/common/hooks/useBusinessCreate';

import queryClient from 'app/states/queryClient';

import { BUSINESSES } from 'admin/common/data/queryKeysConstants';

import { CountryDescription, CreationModalContainer } from './CreationModal.styled';

import { getDefaultBrandPayload } from './utils/getDefaultBrandPayload';

interface CreationModalProps {
    closeModal: () => void;
}

export const CreationModal = ({ closeModal }: CreationModalProps) => {
    const { t } = useTranslation();
    const [brandName, setBrandName] = useState<string>('');
    const [isCreatingBusiness, setIsCreatingBusiness] = useState<boolean>(false);
    const [countryValue, setCountryValue] = useState<Option | undefined>(undefined);
    const { data: countryData } = useCountry();

    const onSuccess = async () => {
        queryClient.resetQueries(invalidatesAny(BUSINESSES));
        setIsCreatingBusiness(false);
        toast.success(
            t('brand_creation_modal_success_description'),
            t('brand_creation_modal_success_title'),
        );
        closeModal();
    };
    const onError = async () => {
        setIsCreatingBusiness(false);
        toast.error(
            t('brand_creation_modal_error_description'),
            t('brand_creation_modal_error_title'),
        );
    };
    const businessCreate = useBusinessCreate(onSuccess, onError);

    const options = useMemo(
        () =>
            countryData?.map(country => ({
                label: country.label,
                name: country.label,
                value: country.value,
            })) ?? [],
        [countryData],
    );
    const isCreateDisabled = brandName.length === 0 || !countryValue || isCreatingBusiness;

    const handleBrandChange = (value: string) => {
        setBrandName(value);
    };
    const handleCountryChange = (option: Option | undefined) => {
        setCountryValue(option);
    };
    const handleBrandCreation = () => {
        const payload = getDefaultBrandPayload(brandName, countryValue?.value ?? '');
        setIsCreatingBusiness(true);
        businessCreate.mutate(payload);
    };

    return (
        <CreationModalContainer gap="24px">
            <Stack gap="16px">
                <Text variant="heading6">{t('brand_creation_modal_title')}</Text>
                <Text variant="bodyMSemibold">{t('brand_creation_modal_description')}</Text>
            </Stack>
            <TextInput
                dataTrackId={''}
                value={brandName}
                onChange={handleBrandChange}
                label={t('brand_creation_modal_name_input')}
            ></TextInput>
            <Stack gap="4px">
                <SingleSelect
                    placeholder={t('country')}
                    label={t('country')}
                    sections={[{ options }]}
                    onChange={handleCountryChange}
                    selectedValue={countryValue}
                    dataTrackId={''}
                />
                <CountryDescription>
                    <Text color="secondary" variant="bodySRegular">
                        {t('brand_creation_modal_country_description')}
                    </Text>
                </CountryDescription>
            </Stack>
            <Stack gap="8px" justifyContent="flex-end" direction="row">
                <Button
                    dataTrackId={'brand_creation_modal_close'}
                    onClick={closeModal}
                    variant="secondary"
                    appearance="outlined"
                    shape="rounded"
                >
                    <Text variant="bodyMBold">{t('cancel')}</Text>
                </Button>
                <Button
                    dataTrackId={'brand_creation_modal_create'}
                    onClick={handleBrandCreation}
                    shape="rounded"
                    disabled={isCreateDisabled}
                >
                    <Text color="info" variant="bodyMBold">
                        {t('brand_creation_modal_create')}
                    </Text>
                </Button>
            </Stack>
        </CreationModalContainer>
    );
};
