import * as constants from './constants';

export type GetReviewsStatsSuccessType = {
    type: typeof constants.GET_REVIEWS_STATS_SUCCESS;
    count: number;
    treated: number;
    notTreated: number;
    deleted: number;
};

export type GetReviewsStatsFailureType = {
    type: typeof constants.GET_REVIEWS_STATS_FAILURE;
    error: Record<string, any>;
};

export type UpdateReviewStatsType = {
    type: typeof constants.UPDATE_REVIEW_STATS;
    treated: number;
    notTreated: number;
};

export type AddReviewStatsType = {
    type: typeof constants.ADD_REVIEW_STATS;
    count: number;
    treated: number;
    notTreated: number;
};

export type ReviewsStatsActionType =
    | GetReviewsStatsSuccessType
    | GetReviewsStatsFailureType
    | UpdateReviewStatsType
    | AddReviewStatsType;
