import { RepliesNotFetchedBanner } from 'app/reviewManagement/reviewList/sections/BannerSection/RepliesNotFetchedBanner';

import { ReplyPermissionDeniedBanner } from './ReplyPermissionDeniedBanner';

export const BannerSection = () => {
    return (
        <>
            <ReplyPermissionDeniedBanner />
            <RepliesNotFetchedBanner />
        </>
    );
};
