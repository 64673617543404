import { useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconButton, IconPrefix, Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import {
    AverageRatingGraph,
    RatingDistributionGraph,
    ReplyMeansGraph,
    ReplyTimeGraph,
    ReviewCountGraph,
    TagsGraph,
} from './Graphs';
import { StyledGraphContainer, StyledGraphSection } from './GraphSection.styled';
import { useDateDimension } from '../../hooks/useDateDimension';
import { useGraphSelection } from '../../hooks/useGraphSelection';
import { ExportModal } from '../ExportModal/ExportModal';

export const GraphSection = () => {
    const { t } = useTranslation();
    const [graph] = useGraphSelection();
    const [openModal, setOpenModal] = useState(false);

    const GraphComponent = {
        average_rating: AverageRatingGraph,
        review_count: ReviewCountGraph,
        rating_distribution: RatingDistributionGraph,
        reply_means: ReplyMeansGraph,
        reply_time: ReplyTimeGraph,
        tags: TagsGraph,
    }[graph];

    const dateDimension = useDateDimension();
    const dimension = graph === 'tags' ? 'tag' : dateDimension;

    return (
        <StyledGraphSection>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Text variant="heading6">{t(`review_analytics_graph_${graph}`)}</Text>
                <IconButton
                    dataTrackId={`review_analytics_graph_${graph}_download_button`}
                    appearance="outlined"
                    icon={[FontAwesomeIconsPartooUsed.faArrowDownToLine, IconPrefix.REGULAR]}
                    tooltipPlacement="bottom"
                    tooltip={t('download_data')}
                    onClick={() => setOpenModal(true)}
                />
            </Stack>
            <StyledGraphContainer>
                {GraphComponent ? <GraphComponent /> : undefined}
            </StyledGraphContainer>
            <ExportModal
                isOpenExportModal={openModal}
                setIsOpenExportModal={setOpenModal}
                dimensionType={dimension}
            />
        </StyledGraphSection>
    );
};
