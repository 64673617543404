import { Navigate, useNavigate, useRoutes } from 'react-router-dom';

import useMe from 'app/common/hooks/queries/useMeUncamel';
import { GooglePostsListPage } from 'app/pages/visibility/GooglePostsList';
import {
    EditPostsContext,
    useEditPostContext,
} from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/hooks/useEditPostContext';
import PostCreationModalComponent from 'app/presence/googlePosts/components/googlePostList/PostCreationModal/PostCreationModalComponent';
import { ProtectedRoute } from 'app/routing/components/ProtectedRoute';
import { NOT_FOUND_PATH, POSTS_PATH } from 'app/routing/routeIds';

const PostsRoutes = () => {
    const { data: me } = useMe();
    const navigate = useNavigate();
    const meRole = me ? me.role : null;
    const editPostsContext = useEditPostContext();

    const routes = [
        {
            index: true,
            element: (
                <ProtectedRoute userRole={meRole}>
                    <GooglePostsListPage />
                </ProtectedRoute>
            ),
        },
        {
            path: 'create',
            element: (
                <ProtectedRoute userRole={meRole}>
                    <EditPostsContext.Provider value={editPostsContext}>
                        <PostCreationModalComponent
                            isCreating
                            show
                            onHide={() => {
                                navigate(POSTS_PATH);
                            }}
                        />
                    </EditPostsContext.Provider>
                </ProtectedRoute>
            ),
        },
        {
            path: '*',
            element: <Navigate replace to={NOT_FOUND_PATH} />,
        },
    ];

    return useRoutes(routes);
};

export default PostsRoutes;
