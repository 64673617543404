import { AccountPartnerName, V2FormattedLocationData } from 'app/api/types/account';
import LoadingLocationCard from 'app/businessConnection/components/commonOld/LoadingLocationCard';
import LocationSelectionCard from 'app/businessConnection/components/modals/ConnectionModal/LocationCardSelection';
import { usePartnerConnectionContext } from 'app/businessConnection/components/PartnerConnectionContext';

import { LocationListContainer } from './LocationList.styled';

type Props = {
    locations: Array<V2FormattedLocationData> | undefined;
    partnerName: AccountPartnerName;
    isLoading: boolean;
    error: Record<string, any>;
};

const LocationsList = ({ locations, partnerName, isLoading, error }: Props) => {
    const { owningBusinessId, selectedLocationId, setSelectedLocationId, setOwningBusinessId } =
        usePartnerConnectionContext();
    const editLocationSelected = (locationId?: string) => {
        if (selectedLocationId === locationId) {
            setSelectedLocationId('');
        } else {
            setSelectedLocationId(locationId || '');
            setOwningBusinessId(owningBusinessId || '');
        }
    };

    return (
        <LocationListContainer>
            {isLoading ? (
                <LoadingLocationCard />
            ) : (
                <div
                    className={`
                        fullscreen_connection_modal__locations_list
                        fullscreen_connection_modal__locations_list${
                            locations?.length > 1 ? '--grid' : ''
                        }
                    `}
                >
                    {locations?.map((location: V2FormattedLocationData, index: number) => (
                        <LocationSelectionCard
                            isSelected={selectedLocationId === location.id}
                            partnerName={partnerName}
                            locationStatus={location.partnerConnection.status}
                            editLocationsSelected={editLocationSelected}
                            hasError={error[location.id]}
                            location={location}
                            key={location.id}
                            tooltipPlacement={index % 2 === 0 ? 'right' : 'left'}
                        />
                    ))}
                </div>
            )}
        </LocationListContainer>
    );
};

export default LocationsList;
