import ApiResourceBaseClass from 'app/api/v2/api_calls/baseApiCalls';

export type WidgetSetupIdsData = {
    ids: Array<string>;
};

export enum WidgetSetupIcon {
    LOGO = 'logo',
    BUBBLE = 'bubble',
    QUESTION_MARK = 'question_mark',
}

export type CreateWidgetSetupData = {
    business_id: string;
    domain_whitelist: Array<string>;
    has_sms_active: boolean;
    has_whatsapp_active: boolean;
    has_messenger_active: boolean;
    color?: string;
    icon?: WidgetSetupIcon;
    avatar_url?: string;
};

export type UpdateWidgetSetupData = {
    domain_whitelist: Array<string>;
    has_sms_active: boolean;
    has_whatsapp_active: boolean;
    has_messenger_active: boolean;
    color?: string;
    icon?: WidgetSetupIcon;
    avatar_url?: string;
};

export type CreateWidgetSetupResponse = {
    id: string;
    business: {
        id: string;
        name?: string;
        country?: string;
    };
    domain_whitelist: Array<string>;
    expired: boolean;
    created_at: Date;
    color: string;
    icon: WidgetSetupIcon;
    avatar_url: string;
    has_sms_active: boolean;
    has_whatsapp_active: boolean;
    has_messenger_active: boolean;
};

export class MessagingWidgetSetup extends ApiResourceBaseClass {
    static getWidgetSetupIds(): Promise<WidgetSetupIdsData> {
        return MessagingWidgetSetup.get('widgets/ids').then(({ data }) => {
            return data;
        });
    }

    static createWidgetSetup(data: CreateWidgetSetupData): Promise<CreateWidgetSetupResponse> {
        return MessagingWidgetSetup.post('widgets', data).then(({ data }) => data);
    }

    static updateWidgetSetup(
        widgetId: string,
        data: UpdateWidgetSetupData,
    ): Promise<CreateWidgetSetupResponse> {
        return MessagingWidgetSetup.patch(`widgets/${widgetId}`, data).then(({ data }) => data);
    }

    static getWidgetSetup(widgetId: string): Promise<CreateWidgetSetupResponse> {
        return MessagingWidgetSetup.get(`widgets/${widgetId}`).then(({ data }) => data);
    }

    static uploadAvatar(widgetId: string, file: File): Promise<CreateWidgetSetupResponse> {
        const formData = new FormData();

        formData.append('avatar_file', file);

        return MessagingWidgetSetup.post(`widgets/${widgetId}/avatar`, formData).then(
            ({ data }) => data,
        );
    }
}
