import { ORG_ADMIN } from 'app/common/data/roles';
import { useHasReviewManagementPage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewManagementPage';
import useBusinessSubscriptions from 'app/common/hooks/queries/useBusinessSubscriptions';
import useMe from 'app/common/hooks/queries/useMeUncamel';
import {
    CEOrgFeatures,
    useHasCEOrgFeatures,
} from 'app/pages/customerExperience/settings/useHasCEOrgFeatures';
import {
    CEPages,
    useCanAccessCustomerExperiencePage,
} from 'app/pages/customerExperience/useCanAccessCustomerExperiencePage';
import { useHasReviewReplyTemplatesAccess } from 'app/reviewManagement/replyTemplate/hooks/useHasReviewReplyTemplatesAccess';

export enum ReviewSettingsMenu {
    AUTO_REPLY_WITHOUT_COMMENT = 'auto_reply_without_comment',
    AUTO_REPLY_WITH_COMMENT = 'auto_reply_with_comment',
    REPLY_TEMPLATES = 'reply_templates',
    FBM = 'feedback_management',
}

export const useCanAccessReviewsSetting = () => {
    const { data: me, isLoading: isLoadingMe } = useMe();

    // Sidebar product (REVIEW_MANAGEMENT) ON for user
    const userHasReviewManagement = !!useHasReviewManagementPage();
    const meRole = me ? me.role : null;

    const {
        orgFeatures: {
            [CEOrgFeatures.AutoReplyWithoutComments]: hasWithoutCommentsFeature,
            [CEOrgFeatures.AutoReplyWithComments]: hasWithCommentsFeature,
        },
        isLoading: isLoadingOrgFeatures,
    } = useHasCEOrgFeatures();
    const hasReplyTemplatesAccess = useHasReviewReplyTemplatesAccess();
    const { canAccessCustomerExperiencePage, isLoading: isLoadingCEAccess } =
        useCanAccessCustomerExperiencePage();

    // Valid BM subscription on org
    const {
        hasBusinesses,
        subscribedToRM,
        isLoading: isLoadingReviewManagementSubscription,
    } = useBusinessSubscriptions();

    const hasRM = userHasReviewManagement && hasBusinesses && subscribedToRM;
    const menuAccess = {
        [ReviewSettingsMenu.AUTO_REPLY_WITHOUT_COMMENT]:
            (hasRM && meRole === ORG_ADMIN && hasWithoutCommentsFeature) ?? false,
        [ReviewSettingsMenu.AUTO_REPLY_WITH_COMMENT]:
            (hasRM && meRole === ORG_ADMIN && hasWithCommentsFeature) ?? false,
        [ReviewSettingsMenu.REPLY_TEMPLATES]: hasReplyTemplatesAccess && hasRM,
        [ReviewSettingsMenu.FBM]: canAccessCustomerExperiencePage[CEPages.FBM] ?? false,
    };

    const canAccess = Object.values(menuAccess).some(Boolean);
    const isLoading =
        isLoadingMe ||
        isLoadingReviewManagementSubscription ||
        isLoadingCEAccess ||
        isLoadingOrgFeatures;

    return { canAccess, isLoading, menuAccess };
};
