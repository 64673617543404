import { ReviewAnalyticsItemType } from 'app/api/types/review_analytics';
import { sumValues } from 'app/common/utils/sumValues';

type quadrantLabelProps = {
    position: {};
    label: string;
    fontSize?: number;
    bold?: boolean;
};

type quadrantBackgroundColorProps = {
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
    color: string;
};

export const calculateYProportion = (score: number) => {
    return score >= 1 && score < 3.8
        ? ((score - 1) * 3) / (3.8 - 1)
        : ((score - 3.8) * 3) / (5 - 3.8) + 3;
};

export const calculateXProportion = (avgReview: number, maxReview: number, review: number) => {
    const middleReview = maxReview * 0.5;

    return review >= 0 && review < avgReview
        ? (review * middleReview) / avgReview
        : ((review - avgReview) * middleReview) / (maxReview - avgReview) + middleReview;
};

export const getQuadrantBackgroundColor = (
    x: number,
    y: number,
    middleReviewQuantity: number,
    middleY: number,
) => {
    if (
        (x < middleReviewQuantity && y >= middleY) ||
        (x === middleReviewQuantity && y === middleY)
    ) {
        return 'rgba(0, 133, 242, 0.05)'; // top-left
    } else if (x >= middleReviewQuantity && y >= middleY) {
        return 'rgba(64, 219, 162, 0.05)'; // top-right
    } else if (x <= middleReviewQuantity && y < middleY) {
        return 'rgba(255, 144, 20, 0.05)'; // bottom-left
    } else {
        return 'rgba(255, 66, 110, 0.05)'; // bottom-right
    }
};

export const createBackground = ({
    xMin,
    xMax,
    yMin,
    yMax,
    color,
}: quadrantBackgroundColorProps) => ({
    type: 'box' as const,
    borderWidth: 0,
    xMin,
    xMax,
    yMin,
    yMax,
    backgroundColor: color,
});

export const createLabel = ({
    position,
    label,
    fontSize = 12,
    bold = false,
}: quadrantLabelProps) => ({
    type: 'label' as const,
    color: 'rgba(127, 142, 164, 1)',
    ...position,
    content: label,
    font: {
        size: fontSize,
        weight: bold ? ('bold' as const) : ('normal' as const),
    },
});

export const getQuadrantTextPosition = (xValue: number, yValue: number) => ({ xValue, yValue });

export const getReviewQuantity = (xValues: number[]) => {
    const minReviewQuantity = 0;
    const maxReviewQuantity = Math.max(...xValues) + 1;
    const middleReviewQuantity = (minReviewQuantity + maxReviewQuantity) * 0.5;

    return { minReviewQuantity, maxReviewQuantity, middleReviewQuantity };
};

export const getTagScores = (
    data: ReviewAnalyticsItemType<'tag', ['average_rating', 'rating_distribution']>[],
    bottom = false,
    limit = 2,
) => {
    return data
        ?.map(({ dimension_name, metrics }) => {
            const ratingSum = sumValues(metrics.rating_distribution);
            let score = 0;

            if (bottom) {
                if (ratingSum !== 0) {
                    score = (metrics.average_rating ?? 0) / ratingSum;
                }
            } else {
                score = (metrics.average_rating ?? 0) * ratingSum;
            }

            return { tagName: dimension_name, score };
        })
        .sort((a, b) => (bottom ? a.score - b.score : b.score - a.score))
        .slice(0, limit);
};

const getPercentage = (value: number | null, total: number) =>
    total > 0 ? (100 * (value ?? 0)) / total : 0;

export const formatValue = (value: number | null, total: number) =>
    value === null ? '-' : `${getPercentage(value, total).toFixed(2)}%`;

export const generateTableColumns = (
    currentOrder: any,
    handleSort: Function,
    setPage: Function,
    columnsData: any[],
) => {
    return columnsData.map(({ id, label, width, sortable = true }) => ({
        label: label,
        width,
        ...(sortable && {
            isOrdered: currentOrder.columnId === id,
            onOrder: () => {
                handleSort(id);
                setPage(1);
            },
            orderDirection: currentOrder.order,
        }),
    }));
};
