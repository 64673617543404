import React from 'react';

import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import { V2FormattedAccountData } from 'app/api/types/account';

import {
    AccountListContainer,
    AccountRowContainer,
    LoadMore,
    NoAccounts,
    Text,
} from './AccountSection.styled';
import AccountRow from '../AccountRow/AccountRow';

type Props = {
    t: (text: string, obj?: Record<string, any>) => string;
    accounts: V2FormattedAccountData[];
    isFetching: boolean;
    hasNextPage?: boolean;
    fetchNextPage: () => void;
    hasSearch: boolean;
};

const AccountSection = ({
    t,
    accounts,
    isFetching,
    hasNextPage,
    fetchNextPage,
    hasSearch,
}: Props) => {
    if (!isFetching && !accounts.length) {
        return (
            <NoAccounts>
                <Text>
                    {hasSearch ? t('no-result-found') : t('pam_text_no_accounts_available')}
                </Text>
            </NoAccounts>
        );
    }

    const loadingComponent = <Text>{t('loading')}</Text>;

    return (
        <AccountListContainer id="accountListScroll">
            <InfiniteScroll
                hasMore={hasNextPage}
                loadMore={() => (!isFetching ? fetchNextPage() : null)}
                useWindow={false}
                getScrollParent={() => document.getElementById('accountListScroll')}
                threshold={500}
            >
                <div>
                    {accounts.map(account => (
                        <AccountRowContainer key={account.id}>
                            <AccountRow oauthAccount={account} />
                        </AccountRowContainer>
                    ))}
                    <LoadMore key={0}>
                        {isFetching
                            ? loadingComponent
                            : hasNextPage && (
                                  <a
                                      onClick={fetchNextPage}
                                      onKeyPress={fetchNextPage}
                                      role="button"
                                      tabIndex={-1}
                                  >
                                      {t('load_more')}
                                  </a>
                              )}
                    </LoadMore>
                </div>
            </InfiniteScroll>
        </AccountListContainer>
    );
};

export default withTranslation()(AccountSection);
