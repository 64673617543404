import { FEATURE_ENUM } from 'app/api/types/plans_features';
import { USE_USER_SIDEBAR_FEATURES } from 'app/common/data/featureFlags';
import { ADMIN } from 'app/common/data/roles';
import { useHasPage } from 'app/common/hooks/accessesAndPermissions/useHasPage';
import useMe from 'app/common/hooks/queries/useMeUncamel';

export const useHasApiKeyManagerPage = (): boolean | undefined => {
    const { data: me } = useMe();

    if (me?.role === ADMIN) {
        return false;
    }

    if (USE_USER_SIDEBAR_FEATURES) {
        return useHasPage(FEATURE_ENUM.API_KEY_MANAGER);
    } else {
        return !me?.sidebar_excluded_pages.includes('api_key_manager');
    }
};
