import { Text, theme } from '@partoohub/ui';

import { UseControllerProps, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { BrandSelector } from 'app/common/components/BrandSelector/BrandSelector';

import { BrandDisplay } from './components/BrandDisplay';

import { WidgetSetupFormValues } from '../../../WidgetSetup';
import { DropdownFormSection } from '../../DropdownFormSection';

export const BrandSection = ({ control }: UseControllerProps<WidgetSetupFormValues>) => {
    const {
        field: { onChange },
        fieldState: { error },
    } = useController({
        name: 'business_id',
        control,
        rules: { required: true },
    });

    const { t } = useTranslation();
    const { widgetSetupId } = useParams();

    const headingText = t('widget_setup_brand_selection_title');

    return (
        <DropdownFormSection header={<Text variant="heading6">{headingText}</Text>}>
            {widgetSetupId ? (
                <BrandDisplay />
            ) : (
                <>
                    <BrandSelector onChange={onChange} />
                    {error && (
                        <Text color={theme.colors.danger.initial}>
                            {t('widget_setup_brand_selection_error')}
                        </Text>
                    )}
                </>
            )}
        </DropdownFormSection>
    );
};
