import { LegacyRef } from 'react';

import { Button, FontAwesomeIconsPartooUsed } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useClampText } from 'use-clamp-text';

import { useHasBulkModificationPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasBulkModificationPage';
import { useIsMobile } from 'app/common/services/screenDimensions';
import { VISIBILITY_BULK_UPDATE_PATH } from 'app/routing/routeIds';

import {
    AverageSubtitle,
    AverageTitle,
    BulkButtonContainer,
    BusinessAverageDescriptionContainer,
} from './BusinessAverageDescription.styled';

export const BusinessAverageDescription = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const isMobile = useIsMobile();
    const hasBulkModificationPage = useHasBulkModificationPage();

    const displayBulkUpdateLink = !!hasBulkModificationPage && !isMobile;

    const [refTitle, { clampedText: title }] = useClampText({
        text: t('business_list_average_completion_of_businesses'),
        ellipsis: '...',
        lines: 3,
        debounceTime: 0,
    });

    const [refSubtitle, { clampedText: subtitle }] = useClampText({
        text: t('business_list_fill_in_your_info'),
        ellipsis: '...',
        lines: 4,
        debounceTime: 0,
    });

    const handleRedirectBulkUpdate = () => {
        navigate(VISIBILITY_BULK_UPDATE_PATH);
    };

    return (
        <BusinessAverageDescriptionContainer>
            <AverageTitle ref={refTitle as LegacyRef<HTMLDivElement>}>{title}</AverageTitle>
            <AverageSubtitle ref={refSubtitle as LegacyRef<HTMLDivElement>}>
                {subtitle}
            </AverageSubtitle>
            {displayBulkUpdateLink && (
                <BulkButtonContainer>
                    <Button
                        dataTrackId="redirect_bulk_update__button"
                        variant="primary"
                        appearance="text"
                        onClick={handleRedirectBulkUpdate}
                        icon={[FontAwesomeIconsPartooUsed.faArrowRight]}
                        iconPosition="right"
                    >
                        {t('bulk_modification')}
                    </Button>
                </BulkButtonContainer>
            )}
        </BusinessAverageDescriptionContainer>
    );
};
