import { Tabs } from '@partoohub/ui';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDataContext } from 'app/common/contexts/appData';
import { useFeatureFlag } from 'app/common/contexts/featureFlags';
import { USE_NEW_SIDEBAR } from 'app/common/data/featureFlags';
import IS_IFRAME from 'app/common/data/iframe';
import { ADMIN } from 'app/common/data/roles';
import { useHasCompetitiveBenchmarkPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasCompetitiveBenchmarkPage';
import { useHasPresenceAnalyticsPage } from 'app/common/hooks/accessesAndPermissions/pages/presenceManagement/useHasPresenceAnalyticsPage';
import { useHasReviewAnalyticsPage } from 'app/common/hooks/accessesAndPermissions/pages/reviewManagement/useHasReviewAnalyticsPage';
import {
    PARTOOROBOT_TAB_ANALYTYCS_REVIEWS,
    PARTOOROBOT_TAB_ANALYTYCS_REVIEWS_V2,
    PARTOOROBOT_TAB_ANALYTYCS_VISIBILITY,
    PARTOOROBOT_TAB_COMPETITIVE_BENCHMARK,
} from 'app/constants/robot';
import { useHasReviewAnalyticsV2 } from 'app/pages/analytics/hooks';
import { useReviewAnalyticsDefaultFilter } from 'app/pages/analytics/ReviewAnalyticsV2/hooks/useReviewAnalyticsDefaultFilter';
import {
    ANALYTICS_REVIEW_PATH,
    ANALYTICS_VISIBILITY_PATH,
    COMPETITIVE_BENCHMARK_PATH,
} from 'app/routing/routeIds';

import { AnalyticsTabsContainer } from './AnalyticsTabs.styled';

export const AnalyticsTabs = () => {
    const { t } = useTranslation();
    const { me } = useAppDataContext();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const hasPresenceAnalyticsPage = useHasPresenceAnalyticsPage();
    const hasReviewAnalyticsPage = useHasReviewAnalyticsPage();

    const isNewSidebarEnabled = useFeatureFlag(USE_NEW_SIDEBAR);
    const hasCompetitiveBenchmarkPage = useHasCompetitiveBenchmarkPage();
    const hasReviewAnalyticsV2 = useHasReviewAnalyticsV2();
    const { defaultDateQuery } = useReviewAnalyticsDefaultFilter();

    const disabledTabs = [ADMIN].includes(me.role);

    const trueCount = [
        hasReviewAnalyticsPage,
        hasPresenceAnalyticsPage,
        hasCompetitiveBenchmarkPage,
    ].filter(Boolean).length;

    const handleNavigateTabLink = (path: string) => {
        navigate(path);
    };

    if (IS_IFRAME || trueCount <= 1) {
        return null;
    }

    return (
        <AnalyticsTabsContainer isNewSidebar={isNewSidebarEnabled}>
            <Tabs
                selected={pathname}
                dataTrackId="analytics_tabs"
                onChange={value => {
                    if (value === `${ANALYTICS_REVIEW_PATH}/v2`) {
                        handleNavigateTabLink(`${value}?${defaultDateQuery}`);
                    } else {
                        handleNavigateTabLink(value as string);
                    }
                }}
            >
                {hasPresenceAnalyticsPage && (
                    <Tabs.Tab
                        value={ANALYTICS_VISIBILITY_PATH}
                        disabled={disabledTabs}
                        data-partoorobot={PARTOOROBOT_TAB_ANALYTYCS_VISIBILITY}
                    >
                        {t('visibility')}
                    </Tabs.Tab>
                )}

                {hasReviewAnalyticsPage && (
                    <Tabs.Tab
                        value={ANALYTICS_REVIEW_PATH}
                        disabled={disabledTabs}
                        data-partoorobot={PARTOOROBOT_TAB_ANALYTYCS_REVIEWS}
                    >
                        {t('reviews')}
                    </Tabs.Tab>
                )}

                {hasReviewAnalyticsPage && hasReviewAnalyticsV2 && (
                    <Tabs.Tab
                        value={`${ANALYTICS_REVIEW_PATH}/v2`}
                        disabled={disabledTabs}
                        data-partoorobot={PARTOOROBOT_TAB_ANALYTYCS_REVIEWS_V2}
                    >
                        {t('reviews')} V2
                    </Tabs.Tab>
                )}

                {hasCompetitiveBenchmarkPage && (
                    <Tabs.Tab
                        value={COMPETITIVE_BENCHMARK_PATH}
                        disabled={disabledTabs}
                        data-partoorobot={PARTOOROBOT_TAB_COMPETITIVE_BENCHMARK}
                    >
                        {t('competitive_benchmark_wording')}
                    </Tabs.Tab>
                )}
            </Tabs>
        </AnalyticsTabsContainer>
    );
};
