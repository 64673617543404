import React, { ReactNode, createContext, useContext, useMemo, useState } from 'react';

import { AccountPartnerName } from 'app/api/types/account';

interface BusinessConnectionsStats {
    count: number;
    linked_to_gmb_count: number;
    linked_to_facebook_count: number;
}

interface BusinessConnectionsFilter {
    org_id?: number;
    org_id__in?: string;
}

interface PartnerConnectionContextValue {
    selectedLocationId: string;
    setSelectedLocationId: React.Dispatch<React.SetStateAction<string>>;
    connectedLocationId: string;
    setConnectedLocationId: React.Dispatch<React.SetStateAction<string>>;
    owningBusinessId: string;
    setOwningBusinessId: React.Dispatch<React.SetStateAction<string>>;
    oauthAccountId: string;
    setOAuthAccountId: React.Dispatch<React.SetStateAction<string>>;
    partnerName: AccountPartnerName;
    setPartnerName: React.Dispatch<React.SetStateAction<AccountPartnerName>>;
    displayImportModal: boolean;
    setDisplayImportModal: React.Dispatch<React.SetStateAction<boolean>>;
    businessConnectionsStats: BusinessConnectionsStats;
    setBusinessConnectionsStats: React.Dispatch<React.SetStateAction<BusinessConnectionsStats>>;
    showConnectionModal: boolean;
    setShowConnectionModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface PartnerConnectionProviderProps {
    children: ReactNode;
}

const PartnerConnectionContext = createContext<PartnerConnectionContextValue>({
    selectedLocationId: '',
    setSelectedLocationId: () => {},
    connectedLocationId: '',
    setConnectedLocationId: () => {},
    owningBusinessId: '',
    setOwningBusinessId: () => {},
    oauthAccountId: '',
    setOAuthAccountId: () => {},
    partnerName: 'google',
    setPartnerName: () => {},
    displayImportModal: false,
    setDisplayImportModal: () => {},
    showConnectionModal: false,
    setShowConnectionModal: () => {},
    businessConnectionsStats: {
        count: 0,
        linked_to_gmb_count: 0,
        linked_to_facebook_count: 0,
    },
    setBusinessConnectionsStats: () => {},
});

export const PartnerConnectionProvider: React.FC<PartnerConnectionProviderProps> = ({
    children,
}) => {
    const [selectedLocationId, setSelectedLocationId] = useState<string>('');
    const [connectedLocationId, setConnectedLocationId] = useState<string>('');
    const [owningBusinessId, setOwningBusinessId] = useState<string>('');
    const [oauthAccountId, setOAuthAccountId] = useState<string>('');
    const [partnerName, setPartnerName] = useState<AccountPartnerName>('google');
    const [displayImportModal, setDisplayImportModal] = useState<boolean>(false);
    const [showConnectionModal, setShowConnectionModal] = useState<boolean>(false);
    const [businessConnectionsStats, setBusinessConnectionsStats] =
        useState<BusinessConnectionsStats>({
            count: 0,
            linked_to_gmb_count: 0,
            linked_to_facebook_count: 0,
        });
    const [businessFilter, setBusinessFilter] = useState<BusinessConnectionsFilter>({
        org_id: undefined,
        org_id__in: undefined,
    });

    const ctxValue = useMemo(
        () => ({
            selectedLocationId,
            setSelectedLocationId,
            connectedLocationId,
            setConnectedLocationId,
            owningBusinessId,
            setOwningBusinessId,
            oauthAccountId,
            setOAuthAccountId,
            partnerName,
            setPartnerName,
            displayImportModal,
            setDisplayImportModal,
            showConnectionModal,
            setShowConnectionModal,
            businessConnectionsStats,
            setBusinessConnectionsStats,
            businessFilter,
            setBusinessFilter,
        }),
        [
            selectedLocationId,
            setSelectedLocationId,
            connectedLocationId,
            setConnectedLocationId,
            owningBusinessId,
            setOwningBusinessId,
            oauthAccountId,
            setOAuthAccountId,
            partnerName,
            setPartnerName,
            displayImportModal,
            setDisplayImportModal,
            showConnectionModal,
            setShowConnectionModal,
            businessConnectionsStats,
            setBusinessConnectionsStats,
            businessFilter,
            setBusinessFilter,
        ],
    );

    return (
        <PartnerConnectionContext.Provider value={ctxValue}>
            {children}
        </PartnerConnectionContext.Provider>
    );
};

export const usePartnerConnectionContext = () => {
    const context = useContext(PartnerConnectionContext);

    if (!context) {
        throw new Error('Context must be used within a PartnerConnectionProvider');
    }

    return context;
};
