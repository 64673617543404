import { Illustration, IllustrationElement } from '@partoohub/iconography';
import { ButtonAppearance, IconElement, Stack, Text } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import { CONTAINED, Type } from 'app/common/designSystem/constants/type';
import { useIsMobile } from 'app/common/services/screenDimensions';

import {
    NoResultImageContainer,
    NotFoundTemplateContainer,
    StyledButton,
} from './NotFoundTemplate.styled';

export interface NotFoundTemplateProps {
    handleClick?: () => void;
    imgSrc: string | IllustrationElement;
    show: boolean;
    // Optional props
    buttonStatus?: Type | string;
    title?: string | null;
    subtitle?: string | null;
    subsubtitle?: string;
    buttonText?: string | null;
    withButton?: boolean;
    icon?: IconElement;
    small?: boolean; // to handle template inside a page with content
}

const NotFoundTemplate = ({
    show,
    imgSrc,
    buttonStatus = CONTAINED as Type,
    handleClick = undefined,
    title = null,
    subtitle = null,
    subsubtitle,
    buttonText = null,
    withButton = true,
    icon = undefined,
    small = false,
}: NotFoundTemplateProps): JSX.Element => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const img =
        typeof imgSrc === 'object' ? (
            <Illustration
                dataTrackId={`not_found__img__${title}`}
                illustration={imgSrc}
                width={isMobile ? '215' : '430'}
                height={isMobile ? '99' : '188'}
            />
        ) : (
            <NoResultImageContainer small={small}>
                <img src={imgSrc} alt="no-result-found" />
            </NoResultImageContainer>
        );
    return (
        <NotFoundTemplateContainer direction="row" alignItems="center" justifyContent="center">
            <Stack
                justifyContent="center"
                alignItems="center"
                style={{ textAlign: 'center', maxWidth: '480px' }}
            >
                {img}
                {title && (
                    <Text variant="heading5" as="span">
                        {t(title)}
                    </Text>
                )}
                {show && subtitle && (
                    <Text variant="bodyMRegular" as="span" color="secondary">
                        {t(subtitle)}
                    </Text>
                )}
                {show && subsubtitle && (
                    <Text variant="bodyMRegular" as="span" color="secondary">
                        {t(subsubtitle)}
                    </Text>
                )}
                {show && withButton && (
                    <StyledButton
                        dataTrackId="not_found__button"
                        onClick={handleClick}
                        variant="primary"
                        shape="cube"
                        appearance={buttonStatus as ButtonAppearance}
                        icon={icon}
                    >
                        {t(buttonText ?? '')}
                    </StyledButton>
                )}
            </Stack>
        </NotFoundTemplateContainer>
    );
};

export default NotFoundTemplate;
