import * as constants from './constants';

export type SetDateFilterType = {
    type: typeof constants.SET_DATE_FILTER;
    from: Date | null;
    to: Date | null;
};

export type SetInputDateFilterType = {
    type: typeof constants.SET_INPUT_DATE_FILTER;
    input: 'startDate' | 'endDate' | null;
};

export type AddMessageFilterType = {
    type: typeof constants.ADD_MESSAGE_FILTER;
    message: 'with_message' | 'without_message';
};

export type RemoveTagFilterType = {
    type: typeof constants.REMOVE_TAG_FILTER;
    tag: number;
};

export type ApplyFiltersType = {
    type: typeof constants.APPLY_FILTERS;
};

export type ResetPendingFilterType = {
    type: typeof constants.RESET_PENDING_FILTER;
    name: constants.AllFilters;
};

export type ResetReviewFilterType = {
    type: typeof constants.RESET_REVIEW_FILTER;
    name: constants.AllFilters;
};

export type ResetReviewToolbarFiltersType = {
    type: typeof constants.RESET_REVIEW_TOOLBAR_FILTERS;
};

// MOBILE
export type DisplayReviewMobileFiltersType = {
    type: typeof constants.DISPAY_REVIEW_MOBILE_FILTERS;
};

export type HideReviewMobileFiltersType = {
    type: typeof constants.HIDE_REVIEW_MOBILE_FILTERS;
};

export type ReInitReviewFilters = {
    type: typeof constants.RE_INIT_REVIEW_FILTERS;
};

export type ReviewFiltersActionType =
    | SetDateFilterType
    | SetInputDateFilterType
    | RemoveTagFilterType
    | ApplyFiltersType
    | ResetPendingFilterType
    | ResetReviewFilterType
    | ResetReviewToolbarFiltersType
    | DisplayReviewMobileFiltersType
    | HideReviewMobileFiltersType
    | ReInitReviewFilters;
