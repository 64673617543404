export type ReviewAnalyticsDate = {
    from: Date | null;
    to: Date | null;
};

export type ReviewFiltersState = {
    date: ReviewAnalyticsDate;
    platform: Array<'google_my_business' | 'facebook' | 'tripadvisor'>;
    message: Array<'withMessage' | 'withoutMessage'>;
    rating: Array<'postive' | 'neutral' | 'negative'>;
    keyword: Array<string>;
    tag: Array<number>;
    pending: Record<string, any>;
    displaySearchBar: boolean;
    mobileShow: boolean;
};
