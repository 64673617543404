import { ChangeEvent, FC, useState } from 'react';

import { Button } from '@partoohub/ui';

import { useTranslation } from 'react-i18next';

import pictoUrl from 'app/assets/whatsapp/mobile_onboarding_picto_1.png';

import {
    CenteredBody,
    CenteredText,
    CenteredTitle,
    MBSCheckbox,
    PageWrapper,
    Picto,
} from './WhatsappAccountImport.styled';

interface Props {
    onButtonClick: () => void;
    readyForSignup: boolean;
}

export const WhatsappAccountRequirementsPage: FC<Props> = ({ onButtonClick, readyForSignup }) => {
    const { t } = useTranslation();

    const [hasMBSAccount, setHasMBSAccount] = useState<boolean>(false);
    const [isSignupLaunched, setIsSignupLaunched] = useState<boolean>(false);

    const handleCheckboxClicked = (e: ChangeEvent<HTMLInputElement>) => {
        setHasMBSAccount(e.target.checked);
    };

    const handleButtonClick = () => {
        onButtonClick();
        setIsSignupLaunched(true);
    };

    return (
        <PageWrapper>
            <CenteredBody>
                <Picto src={pictoUrl} />
                <CenteredTitle as={'div'} variant="heading2">
                    {t('whatsapp_mobile_onboarding_requirements_title')}
                </CenteredTitle>
                <CenteredText as={'div'} variant="bodyLRegular">
                    {t('whatsapp_mobile_onboarding_requirements_text')}
                </CenteredText>
                <MBSCheckbox
                    dataTrackId=""
                    onChange={handleCheckboxClicked}
                    disabled={isSignupLaunched}
                >
                    {t('whatsapp_mobile_onboarding_requirements_checkbox')}
                </MBSCheckbox>
            </CenteredBody>
            <Button
                dataTrackId=""
                full={true}
                variant="primary"
                disabled={!readyForSignup || !hasMBSAccount || isSignupLaunched}
                onClick={handleButtonClick}
            >
                {t('whatsapp_mobile_onboarding_meta_button')}
            </Button>
        </PageWrapper>
    );
};
