import { Dispatch, SetStateAction, useState } from 'react';

import { FontAwesomeIconsPartooUsed, IconPrefix, TextInput } from '@partoohub/ui';
import { validate } from 'email-validator';
import { useTranslation } from 'react-i18next';

import { ReviewAnalyticsDimension } from 'app/api/types/review_analytics';
import { ConfirmModal } from 'app/common/components/ConfirmModalV2';

import { useAppDataContext } from 'app/common/contexts/appData';

import { StyledCloseButton } from './ExportModal.styled';
import { useDownloadMetrics } from '../../hooks/useDownloadMetrics';

type Props = {
    isOpenExportModal: boolean;
    setIsOpenExportModal: Dispatch<SetStateAction<boolean>>;
    dimensionType: ReviewAnalyticsDimension;
};

export const ExportModal = ({ isOpenExportModal, setIsOpenExportModal, dimensionType }: Props) => {
    const { t } = useTranslation();

    const { me } = useAppDataContext();
    const myEmail = me?.email;

    const [email, setEmail] = useState(myEmail);

    const isError = !validate(email);
    const notice = isError ? t('email_not_valid') : '';

    const { mutate } = useDownloadMetrics(email, dimensionType);

    const resetExportModal = () => {
        setIsOpenExportModal(false);
        setEmail(myEmail);
    };
    const handleExportFile = () => {
        resetExportModal();
        mutate();
    };

    return (
        <ConfirmModal
            trackId="review_analytics_modal"
            modalFor="download"
            title={t('review_analytics_export_data')}
            content={t('review_analytics_export_content')}
            confirmLabel={t('send_email')}
            show={isOpenExportModal}
            onHide={resetExportModal}
            onConfirm={handleExportFile}
            hideOnClickOutside={false}
            confirmDisabled={isError}
        >
            <TextInput
                dataTrackId="review_analytics_data__email_textinput"
                required
                type="email"
                label={t('email_input_placeholder')}
                value={email}
                onChange={setEmail}
                error={isError}
                notice={notice}
            />
            <StyledCloseButton
                dataTrackId="review_analytics_modal_close_button"
                icon={[FontAwesomeIconsPartooUsed.faClose, IconPrefix.REGULAR]}
                appearance="outlined"
                onClick={resetExportModal}
            />
        </ConfirmModal>
    );
};
