import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { SelectableCard } from 'app/common/components/SelectableCard';

export const ChannelSelectorCard = styled(SelectableCard)<{ isConnected: boolean }>`
    flex-basis: 150px;
    cursor: auto;
    ${({ isConnected }) =>
        isConnected &&
        css`
            cursor: pointer;
        `}
`;
