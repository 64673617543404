import styled from '@emotion/styled';
import { Checkbox, Text } from '@partoohub/ui';

export const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding: 8px;
    background: linear-gradient(
        108deg,
        #e6f1f7 0%,
        #f3f3fc 23.96%,
        #f6f4f1 50%,
        #f6f2ec 73.96%,
        #f6f8fc 100%
    );
`;

export const CenteredBody = styled.div`
    width: 100%;
    margin: auto;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const Picto = styled.img`
    max-width: 100%;
    margin-bottom: 36px;
`;

export const ClosePicto = styled.img`
    max-width: 100%;
    margin-bottom: 16px;
`;

export const FullWidthBackgroundPicto = styled.img`
    width: calc(100% + 48px);
    margin-bottom: -36px;
`;

export const CenteredText = styled(Text)`
    text-align: center;
`;

export const CenteredTitle = styled(CenteredText)`
    margin-bottom: 16px;
`;

export const MBSCheckbox = styled(Checkbox)`
    margin-top: 26px;
`;
