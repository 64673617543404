import styled from '@emotion/styled';
import { Stack } from '@partoohub/ui';

export const CreationModalContainer = styled(Stack)`
    padding: 24px;
`;

export const CountryDescription = styled.div`
    padding: 0px 16px;
`;
